//plans/page.js
'use client' 
import React, { useState } from 'react';

const plans = [
  {
    id: 1,
    price: '₹247',
    duration: '7-Day trial subscription',
    description: 'Unlimited PDF Downloads, Unlimited Resumes, Unlimited Cover Letters, After 7 days, auto-renews to ₹2,089 billed every 4 weeks, Cancel any time, 7-day money back guarantee*, Free resume critique from an HR expert.',
    popular: true,
  },
  {
    id: 2,
    price: '₹3,687',
    duration: '6 months',
    description: 'Unlimited PDF Downloads, Unlimited Resumes, Unlimited Cover Letters, Non-recurring payment. Pay once, 7-day money back guarantee*, Free resume critique from an HR expert.',
    popular: false,
  },
  {
    id: 3,
    price: '₹5,799',
    duration: '12 months',
    description: 'Unlimited PDF Downloads, Unlimited Resumes, Unlimited Cover Letters, Non-recurring payment. Pay once, 7-day money back guarantee*, Free resume critique from an HR expert.',
    popular: false,
  }
];

const PlanCard = ({ plan }) => {
  return (
    <div className={`bg-white p-6 rounded-lg shadow-md ${plan.popular ? 'border-2 border-blue-500' : ''}`}>
      {plan.popular && <span className="bg-blue-500 text-white px-3 py-1 text-xs rounded-full uppercase tracking-wider">Most Popular</span>}
      <h3 className="text-2xl font-semibold my-4">{plan.price}</h3>
      <p className="text-gray-500 mb-6">{plan.duration}</p>
      <ul className="text-sm mb-6">
        {plan.description.split(', ').map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            <span className="text-green-500 mr-2">✓</span>
            {feature}
          </li>
        ))}
      </ul>
      <button className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
        Choose Plan
      </button>
    </div>
  );
};

const FAQAccordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`border-b ${isOpen ? 'mb-4' : 'mb-0'}`}>
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-gray-700">{question}</span>
        <span className="font-bold text-gray-700">{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && <p className="pb-4 text-gray-600">{answer}</p>}
    </div>
  );
};

const Plans = () => {
  const faqs = [
    {
      question: 'Can I change my plan?',
      answer: 'Yes, you can upgrade, downgrade or cancel your plan at any time. Do it yourself in your account or contact us, we are here to help.'
    },
    {
      question: 'Do I get full access with the trial?',
      answer: 'Yes, during your trial you will have full access to all the features offered. Take advantage to explore everything we offer!'
    },
    {
      question: 'What about money back?',
      answer: `We offer a 7-day money back guarantee. If you're not satisfied with our service, you can get a full refund within the first 7 days.`
    }
  ];

  return (
    <>
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="text-center mt-6 mb-12 max-w-3xl mx-auto">
        <h2 className="text-2xl font-bold">Get Your Eye-Catching Resume!</h2>
        <p className="text-gray-600 mt-4 text-md">Sign up for Premium to download immediately and unlock exclusive templates and colors.</p>
      </div>

      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {plans.map(plan => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
      </div>

      {/* Companies section */}
      {/* <div className="mt-12 mb-8 py-12">
        <h2 className="text-center font-bold text-xl mb-6">Companies that have hired applicants who used our resume builder:</h2>
        <div className="flex justify-center items-center flex-wrap gap-5">
          <img src="/path-to-your-logo/nike-logo.png" alt="Nike Logo" className="h-8"/>
          <img src="/path-to-your-logo/starbucks-logo.png" alt="Starbucks Logo" className="h-8"/>
          <img src="/path-to-your-logo/hilton-logo.png" alt="Hilton Logo" className="h-8"/>
          <img src="/path-to-your-logo/hm-logo.png" alt="H&M Logo" className="h-8"/>
          <img src="/path-to-your-logo/ibm-logo.png" alt="IBM Logo" className="h-8"/>
          <img src="/path-to-your-logo/bmw-logo.png" alt="BMW Logo" className="h-8"/>
        </div>
      </div> */}

      {/* FAQs section */}
      <div className="mt-8 py-12">
        <h2 className="text-center font-bold text-xl mb-6">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQAccordion key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <div className="text-center mt-12">
          <a href="/faq" className="text-blue-500 hover:underline">Didn't find what you're looking for? – Use our FAQ</a>
        </div>
      </div>

    </div>
    </>
  );
};

export default Plans;



