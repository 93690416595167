import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';  
import { BsFileText, BsPlayCircle, BsEnvelope } from "react-icons/bs";
import { useAuth } from '../../context/AuthContext';
import { deleteJob } from '../../services/jobService';
import { format } from 'date-fns'; // Use date-fns to format the date

const JobCardDraggable = ({ job, onClick, onStatusChange, onJobClick, onJobDelete }) => {
  const { company, title, description, location, jobProviders } = job;
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [{ isDragging }, dragRef] = useDrag({
    type: 'JOB',
    item: { job },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleApplyClick = () => setShowApplyModal(true);
  const handleConfirmApply = () => {
    setShowApplyModal(false);
    onStatusChange(job.docId, 'applied');
  };
  const handleCancelApply = () => setShowApplyModal(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleLinkClick = () => {
    setDropdownOpen(false);
    handleApplyClick();
  };

  const handleOptimizeResume = () => {
    const jobDescriptionEncoded = encodeURIComponent(description);
    const jobTitleEncoded = encodeURIComponent(title);
    const url = `/optimize-resume?jobDescription=${jobDescriptionEncoded}&jobTitle=${jobTitleEncoded}`;
    window.open(url, '_blank'); 
  };

  const handleSimilarJobsClick = () => {
    const searchParams = new URLSearchParams({
      query: title,
      location: location,
      datePosted: 'month',
    }).toString();

    navigate(`/jobs?${searchParams}`);
  };

  const handleInterviewClick = () => onStatusChange(job.docId, 'interviewing');
  const handleOfferClick = () => onStatusChange(job.docId, 'offer');
  const handleArchiveClick = () => onStatusChange(job.docId, 'archive');

  const handleGenerateInterviewSheet = () => {
    const url = new URL(window.location);
    url.searchParams.set('jobId', job.docId);
    url.searchParams.set('tab', 'Interviews');
    window.history.pushState({}, '', url);
    onJobClick(job, 'Interviews');
  };

  const handleGenerateEmail = (emailVal) => {
    const url = new URL(window.location);
    url.searchParams.set('jobId', job.docId);
    url.searchParams.set('tab', 'Emails');
    url.searchParams.set('emailType', emailVal);
    window.history.pushState({}, '', url.toString());
    onJobClick(job, 'Emails');
  };

  const handleDelete = async () => {
    if (!job || !job.id) return;
    try {
      await deleteJob(user.uid, job.docId);
      onJobDelete(job.id); 
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  // Safeguard: Check if appliedDate exists and is valid (either Firestore Timestamp or JS Date)
const formattedAppliedDate = job.appliedDate 
? (job.appliedDate.seconds 
    ? format(new Date(job.appliedDate.seconds * 1000), 'MMMM d, yyyy')  // Handle Firestore Timestamp
    : (job.appliedDate instanceof Date 
        ? format(job.appliedDate, 'MMMM d, yyyy')  // Handle JavaScript Date
        : null)
  )
: null;


//   // Safeguard: Check if appliedDate exists and is valid
//   const formattedAppliedDate = job.appliedDate && job.appliedDate.seconds
//     ? format(new Date(job.appliedDate.seconds * 1000), 'MMMM d, yyyy')
//     : null;
// // Safeguard: Check if appliedDate exists and is valid
// const formattedAppliedDate = job.appliedDate instanceof Date
//   ? format(job.appliedDate, 'MMMM d, yyyy')
//   : null;

  return (
    <div
      ref={dragRef}
      className={`job-card bg-white rounded cursor-pointer mb-4 hover:shadow-lg ${isDragging ? 'opacity-50' : ''}`}
    >
      <div className='p-5' onClick={onClick}>
        <h4 className="text-md mb-1 font-bold">{title}</h4>
        <p className="text-gray-600 text-sm">{company}</p>
        <p className="text-gray-600 text-sm">{location}</p>
        {job.status === 'applied' && formattedAppliedDate && (
          <p className="text-sm text-gray-400 mt-3 italic">
            Applied on {formattedAppliedDate}
          </p>
        )}
      </div>
      <div className='p-5 pb-2 bg-gray-100 rounded-br-[15px] rounded-bl-[15px]'>
        <div className='flex justify-between'>
          <div>
            {job.status === "saved" && (
              <div className="relative">
                {jobProviders.length > 1 ? (
                  <div className="relative inline-block text-left">
                    <button
                      className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
                      onClick={toggleDropdown}
                    >
                      Apply Now
                    </button>
                    {dropdownOpen && (
                      <div className="absolute mt-2 rounded shadow py-2 bg-white z-10 border border-gray-200 w-[250px] rounded-[15px]">
                        {jobProviders.map((provider, index) => (
                          <a 
                            key={index} 
                            href={provider.url} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                            onClick={handleLinkClick}
                          >
                            Apply on {provider.jobProvider}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <a
                    href={jobProviders[0].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
                    onClick={handleApplyClick}
                  >
                    Apply Now
                  </a>
                )}
              </div>
            )}
            {job.status === "applied" && (
              <button className='bg-gray-200 text-black text-sm py-2 px-4 rounded-full hover:bg-gray-300' onClick={handleInterviewClick}>
                🗓️ I got interview
              </button>
            )}
            {job.status === "interviewing" && (
              <button className='bg-gray-200 text-black text-sm py-2 px-4 rounded-full hover:bg-gray-300' onClick={handleOfferClick}>
                🤩 I got offer!
              </button>
            )}
            {job.status === "offer" && (
              <p className="flex items-center text-green-500 font-bold py-2 text-sm hover:text-gray-700">
                🎉 Congrats on offer!
              </p>
            )}
          </div>
          <div>
            {job.status === "saved" && (
              <button className='bg-gray-200 text-black text-sm py-2 px-4 rounded-full hover:bg-gray-300' onClick={handleSimilarJobsClick}>
                Similar jobs
              </button>
            )}
            {(job.status !== "saved" && job.status !== "archive") && (
              <button className='text-gray-400 text-sm py-2 hover:text-gray-500 hover:underline' onClick={handleArchiveClick}>
                Archive
              </button>
            )}
          </div>
        </div>
        
        <div className='mt-5'>
          {job.status === "saved" && (<>
            <button onClick={handleOptimizeResume} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
              <BsFileText className="mr-2" /> Optimize Resume
            </button>
            {/* <button onClick={handleDelete} className="flex items-center text-red-500 mb-2 hover:text-red-700 hover:underline text-sm">
            <BsFileText className="mr-2" /> Delete Job
          </button> */}
          </>
          )}
          {job.status === "applied" && (
            <div>
              <button onClick={handleGenerateInterviewSheet} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsFileText className="mr-2" /> Generate interview sheet
              </button>
              <button onClick={() => handleGenerateEmail("followUp")} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsEnvelope className="mr-2" /> Generate follow up email
              </button>
            </div>
          )}
          {job.status === "interviewing" && (
            <div>
              <button onClick={handleGenerateInterviewSheet} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsFileText className="mr-2" /> Generate interview sheet
              </button>
              <button className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsPlayCircle className="mr-2" /> Start practice interview
              </button>
              <button onClick={() => handleGenerateEmail("interviewConfirmation")} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsEnvelope className="mr-2" /> Generate confirmation email
              </button>
              <button onClick={() => handleGenerateEmail("interviewThankYou")} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsEnvelope className="mr-2" /> Generate thank you email
              </button>
            </div>
          )}
          {job.status === "offer" && (
            <div>
              <button onClick={() => handleGenerateEmail("acceptance")} className="flex items-center text-gray-500 mb-2 hover:text-gray-700 hover:underline text-sm">
                <BsEnvelope className="mr-2" /> Generate acceptance email
              </button>
            </div>
          )}
        </div>

        {showApplyModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-xl shadow-lg w-96">
              <h2 className="text-lg font-bold mb-4">Did you apply for this role with {company}?</h2>
              <p className="text-sm text-gray-700 mb-8">
                Let us know so we can help you track your application and take the right next steps!
              </p>
              <div className="flex justify-between">
                <button className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700' onClick={handleConfirmApply}>
                  Yes, I applied!
                </button>
                <button className='bg-gray-500 text-white text-sm py-2 px-4 rounded-full hover:bg-gray-700' onClick={handleCancelApply}>
                  No, I didn't apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardDraggable;
