import React, { useState, useEffect } from 'react';
import CustomDropdown from '../Resume/CustomDropdown';
import UPNG from 'upng-js'; 
import { FaFont, FaRegCheckCircle } from 'react-icons/fa';
import { saveCoverLetterMetadata } from '../../services/resumeService';
import { useAuth } from '../../context/AuthContext';
import { TbTextSize, TbLineHeight, TbAlignJustified, TbDownload, TbX, TbFile, TbCircleCheck } from "react-icons/tb";
import ModalComponent from '../../components/ModalComponent';
import cssString from './coverLetterStyles.css?raw';
import "./fonts.css";
import "./coverLetterStyles.css";
import { Link } from 'react-router-dom';
import { toPng } from 'html-to-image';
import { coverletterimages } from '../../util/loadImages';
import { BiArrowBack } from 'react-icons/bi'; 

const CoverLetterPreview = ({ currentCoverletterId, coverLetterData, coverLetterFullData, setShowTemplateCollection, showResume, onToggleTemplates, showPreviewOnly = false }) => {
  const [coverLetterID, setCoverLetterID] = useState(currentCoverletterId);
  const { user } = useAuth();
  const [selectedStyle, setSelectedStyle] = useState('Professional');
  const [selectedFont, setSelectedFont] = useState('roboto-regular');
  const [fontSize, setFontSize] = useState('M');
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [lineHeight, setLineHeight] = useState('1.5');
  const [textAlignment, setTextAlignment] = useState('left');
  const [modalOpen, setModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [pageFormat, setPageFormat] = useState('A4');
  const [showTemplates, setShowTemplates] = useState(false);
  const [layoutType, setLayoutType] = useState(1);

  const { personalDetails, employerDetails, letterContent } = coverLetterData;

  const createMarkup = (htmlContent) => ({ __html: htmlContent });

  const fontSizeMap = { 
    'S': '12px',
    'M': '13px',
    'L': '14px',
    'XL': '15px',
    'XXL': '16px',
    'XXXL': '17px',
  };

  const defaultColorsByStyle = {
    Professional: '#293993',
    Executive: '#000',
    Modern: '#994731',
    Creative: '#000',
    Impactful: '#096e65',
    Visionary: '#242d3e',
    Dynamic: '#c42e08',
    Explorer: '#283c50',
    Classic: '#000',
    Elegant: '#000',
    Bold: '#4b93eb',
    Expertise: '#d3f1ff',
    Aspirations: '#2196F3',
    Minimalist: '#000',
    Influential: '#283c50',
    Inspired: '#f0e4d4',
    Versatile: '#ffc107',
    Vibrant: '#795548',
    Proactive: '#1f3540',
    Empowering: '#3F51B5',
    Ambitious: '#ffd3d3',
    Innovative: '#000'
  };

  const layoutComponents = {
    1: () => (
      <div>
        {renderHeader()}
        <div className="cl-body">
          <div className="left-column">{renderPersonalAndEmployerDetails()}</div>
          <div className="right-column">{renderLetterContent()}</div>
        </div>
      </div>
    ),
    2: () => (
      <div className="layout-two">
        {selectedStyle !== 'Versatile' ? renderHeader() : <h1>Cover Letter</h1>}
        <div className="sub-head"> {renderPersonalAndEmployerDetails()} </div>
        <div>{renderLetterContent()}</div>
      </div>
    ),
    3: () => (
      <div>
        {renderHeader()}
        <div className='cl-body'>
          <div className="left-column">{renderPersonalAndEmployerDetails()}</div>
          <div className="right-column">{renderLetterContent()}</div>
        </div>
      </div>
    ),
    4: () => (
      <div className="flex">
        <div className="right-column">
          {(selectedStyle == 'Explorer' || selectedStyle == 'Elegant' || selectedStyle == 'Proactive' || selectedStyle == 'Modern' || selectedStyle == 'Minimalist' || selectedStyle == 'Empowering') && renderHeader()}
          {(selectedStyle == 'Impactful' || selectedStyle == 'Minimalisst') && <h1>Cover Letter</h1>}
          {renderLetterContent()}</div>
        <div className="left-column">{renderPersonalAndEmployerDetails()}</div>
      </div>
    ),
  };

  const layoutOne = [
    'Influential',
    'Professional',
    'Visionary',
    'Classic',
    'Bold'
  ];

  const layoutTwo = [
    'Executive',
    'Dynamic',
    'Innovative',
    'Aspirations',
    'Versatile',
    'Ambitious'
  ];

  const layoutThree = [
    'Inspired',
    'Creative',
    'Vibrant',
  ];

  const layoutFour = [
    'Impactful',
    'Modern',
    'Minimalist',
    'Elegant',
    'Explorer',
    'Expertise',
    'Proactive',
    'Empowering'
  ];

  const adjustLayoutForStyle = (style) => {
    if (layoutOne.includes(style)) return 1;
    if (layoutTwo.includes(style)) return 2;
    if (layoutThree.includes(style)) return 3;
    if (layoutFour.includes(style)) return 4;
    return layoutType;
  };

  useEffect(() => {
    if (coverLetterFullData && coverLetterFullData.metadata) {
      const { selectedStyle, selectedFont, fontSize, lineHeight, selectedColor, textAlignment } = coverLetterFullData.metadata;
      setCoverLetterID(currentCoverletterId);
      setSelectedStyle(selectedStyle || 'Professional');
      setSelectedFont(selectedFont || 'roboto-regular');
      setFontSize(fontSize || 'M');
      setLineHeight(lineHeight || '1.25');
      setTextAlignment(textAlignment || "left");
      setSelectedColor(selectedColor || defaultColorsByStyle[selectedStyle] || '#000000');
      setPageFormat(coverLetterFullData.metadata.pageFormat || 'A4');
      const newLayoutType = adjustLayoutForStyle(selectedStyle);
      setLayoutType(newLayoutType);
    }
  }, [coverLetterFullData, currentCoverletterId]);

  const textAlignOptions = [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
    { label: 'Justify', value: 'justify' }
  ];
  const pageFormatOptions = [
    { label: 'A4', value: 'A4' },
    { label: 'Letter', value: 'Letter' }
  ];

  const handleTextAlignmentChange = async (newAlignment) => {
    setTextAlignment(newAlignment);
    try {
      await saveCoverLetterMetadata(coverLetterID, { textAlignment: newAlignment });
      console.log('Cover letter font updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter font:', error);
    }
  };

  const handlePageFormatChange = async (newFormat) => {
    setPageFormat(newFormat);
    try {
      await saveCoverLetterMetadata(coverLetterID, { pageFormat: newFormat });
      console.log('Cover letter page format updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter page format:', error);
    }
  };

  const handleStyleChange = async (newStyle) => {
    const newColor = defaultColorsByStyle[newStyle] || '#000000';
    setSelectedStyle(newStyle);
    setSelectedColor(newColor);
    const newLayoutType = adjustLayoutForStyle(newStyle);
    setLayoutType(newLayoutType);

    try {
      await saveCoverLetterMetadata(coverLetterID, {
        selectedStyle: newStyle,
        selectedColor: newColor,
      });
      console.log('Cover letter style and color updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter style and color:', error);
    }
  };

  const handleFontChange = async (newFont) => {
    setSelectedFont(newFont);
    try {
      await saveCoverLetterMetadata(coverLetterID, { selectedFont: newFont });
      console.log('Cover letter font updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter font:', error);
    }
  };

  const handleFontSizeChange = async (newSize) => {
    setFontSize(newSize);
    try {
      await saveCoverLetterMetadata(coverLetterID, { fontSize: newSize });
      console.log('Cover letter font size updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter font size:', error);
    }
  };

  const handleLineHeightChange = async (newLineHeight) => {
    setLineHeight(newLineHeight);
    try {
      await saveCoverLetterMetadata(coverLetterID, { lineHeight: newLineHeight });
      console.log('Cover letter line height updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter line height:', error);
    }
  };

  const handleColorChange = async (newColor) => {
    setSelectedColor(newColor);
    try {
      await saveCoverLetterMetadata(coverLetterID, { selectedColor: newColor });
      console.log('Cover letter color updated successfully');
    } catch (error) {
      console.error('Failed to update cover letter color:', error);
    }
  };

  const renderHeader = () => {
    const personalTitle = personalDetails.inputs.find(input => input.inputKey === "title")?.inputValue;
    const firstName = personalDetails.inputs.find(input => input.inputKey === "firstName")?.inputValue;
    const lastName = personalDetails.inputs.find(input => input.inputKey === "lastName")?.inputValue;

    return (
      <div className="cl-header">
        <h1>{letterContent.inputs.find(input => input.inputKey === "letterTitle")?.inputValue}</h1>
        <h2>{`${personalTitle ? personalTitle + '.' : ''} ${firstName} ${lastName}`}</h2>
      </div>
    );
  };

  const renderFooter = () => <div className="cl-footer"><p>Footer Content</p></div>;

  const renderPersonalAndEmployerDetails = () => {
    const title = personalDetails.inputs.find(input => input.inputKey === "title")?.inputValue;
    const firstName = personalDetails.inputs.find(input => input.inputKey === "firstName")?.inputValue;
    const lastName = personalDetails.inputs.find(input => input.inputKey === "lastName")?.inputValue;

    const etitle = employerDetails.inputs.find(input => input.inputKey === "title")?.inputValue;
    const efirstName = employerDetails.inputs.find(input => input.inputKey === "firstName")?.inputValue;
    const elastName = employerDetails.inputs.find(input => input.inputKey === "lastName")?.inputValue;

    const otherPersonalDetails = personalDetails.inputs.filter(input =>
      !["title", "firstName", "lastName"].includes(input.inputKey));
    const otherEmployerDetails = employerDetails.inputs.filter(input =>
      !["title", "firstName", "lastName"].includes(input.inputKey));

    return (
      <>
        <div>
          <div className='section-title'>From</div>
          <div className="personal-inline-details">
            {title && <span className="inline-detail">{title ? title + '.' : ''}</span>}
            {firstName && <span className="inline-detail">{firstName}</span>}
            {lastName && <span className="inline-detail">{lastName}</span>}
          </div>

          <div className="section personal-details">
            {otherPersonalDetails.map((detail, index) => (
              <p key={index}>{detail.inputValue}</p>
            ))}
          </div>
        </div>
        <div>
          <div className='section-title'>To</div>
          <div className="employer-inline-details">
            {etitle && <span className="inline-detail">{etitle ? etitle + '.' : ''}</span>}
            {efirstName && <span className="inline-detail">{efirstName}</span>}
            {elastName && <span className="inline-detail">{elastName}</span>}
          </div>

          <div className="section employer-details">
            {otherEmployerDetails.map((detail, index) => (
              <p key={index}>{detail.inputValue}</p>
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderLetterContent = () => {
    const dateEntry = letterContent.inputs.find(input => input.inputKey === "date");
    const subjectEntry = letterContent.inputs.find(input => input.inputKey === "subject");

    return (
      <div className="section letter-content">
        <div className="input-section date-subject-wrapper">
          {subjectEntry && (
            <div className="subject-entry">
              <p className="input-value">{subjectEntry.inputValue}</p>
            </div>
          )}
          {dateEntry && (
            <div className="date-entry">
              <p className="input-value">{dateEntry.inputValue}</p>
            </div>
          )}
        </div>

        {letterContent.inputs.filter(input => input.inputKey !== "date" && input.inputKey !== "subject").map((input) => (
          <div key={input.inputKey} className={`input-section ${input.inputKey.toLowerCase().replace(/\s+/g, '-')}`}>
            {input.inputKey === "letterBody" ? (
              <div dangerouslySetInnerHTML={createMarkup(input.inputValue || '')} />
            ) : (
              <p className="input-value">{input.inputValue}</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  const fontOptions = [
    { label: 'Roboto', value: 'roboto-regular' },
    { label: 'Ubuntu', value: 'ubuntu-regular' },
    { label: 'Rubik', value: 'rubik-regular'},
    { label: 'PT Serif', value: 'pt-serif-regular' },
    { label: 'Lexend', value: 'lexend-regular' },
    { label: 'ABeeZee', value: 'abeezee-regular' },
    { label: 'Poppins', value: 'poppins-regular' },
    { label: 'Prompt', value: 'prompt-regular' },   
    { label: 'Montserrat', value: 'montserrat-regular'},
    { label: 'Lora', value: 'lora-regular'},
    { label: 'Raleway', value: 'raleway-regular'},
    { label: 'Roboto Mono', value: 'roboto-mono' },
    { label: 'Playwrite', value: 'playwrite-de-grund'}
  ];
  const lineHeightOptions = [
    { label: '1.15', value: '1.15' },
    { label: '1.25', value: '1.25' },
    { label: '1.30', value: '1.30' },
    { label: '1.35', value: '1.35' },
    { label: '1.45', value: '1.45' },
    { label: '1.55', value: '1.55' },
    { label: '1.75', value: '1.75' },
    { label: '2', value: '2' },
    { label: '2.15', value: '2.15' }
  ];
  const fontSizeOptions = [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: 'XXL', value: 'XXL' },
    { label: 'XXXL', value: 'XXXL' }
  ];

  const getStyleBySelectedStyle = (selectedStyle, selectedColor, fontSize, lineHeight) => {
    const baseStyle = {
      fontSize: fontSize,
      lineHeight: lineHeight,
    };

    const styleVariations = {
      'Professional': {
        '--professional-color': selectedColor
      },
      'Executive': {
        '--executive-color': selectedColor,
      },
      'Modern': {
        '--modern-color': selectedColor,
      },
      'Visionary': {
        '--visionary-color': selectedColor,
      },
      'Creative': {
        '--creative-color': selectedColor,
      },
      'Impactful': {
        '--impactful-color': selectedColor,
      },
      'Dynamic': {
        '--dynamic-color': selectedColor,
      },
      'Explorer': {
        '--explorer-color': selectedColor,
      },
      'Classic': {
        '--classic-color': selectedColor,
      },
      'Elegant': {
        '--elegant-color': selectedColor,
      },
      'Bold': {
        '--bold-color': selectedColor,
      },
      'Expertise': {
        '--expertise-color': selectedColor,
      },
      'Aspirations': {
        '--aspirations-color': selectedColor,
      },
      'Minimalist': {
        '--minimalist-color': selectedColor,
      },
      'Influential': {
        '--influential-color': selectedColor,
      },
      'Inspired': {
        '--inspired-color': selectedColor,
      },
      'Versatile': {
        '--versatile-color': selectedColor,
      },
      'Vibrant': {
        '--vibrant-color': selectedColor,
      },
      'Proactive': {
        '--proactive-color': selectedColor,
      },
      'Empowering': {
        '--empowering-color': selectedColor,
      },
      'Ambitious': {
        '--ambitious-color': selectedColor,
      },
      'Innovative': {
        '--innovative-color': selectedColor,
      }
    };

    return {
      ...baseStyle,
      ...(styleVariations[selectedStyle] || {}),
    };
  };

  const coverLetterStyles = getStyleBySelectedStyle(selectedStyle, selectedColor, fontSizeMap[fontSize], lineHeight);
  // const handleImageDownload = async () => {
  //   const coverLetterElement = document.getElementById('coverletter');
  //   coverLetterElement.style.display = 'flex';
  //   coverLetterElement.style.justifyContent = 'center';
  //   coverLetterElement.style.width = '8.3in';
  //   coverLetterElement.style.height = '11.7in';
  //   coverLetterElement.style.margin = '0px';

  //   if (!coverLetterElement) {
  //     console.error('Cover letter element not found');
  //     return;
  //   }
  
  //   try {
  //     // Convert the HTML to a PNG image using html-to-image
  //     const dataUrl = await toPng(coverLetterElement, {
  //       backgroundColor: '#ffffff',
  //       width: coverLetterElement.clientWidth * 2, // Scale up to 1600px width
  //       height: coverLetterElement.clientHeight * 2 // Scale up to 1600px height
  //     });
  
  //     // Convert the data URL to a Uint8Array
  //     const byteString = atob(dataUrl.split(',')[1]);
  //     const byteArray = new Uint8Array(byteString.length);
  //     for (let i = 0; i < byteString.length; i++) {
  //       byteArray[i] = byteString.charCodeAt(i);
  //     }
  
  //     // Decode the PNG image to get the image data
  //     const decoded = UPNG.decode(byteArray);
  //     const rgba = UPNG.toRGBA8(decoded);
  
  //     // Compress the PNG image
  //     const compressed = UPNG.encode(rgba, decoded.width, decoded.height, 256);
  
  //     // Create a Blob from the compressed data
  //     const blob = new Blob([compressed], { type: 'image/png' });
  
  //     // Create a link element to download the compressed image
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'cover-letter-compressed.png';
  //     link.click();
  //   } catch (error) {
  //     console.error('Error generating and compressing image:', error);
  //   }
  // };
  
  const handleImageDownload = async () => {
    const coverLetterElement = document.getElementById('coverletter');
  
    if (!coverLetterElement) {
      console.error('Cover letter element not found');
      return;
    }
  
    // Set the styles for centering and sizing the content
    coverLetterElement.style.display = 'flex';
    coverLetterElement.style.justifyContent = 'center';
    coverLetterElement.style.width = '8.3in';
    coverLetterElement.style.height = '11.7in';
    coverLetterElement.style.margin = '0px';
  
    try {
      // Convert the HTML to a PNG image using html-to-image with increased resolution
      const dataUrl = await toPng(coverLetterElement, {
        backgroundColor: '#ffffff',
        pixelRatio: 1.5 // Double the pixel ratio for higher resolution
      });
  
      // Convert the data URL to a Uint8Array
      const byteString = atob(dataUrl.split(',')[1]);
      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }
  
      // Decode the PNG image to get the image data
      const decoded = UPNG.decode(byteArray);
      const rgba = UPNG.toRGBA8(decoded);
  
      // Compress the PNG image
      const compressed = UPNG.encode(rgba, decoded.width, decoded.height, 256);
  
      // Create a Blob from the compressed data
      const blob = new Blob([compressed], { type: 'image/png' });
  
      const subjectEntry = letterContent.inputs.find(input => input.inputKey === "subject");
      const jobTitle = subjectEntry.inputValue.toLowerCase().replace(/ /g, '-');
      const fileName = `${jobTitle}-cover-letter-example.png`;
      
      // Create a link element to download the compressed image
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error('Error generating and compressing image:', error);
    }
  };


  const fetchWithRetry = async (url, options, retries = 3) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`Attempt ${attempt} failed: ${response.statusText}`);
        }
        return response;
      } catch (error) {
        console.error(error);
        if (attempt === retries) {
          throw new Error(`Failed after ${retries} attempts`);
        }
      }
    }
  };

  const handleDownloadPdf = async () => {
    setModalOpen(true);
    setIsDownloading(true);
    setDownloadError(false);
    const html = document.getElementById('coverletter').outerHTML;
    const fontLinkMapping = {
      'roboto-regular': '<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">',
      'montserrat-regular': '<link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">',
      'lora-regular': '<link href="https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap" rel="stylesheet">',
      'raleway-regular': '<link href="https://fonts.googleapis.com/css2?family=Raleway:ital@0;1&display=swap" rel="stylesheet">',
      'rubik-regular': '<link href="https://fonts.googleapis.com/css2?family=Rubik&display=swap" rel="stylesheet">',
      'inconsolata-regular': '<link href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap" rel="stylesheet">',
      'signika-regular': '<link href="https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap" rel="stylesheet">',
      'poppins-regular': '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet">',
      'ubuntu-regular': '<link href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap" rel="stylesheet">',
      'prompt-regular': '<link href="https://fonts.googleapis.com/css2?family=Prompt&display=swap" rel="stylesheet">',
      'roboto-mono': '<link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet">',
      'playwrite-de-grund': '<link href="https://fonts.googleapis.com/css2?family=Playwrite+DE+Grund&display=swap" rel="stylesheet">',
      'abeezee-regular': '<link href="https://fonts.googleapis.com/css2?family=ABeeZee&display=swap" rel="stylesheet">',
      'lexend-regular': '<link href="https://fonts.googleapis.com/css2?family=Lexend&display=swap" rel="stylesheet">',
      'pt-serif-regular': '<link href="https://fonts.googleapis.com/css2?family=PT+Serif&display=swap" rel="stylesheet">'
  };
    const selectedFontLink = fontLinkMapping[selectedFont] || '';

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Cover Letter</title>
          ${selectedFontLink}
          <style>${cssString}</style>
        </head>
        <body>
            ${html}
        </body>
      </html>
      `;

    let stylesRightSidebar = ['influential', 'impactful', 'expertise', 'versatile']
    let floatTy = "left";
    if (stylesRightSidebar.includes(selectedStyle.toLowerCase())) {
      floatTy = "right";
    }
    let sidebarColor = selectedColor;
    let stylesRequiringTransparentSidebar = ['professional', 'inspired', 'versatile', 'executive', 'visionary', 'creative', 'dynamic', 'classic', 'elegant', 'aspirations', 'minimalist', 'proactive', 'empowering', 'ambitious', 'innovative', 'vibrant', 'bold', 'influential'];
    if (stylesRequiringTransparentSidebar.includes(selectedStyle.toLowerCase())) {
      sidebarColor = 'transparent';
    } else if (selectedStyle.toLowerCase() === 'explorer') {
      sidebarColor = '#f1f1f1';
    } else if (selectedStyle.toLowerCase() === 'bold') {
      sidebarColor = 'rgb(38 38 45)';
    }

    const pdfApi = 'https://rd-server-418cf5202d66.herokuapp.com/generate-pdf';
    const pdfPayLoad = {
      cvHtml: htmlContent,
      cvStyle: selectedStyle,
      cvColor: sidebarColor,
      docType: 'coverletter',
      floatType: floatTy,
      pageFormat: pageFormat
    };

    try {
    const response = await fetchWithRetry(pdfApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pdfPayLoad),
      mode: 'cors'
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'cover-letter.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
      setTimeout(() => {
        setModalOpen(false);
      }, 3000);
    } else {
      throw new Error('Response was not ok.');
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
    setIsDownloading(false);
    setDownloadError(true);
    setTimeout(() => {
      setModalOpen(false);
    }, 5000);
  }
};

  const templates = [
    'Professional', 'Executive', 'Modern', 'Visionary', 'Creative', 'Impactful',
    'Dynamic', 'Explorer', 'Classic', 'Elegant', 'Bold', 'Expertise', 'Aspirations',
    'Minimalist', 'Influential', 'Inspired', 'Versatile', 'Vibrant', 'Proactive',
    'Empowering', 'Ambitious', 'Innovative'
  ];

  // const renderTemplateCollection = () => (
  //   <div className="template-collection p-5 flex flex-wrap justify-center">
  //     {templates.map((template, index) => (
  //       <div
  //         key={index}
  //         className={`template-item m-2 cursor-pointer ${selectedStyle === template ? 'active-template' : ''}`}
  //         onClick={() => handleStyleChange(template)}
  //         style={{
  //           maxWidth: '250px',
  //           height: 'auto',
  //           margin: '0',
  //           display: 'inline-block',
  //           textAlign: 'center',
  //           cursor: 'pointer'
  //         }}
  //       >
  //         <div className='temp-thumbnail relative'><div className='selectedTemp'><TbCircleCheck /></div><img src="https://st-content.myoutshine.com/AI_RESUME_IMAGE/templates/thumb-modernn-teal.png" width="100%" alt="Professional" className='rounded-lg '/></div>
  //         <div className='temp-footer mt-2'> <strong>{template}</strong> </div>
  //       </div>
  //     ))}
  //   </div>
  // );

  const renderTemplateCollection = () => (
    <div className="template-collection p-5 flex flex-wrap justify-center">
      {templates.map((template, index) => (
        <div
          key={index}
          className={`template-item m-2 cursor-pointer ${selectedStyle === template ? 'active-template' : ''}`}
          onClick={() => handleStyleChange(template)}
          style={{
            maxWidth: '250px',
            height: 'auto',
            margin: '0',
            display: 'inline-block',
            textAlign: 'center',
            cursor: 'pointer'
          }}
        >
          
          <div className='temp-thumbnail relative' style={{"width":"250px", "height":"331px"}}><div className='selectedTemp'><TbCircleCheck /></div>
          <img
                                  src={coverletterimages[`${template}.png`] || coverletterimages[`${template}.jpg`]}
                                  alt={template}
                                  width="312"
                                  className="w-full"
                              />
                              </div>
          <div className='temp-footer mt-2'> <strong>{template}</strong> </div>
        </div>
      ))}
    </div>
  );

  
  const [isActive, setIsActive] = useState(false);

  const handleSelectTemplate = () => {
    setIsActive(!isActive);
    setShowTemplateCollection(!isActive);
    setShowTemplates(!isActive); 
    onToggleTemplates(true); 
  };
  const handleCloseTemplate = () => {
    if (isActive) {
      setIsActive(false);
      setShowTemplateCollection(false);
      setShowTemplates(false); 
      onToggleTemplates(false); 
    }
  };

  return (<>
    {showTemplates &&
    <div className="flex flex-row w-full md:w-[40%] h-screen select-resume-template">   
        <div className="flex flex-col w-full h-screen border-l-10 border-cyan-400">
          <div className="flex-1 bg-white overflow-auto custom-scrollbar">
            {renderTemplateCollection()}
          </div>
        </div>
        <div className="md:hidden sticky-footer flex justify-center">
    <button
            onClick={handleCloseTemplate}
            className="flex flex-col items-center fo-btn text-white text-md py-1.5 px-4 rounded"
          >
            <BiArrowBack className="text-xl" />
            <span className="text-xs">Back to Preview</span>
          </button>
    </div>
      </div>
    }

    {!showPreviewOnly && (
      <div className={`json-preview w-full md:w-[60%] bg-gray-100 h-lvh overflow-hide ${!showResume ? 'edit-mode' : 'preview-mode'} ${showTemplates ? 'templateShowing': ''}`}>
        <div className="flex flex-col h-screen" style={{ backgroundColor: "#656e83" }}>
          <div className='flex items-center justify-between'>
            <div className="resume-customize w-full flex items-center p-4">
              <button
                onClick={handleSelectTemplate}
                className={`custom-dropdown-header inline-flex items-center px-4 py-0 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none ${isActive ? 'active' : ''}`}
              >
                Select Template
              </button>
              <CustomDropdown
                options={fontOptions}
                value={selectedFont}
                label={"Font"}
                onChange={handleFontChange}
                className="font-dropdown"
                icon={FaFont}
              />

              <CustomDropdown
                options={fontSizeOptions}
                value={fontSize}
                label={"Font Size"}
                onChange={handleFontSizeChange}
                className="font-size-dropdown"
                icon={TbTextSize}
              />

              <CustomDropdown
                options={lineHeightOptions}
                value={lineHeight}
                label={"Line Height"}
                onChange={handleLineHeightChange}
                className="line-height-dropdown"
                icon={TbLineHeight}
              />

              <CustomDropdown
                options={textAlignOptions}
                value={textAlignment}
                label={"Text Alignment"}
                onChange={handleTextAlignmentChange}
                className="text-align-dropdown"
                icon={TbAlignJustified}
              />
              <CustomDropdown
                options={pageFormatOptions}
                value={pageFormat}
                label={"Page Format"}
                onChange={handlePageFormatChange}
                className="text-align-dropdown"
                icon={TbFile}
              />
              <input type="color" value={selectedColor} onChange={(e) => handleColorChange(e.target.value)} />

              <button className='inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' onClick={(e) => {
                e.preventDefault(); handleDownloadPdf();
              }}><TbDownload className='mr-2' /> Download</button>
             {user && (user.email === "cvdesigner.ai@gmail.com" || user.email === "resumedesign.ai@gmail.com") && (
                <button 
                  className='inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={(e) => {
                    e.preventDefault(); 
                    handleImageDownload();
                  }}
                >
                  <TbDownload className='mr-2' /> Image
                </button>
              )}

            </div>
            <Link to="/documents">
              <button className="text-md text-gray-100 rounded italic py-1 my-1 mr-4 ml-4 px-2 hover:text-gray-400 focus:outline-none focus:border-gray-500">
                <TbX />
              </button>
            </Link>
          </div>

          <div className='flex-1 overflow-auto custom-scrollbar p-5 coverletter-preview' >
            <div id="coverletter" className={`layout-${layoutType} content-${textAlignment} coverletter-container ${selectedStyle.toLowerCase()} ${selectedFont}`}
              style={{
                ...coverLetterStyles,
                fontSize: fontSizeMap[fontSize],
                lineHeight: lineHeight,
                transform: 'scale(1)', transformOrigin: 'center top'
              }}>
              {layoutComponents[layoutType]?.()}
            </div>
          </div>
          <ModalComponent isOpen={modalOpen} onClose={() => setModalOpen(false)}>
  <div className='download-modal'>
    {isDownloading ? (
      <>
        <div className='mid-wrap'>
          <div className="loader"></div>
        </div>
        <h2 className='mb-3'>Downloading your cover letter...</h2>
        <p>Please wait while we're preparing your PDF!</p>
      </>
    ) : downloadError ? (
      <>
        <h2 className='mb-3'>Sorry, something went wrong.</h2>
        <p>Please try again later or contact support for help.</p>
      </>
    ) : (
      <>
        <div className='mid-wrap'>
          <div>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>
        <h2 className='mb-3'>Download Successful!</h2>
        <p>Good luck with your job search!</p>
      </>
    )}
  </div>
</ModalComponent>


        </div>
      </div>
    )}

    {showPreviewOnly && (
      <div id="coverletter" className={`layout-${layoutType} content-${textAlignment} coverletter-container ${selectedStyle.toLowerCase()} ${selectedFont}`}
        style={{
          ...coverLetterStyles,
          fontSize: fontSizeMap[fontSize],
          lineHeight: lineHeight,
          transform: 'scale(0.30)', transformOrigin: 'left top'
        }}>
        {layoutComponents[layoutType]?.()}
      </div>
    )}
  </>);
};

export default CoverLetterPreview;
