import React, { useEffect, useState, useRef} from 'react';
import { toPng } from 'html-to-image';
import UPNG from 'upng-js'; 
import DOMPurify from 'dompurify'; 
import { marked } from 'marked';
import { FaEnvelope, FaPhone, FaHome, FaGlobeAmericas, FaLinkedin, FaFont, FaTextHeight, FaRegCheckCircle, FaLocationArrow } from 'react-icons/fa'; 
import cssString from './templateStyles.css?raw'; 
import "./fonts.css";
import "./templateStyles.css";
import { saveResume, saveMetadata, duplicateResume2, updateResume2, fetchResumeById} from '../../services/resumeService';
import CustomDropdown from './CustomDropdown'; 
import { TbTextSize, TbLetterCaseToggle, TbLineHeight, TbSection, TbDownload, TbAlignJustified, TbX, TbFile, TbCircleCheck  } from "react-icons/tb";
import ModalComponent from '../ModalComponent';
import { useResume } from '../../context/ResumeContext'; 
import { images } from '../../util/loadImages';
import { useAuth } from '../../context/AuthContext';
import { FaCopy } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig'; 
import { BiArrowBack } from 'react-icons/bi'; 

import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { BiUser, BiDollarCircle, BiSolidDashboard, BiSupport, BiLogOut, BiChevronDown, BiChevronUp, BiLogIn } from "react-icons/bi";
import LoadingSpinner from '../LoadingSpinner';

const ResumePreview = ({sections, resumeData, onSectionClick, isPhotoVisible, setShowTemplateCollection, showResume, onToggleTemplates, showPreviewOnly = false, updateResumeData }) => {
    const { presentStyle, setPresentStyle } = useResume();//isSingleLayout(true);
  const { isSingleLayout, setIsSingleLayout } = useResume(false);
  const { isPhotoThere } = useResume();
  const { currentLanguage } = useResume();
  const { user, logout } = useAuth();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isPhotoThere, setIsPhotoThere] = useState(false);
  
  const [modalOpen, setModalOpen] = useState(false); 
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(false); 
  const [pageFormat, setPageFormat] = useState('A4');
  const [showTemplates, setShowTemplates] = useState(false);
  
  const [publishedResumeId, setPublishedResumeId] = useState(null);
  const [isPublished, setIsPublished] = useState(false); // To track if the resume is published
  // const isEditor = (user && user.email === 'samson3d@gmail.com');
  const [isEditor, setIsEditor] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

    // Mapping of custom language codes to Intl locale identifiers
    const languageToLocale = {
      es: 'es-ES', // Spanish (Spain)
      'es-MX': 'es-ES', // Spanish (Mexico)
      fr: 'fr-FR', // French (France)
      de: 'de-DE', // German (Germany)
      it: 'it-IT', // Italian (Italy)
      nl: 'nl-NL', // Dutch (Netherlands)
      pt: 'pt-PT', // Portuguese (Portugal)
      'pt-BR': 'pt-PT', // Portuguese (Brazil)
      pl: 'pl-PL', // Polish (Poland)
      cs: 'cs-CZ', // Czech (Czech Republic)
      ja: 'ja-JP' // Japanese (Japan)
    };

    // Dynamic locale setting based on current language
  const locale = languageToLocale[currentLanguage] || 'en-US'; // Default to English

  const [selectedLanguage, setSelectedLanguage] = useState('fr-FR'); // Default to English

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };
  
  const sectionOrder = resumeData.sectionOrder; 
  
  const defaultColorsByStyle = {
    Professional: '#293993', 
    Executive: '#000', 
    Modern: '#994731',
    Creative: '#000',
    Impactful:'#096e65',
    Visionary: '#242d3e',
    Dynamic: '#c42e08',
    Explorer: '#283c50',
    Classic: '#000',
    Elegant: '#000',
    Bold: '#4b93eb',
    Expertise: '#d3f1ff',
    Aspirations: '#ffd3d3',
    Minimalist: '#000',
    Influential: '#283c50',
    Inspired: '#f0e4d4',
    Versatile: '#487ed5',
    Vibrant: '#1469b8',
    Proactive: '#000',
    Empowering: '#ffb8a2',
    Ambitious: '#0e5dfb',
    Innovative: '#000'

  };
 
  const [selectedStyle, setSelectedStyle] = useState('Professional');  
  const [selectedFont, setSelectedFont] = useState('Calibri');
  const [fontSize, setFontSize] = useState('M'); 
  const [textTransform, setTextTransform] = useState('none'); 
  const [lineHeight, setLineHeight] = useState('1.25');
  const [sectionSpacing, setSectionSpacing] = useState('normal'); 
  const [selectedColor, setSelectedColor] = useState('#000'); //defaultColorsByStyle[selectedStyle]
  const [resumeID, setResumeID] = useState(); 
  const [textAlignment, setTextAlignment] = useState();  
  const [photoVisible, setPhotoVisible] = useState();  
  

  const fontOptions = [
    { label: 'Roboto', value: 'roboto-regular' },
    { label: 'Ubuntu', value: 'ubuntu-regular' },
    { label: 'Rubik', value: 'rubik-regular'},
    { label: 'PT Serif', value: 'pt-serif-regular' },
    { label: 'Lexend', value: 'lexend-regular' },
    { label: 'ABeeZee', value: 'abeezee-regular' },
    { label: 'Poppins', value: 'poppins-regular' },
    { label: 'Prompt', value: 'prompt-regular' },   
    { label: 'Montserrat', value: 'montserrat-regular'},
    { label: 'Lora', value: 'lora-regular'},
    { label: 'Raleway', value: 'raleway-regular'},
    { label: 'Roboto Mono', value: 'roboto-mono' },
    { label: 'Playwrite', value: 'playwrite-de-grund'}
  ];
  const lineHeightOptions = [
    { label: '1.15', value: '1.15' },
    { label: '1.25', value: '1.25' },
    { label: '1.30', value: '1.30' },
    { label: '1.35', value: '1.35' },
    { label: '1.45', value: '1.45' },
    { label: '1.55', value: '1.55' },
    { label: '1.75', value: '1.75' },
    { label: '2', value: '2' },
    { label: '2.15', value: '2.15' }
  ];
  const fontSizeOptions = [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: 'XXL', value: 'XXL' },
    { label: 'XXXL', value: 'XXXL' }
  ];
  const textTransformOptions = [
    { label: 'None', value: 'none' },
    { label: 'Uppercase', value: 'uppercase' },
    { label: 'Capitalize', value: 'capitalize' }
  ];
  const sectionSpacingOptions = [
    { label: 'Very Close', value: 'veryClose' },
    { label: 'Close', value: 'close' },
    { label: 'Normal', value: 'normal' },
    { label: 'Wide', value: 'wide' },
    { label: 'Very Wide', value: 'veryWide' }
  ];  




  // Text alignment options
  const textAlignOptions = [
      { label: 'Left', value: 'left' },
      { label: 'Center', value: 'center' },
      { label: 'Right', value: 'right' },
      { label: 'Justify', value: 'justify' }
  ];
  const pageFormatOptions = [
    { label: 'A4', value: 'A4' },
    { label: 'Letter', value: 'Letter' }
];
const convertMarkdownToHtml = (markdown) => {
  const html = marked(markdown);
  return { __html: DOMPurify.sanitize(html) };
};
  // Function to handle text alignment change
  const handleTextAlignmentChange = async (newAlignment) => {
    setTextAlignment(newAlignment);
  
    const updatedResumeData = {
      ...resumeData,
      metadata: {
        ...resumeData.metadata,
        textAlignment: newAlignment,
      },
    };
  
    try {
      await saveResume(updatedResumeData);
      updateResumeData(updatedResumeData);
      console.log('Text alignment updated successfully');
    } catch (error) {
      console.error('Failed to update text alignment:', error);
    }
  };
  
  const handleCopy = () => {
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000); // Reset the message after 2 seconds
  };
  
  // const handleCopyID = () => {
   
  // };

  const handlePublish = async () => {
    const duplicatedResume = {
      ...resumeData,
      metadata: {
        ...resumeData.metadata,
        userID: 'v6inrqLbWSaY4n87ft07Ynz1l2w1'
      }
    };
  
    try {
      const newResumeId = await duplicateResume2(resumeID, duplicatedResume);
      setPublishedResumeId(newResumeId);
      setIsPublished(true);
    } catch (error) {
      console.error('Error publishing resume:', error);
    }
  };
  
const handleUpdatePublish = async () => {
  const updatedResume = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      userID: process.env.REACT_APP_EXAMPLE_USER //'v6inrqLbWSaY4n87ft07Ynz1l2w1' 
    }
  };

  try {
    await updateResume2(publishedResumeId, updatedResume);
  } catch (error) {
    console.error('Error updating published resume:', error);
  }
};
  
  
  const handlePageFormatChange = async (newFormat) => {
    setPageFormat(newFormat); 
    try {
      await saveMetadata(resumeID, { pageFormat: newFormat });
      console.log('Resume page format updated successfully');
    } catch (error) {
      console.error('Failed to update resume page format:', error);
    } 
};
  const sanitizeHtml = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

//  const formatDate = (dateString) => {
//   const formattedDate = new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
//   console.log(`Formatting date: ${dateString} -> ${formattedDate}`); // Debug log
//   return formattedDate;
// };

/*-----------to get editor for examples publisher---------------*/
const fetchEditorEmails = async () => {
  try {
    const docRef = doc(db, 'admin', process.env.REACT_APP_ADMIN); //'IStA6fJzjiHovjcz6B0V'
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.editors || [];
    } else {
      console.log("No such document!");
      return [];
    }
  } catch (error) {
    console.error("Error fetching editor emails:", error);
    return [];
  }
};

useEffect(() => {
  const checkIfEditor = async () => {
    if (user && user.email) {
      const editorEmails = await fetchEditorEmails();
      const isUserEditor = editorEmails.includes(user.email);
      setIsEditor(isUserEditor);
    }
  };

  checkIfEditor();
}, [user]);
/*-----------------------*/

  const toSlug = (label) => {
    return label.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  };

  const renderInput = (input) => {
    const inputSlug = toSlug(input.inputLabel);
    switch (input.inputType) {
      case 'textarea':
        return <div key={inputSlug} className={`textarea ${inputSlug}`} dangerouslySetInnerHTML={sanitizeHtml(input.inputValue)} />;
      case 'upload':
        return input.inputValue ? <img key={inputSlug} src={input.inputValue} alt={input.inputLabel} className={`upload ${inputSlug}`} /> : null;
      case 'date':
      case 'daterange':
        return <p key={inputSlug} className={`date ${inputSlug}`}>{formatDate(input.inputValue)}</p>;
      default:
        return <p key={inputSlug} className={inputSlug}>{input.inputValue}</p>;
    }
  };


  const renderSection = (key) => {
    if (key === 'skills') {
      return renderSkillsSection(); 
    }
    if (key === 'softSkills') {
      return renderSoftSkillsSection(); 
    }
    if (key === 'languages') {
      return renderLanguagesSection(); 
    }
    if (key === 'contactDetails') {
      return renderContactDetailsSection();
    }
    if (key === 'professionalSummary') {
      return renderProfessionalSummarySection();
    }
    if (key === 'education') {
      return renderEducationSection();
    }
    if (key === 'workExperience') {
      return renderWorkExperienceSection();
    }
    if (key === 'projects') {
      return renderProjectsSection();
    }
    if (key === 'references') {
      return renderReferencesSection();
    }
    if (key === 'achievements') {
      return renderAchievementsSection();
    }
    if (key === 'awards') {
      return renderAwardsSection();
    }
    if (key === 'courses') {
      return renderCoursesSection();
    }
    if (key === 'certifications') {
      return renderCertificationsSection();
    }
    if (key === 'internships') {//check id internship or internships
      return renderInternshipsSection();
    }
    if (key === 'hobbies') {
      return renderHobbiesSection();
    }
    if (key === 'volunteer') {
      return renderVolunteerSection();
    }
    if (key === 'profileLinks') {
      return renderProfileLinksSection();
    }
    const section = sections[key];
    if (!section || !section.isDisplay || !section.isShow || key === 'personalDetails') return null;

    return (
      <div key={key} onClick={() => onSectionClick && onSectionClick(key)} className={`section ${toSlug(section.sectionTitle || '')}`}>
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{section.customSectionTitle ? section.customSectionTitle : section.sectionTitle}</h2>
        {section.inputGroups.map((group, index) => (
          <div key={index} className={`section-content input-group ${toSlug(group.groupTitle || '')}`}>
            {group.inputs.map(renderInput)} 
          </div>
        ))}
      </div>
    );
  };

   // Set initial state from resumeData.metadata
   useEffect(() => {
    if (resumeData.metadata) {
      setResumeID(resumeData.metadata.resumeID);
      setSelectedStyle(resumeData.metadata.selectedStyle || 'Professional');
      setPresentStyle(resumeData.metadata.selectedStyle || 'Professional');
      setSelectedFont(resumeData.metadata.selectedFont || 'Calibri');
      setFontSize(resumeData.metadata.fontSize || 'M');
      setTextTransform(resumeData.metadata.textTransform || 'none');
      setLineHeight(resumeData.metadata.lineHeight || '1.25');
      setSectionSpacing(resumeData.metadata.sectionSpacing || 'normal');
      setTextAlignment(resumeData.metadata.textAlignment || 'left');
      setSelectedColor(resumeData.metadata.selectedColor || defaultColorsByStyle[resumeData.metadata.selectedStyle]);
      setPageFormat(resumeData.metadata.pageFormat || 'A4');
    }
  }, [resumeData.metadata, setPresentStyle]);
  

useEffect(() => {
  if (!sections || !sections.personalDetails) return;

  let isPhotoThere = false;

  const personalDetails = sections.personalDetails;
  if (personalDetails.isDisplay && personalDetails.isShow) {
    personalDetails.inputGroups.forEach(group => {
      group.inputs.forEach(input => {
        if (input.inputKey === 'photo') {
          isPhotoThere = true;
        }
      });
    });
  }

  const photoInput = sections.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo");
  const photoVisibility = photoInput.settings.photoVisible;

  if (photoVisible !== photoVisibility) {
    setPhotoVisible(photoVisibility);
  }
}, [sections, photoVisible]);


const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString(locale, { year: 'numeric', month: 'short' });
};

const formatDateRange = (startDate, endDate, isPresent) => { 
  if (!startDate) return;
  const startFormatted = formatDate(startDate); // Format start date
  if (isPresent) {
    return `${startFormatted} - Present`;
  } else {
    const endFormatted = formatDate(endDate); // Format end date
    return `${startFormatted} - ${endFormatted}`;
  }
};
const [isLoading, setIsLoading] = useState(true);
const renderPhotoSection = () => { 
  const personalDetails = sections.personalDetails;
  if (!personalDetails || !personalDetails.isDisplay || !personalDetails.isShow) return null;

  // Initialize variables to hold the values
  let photoUrl = ''; 

  // Iterate over inputs to assign values
  personalDetails.inputGroups.forEach(group => {
    group.inputs.forEach(input => {
      if (input.inputKey === 'photo') {
        photoUrl = input.inputValue;
        //isPhotoVisible = input.settings.photoVisible;
      }
    });
  });  
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };
  // console.log('photo url'+ photoUrl)
  // console.log(photoVisible)
  return (
    photoUrl && photoVisible ? ( 
        <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('personalDetails')}} className='personal-photo'>
          {isLoading && <div className="loading-indicator"> <LoadingSpinner displayType='inline' /></div>}
          <img
            src={photoUrl}
            alt="Personal Photo"
            className="personal-photo-img"
            style={{height: 'fit-content', display: isLoading ? 'none' : 'block'}}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
    ) : null
  );
};

  const renderPersonalDetailsSection = () => {
    const personalDetails = sections.personalDetails;
    if (!personalDetails || !personalDetails.isDisplay || !personalDetails.isShow) return null;
  
    // Initialize variables to hold the values
    let firstName = '';
    let lastName = '';
    let jobTitle = '';
  
    // Iterate over inputs to assign values
    personalDetails.inputGroups.forEach(group => {
      group.inputs.forEach(input => {
        if (input.inputKey === 'firstName') {
          firstName = input.inputValue;
        } else if (input.inputKey === 'lastName') {
          lastName = input.inputValue;
        } else if (input.inputKey === 'jobTitle') {
          jobTitle = input.inputValue;
        } 
      });
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('personalDetails')}} className="section personal-details-section"> 
      <div className='section-content personal-info'>
        <h1 style={{
        fontSize: `${textTransform === 'uppercase' ? h1 - 2 : h1}px`, 
        textTransform: textTransform
      }}>{`${firstName} ${lastName}`}</h1>
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }} >{jobTitle}</h2>
      </div>        
      </div>
    );
  };
  const renderProfessionalSummarySection = () => {
    const professionalSummary = sections.professionalSummary;
    if (!professionalSummary || !professionalSummary.isDisplay || !professionalSummary.isShow) return null;
  
    const summaryContent = professionalSummary.inputGroups.map((group, index) => {
      let summary = group.inputs.find(input => input.inputKey === 'summary').inputValue;
      if (!summary) return null; // Skip rendering if the summary is empty
  
      // Sanitize the summary content
      const sanitizedSummary = sanitizeHtml(summary);
  
      return (
        <div key={index} className="textarea professional-summary-content" dangerouslySetInnerHTML={sanitizedSummary}></div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('professionalSummary')}} className="section professional-summary-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{professionalSummary.customSectionTitle || professionalSummary.sectionTitle}</h2>
        <div className='section-content'>{summaryContent}</div>
      </div>
    );
  };

  const renderAchievementsSection = () => {
    const achievements = sections.achievements;
    if (!achievements || !achievements.isDisplay || !achievements.isShow) return null;
  
    const achievementsContent = achievements.inputGroups.map((group, index) => {
      const achievementInput = group.inputs.find(input => input.inputKey === 'achievements');
      if (!achievementInput) return null;
      let achievement = achievementInput.inputValue;
      if (!achievement) return null;
      const sanitizedAchievement = convertMarkdownToHtml(achievement);
      return (
        <div key={index} className="textarea achievements-content" dangerouslySetInnerHTML={sanitizedAchievement}></div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('achievements')}} className="section achievements-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }} className="section-title">{achievements.customSectionTitle || achievements.sectionTitle || 'Achievements'}</h2>
        <div className='section-content'>{achievementsContent}</div>
      </div>
    );
  };
  function addStyles(selector, rules) {
    const styleSheet = document.createElement("style");
    document.head.appendChild(styleSheet);
    styleSheet.sheet.insertRule(`${selector} { ${rules} }`, styleSheet.sheet.cssRules.length);
  }
  function getJobTitle(data) {
    // Access the 'personalDetails' section within 'formObject'
    const personalDetails = data.formObject.personalDetails;
    // Ensure 'inputGroups' is defined
    if (personalDetails.inputGroups && personalDetails.inputGroups.length > 0) {
        // Iterate through 'inputGroups' array
        for (const group of personalDetails.inputGroups) {
            // Iterate through 'inputs' array within each group
            for (const input of group.inputs) {
                // Check if the 'inputKey' is 'jobTitle'
                if (input.inputKey === "jobTitle") {
                    // Return the 'inputValue' corresponding to 'jobTitle'
                    return input.inputValue;
                }
            }
        }
    }
    // Return null if 'jobTitle' is not found
    return null;
}

  const imageToBase64 = async (url) => {
    const response = await fetch(`https://rd-server-418cf5202d66.herokuapp.com/fetch-image?url=${encodeURIComponent(url)}`);
    const data = await response.json();
    return data.dataUrl;
  };
  
  const handleImageDownload = async () => {
    const resumeElement = document.getElementById('resume');
  
    if (!resumeElement) {
      console.error('Resume element not found');
      return;
    }
    resumeElement.classList.add('hide-before-element');
    // Set styles for centering and sizing the content
    // resumeElement.style.display = 'flex';
    // resumeElement.style.justifyContent = 'center';
    resumeElement.style.width = '8.3in';
    resumeElement.style.height = '11.7in';
    resumeElement.style.margin = '0px';
  
    try {
      // Convert the HTML to a PNG image using html-to-image with increased resolution
      const dataUrl = await toPng(resumeElement, {
        backgroundColor: '#ffffff',
        pixelRatio: 1.5 // Increase pixel ratio for higher resolution
      });
  
      // Convert the data URL to a Uint8Array
      const byteString = atob(dataUrl.split(',')[1]);
      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }
  
      // Decode the PNG image to get the image data
      const decoded = UPNG.decode(byteArray);
      const rgba = UPNG.toRGBA8(decoded);
  
      // Compress the PNG image
      const compressed = UPNG.encode(rgba, decoded.width, decoded.height, 256);
  
      // Create a Blob from the compressed data
      const blob = new Blob([compressed], { type: 'image/png' });
  
      // Generate a filename based on the resume's content, such as the job title
      const jobTitle = getJobTitle(resumeData)?.replace(/\s+/g, '-') || 'resume'; // Use job title or default to 'resume'
      const fileName = `${jobTitle}-Resume-Example.png`;
      
      // Create a link element to download the compressed image
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error('Error generating and compressing image:', error);
    } finally {
      // Optionally, reset styles or any state after download is complete
      resumeElement.classList.remove('hide-before-element');
      resumeElement.style.display = '';
      resumeElement.style.justifyContent = '';
      resumeElement.style.width = '';
      resumeElement.style.height = '';
      resumeElement.style.margin = '';
    }
  };
  

  
//   const handleImageDownload = async () => {
//     const resumeElement = document.getElementById('resume');

//     if (!resumeElement) {
//         console.error('Resume element not found');
//         return;
//     }

//     const images = resumeElement.getElementsByTagName('img');
//     const originalUrls = Array.from(images).map(img => img.src);

//     const imageLoadPromises = Array.from(images).map(async (img) => {
//         try {
//             const base64 = await imageToBase64(img.src);
//             img.src = base64;
//         } catch (error) {
//             console.error('Error converting image to Base64:', error);
//         }
//     });

//     try {
//         await Promise.all(imageLoadPromises);
//     } catch (error) {
//         console.error('Error loading images:', error);
//     }

//     addStyles("#resume:before", "border: none;");

//     // Save original styles
//     const originalHeight = resumeElement.style.height;
//     const originalOverflow = resumeElement.style.overflow;

//     // Set fixed height and hide overflow
//     resumeElement.style.height = '11in';
//     resumeElement.style.overflow = 'hidden';

//     const scaleFactor = 1.5;
//     const originalWidth = resumeElement.offsetWidth;
//     const scaledWidth = originalWidth * scaleFactor;
//     const scaledHeight = 11 * 96 * scaleFactor; // 11 inches converted to pixels at 96 DPI

//     try {
//         const dataUrl = await toPng(resumeElement, {
//             backgroundColor: '#ffffff',
//             width: scaledWidth,
//             height: scaledHeight,
//             style: {
//                 margin: '0 auto',
//                 display: 'block',
//                 transform: `scale(${scaleFactor})`,
//                 transformOrigin: 'top left'
//             }
//         });

//         const canvas = document.createElement('canvas');
//         canvas.width = scaledWidth;
//         canvas.height = scaledHeight;
//         const ctx = canvas.getContext('2d');
//         const image = new Image();
//         image.src = dataUrl;
//         image.onload = () => {
//             ctx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
//             canvas.toBlob((blob) => {
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 const jobTitle = getJobTitle(resumeData)?.replace(/\s+/g, '-') || 'resume';               
//                 link.download = `${jobTitle}-CV-Example.webp`;
//                 document.body.appendChild(link);
//                 link.click();
//                 link.parentNode.removeChild(link);
//                 // Revert the image sources to their original URLs
//                 Array.from(images).forEach((img, index) => {
//                     img.src = originalUrls[index];
//                 });

//                 // Revert the styles back to original
//                 resumeElement.style.height = originalHeight;
//                 resumeElement.style.overflow = originalOverflow;

//             }, 'image/webp'); // Quality parameter is set to 1.0 (highest quality)
//         };

//     } catch (error) {
//         console.error('Error generating image:', error);
//         // Revert the styles back to original in case of error
//         resumeElement.style.height = originalHeight;
//         resumeElement.style.overflow = originalOverflow;
//     }
// };





  
  
  // const renderContactDetailsSection = () => {
  //   const contactDetails = sections.contactDetails;
  //   if (!contactDetails || !contactDetails.isDisplay || !contactDetails.isShow) return null;  
  //   const iconMapper = {
  //     email: <FaEnvelope />,
  //     phone: <FaPhone />,
  //     city: <FaHome />,
  //     website: <FaGlobeAmericas />,
  //     linkedin: <FaLinkedin />,
  //     customfield: <FaLocationArrow />
  //   };
  //   const contactInputs = contactDetails.inputGroups.map((group) =>
  //     group.inputs.map((input, index) => {
  //       if (!input.show || !input.inputValue) return null;   
  //       const icon = iconMapper[input.inputKey.toLowerCase()] || null;  
  //       return (
  //         <div key={index} className={`contact-detail ${input.inputKey.toLowerCase()}`}>
  //           <p className="contact-value"><span className='contact-icon'>{icon}</span> <span className='contact-text'>{input.inputValue}</span></p>
  //         </div>
  //       );
  //     })
  //   );  
  //   return (
  //     <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('contactDetails')}} className="section contact-details-section">
  //       <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{contactDetails.customSectionTitle || contactDetails.sectionTitle}</h2>
  //       <div className="section-content contact-details-content">
  //         {contactInputs}
  //       </div>
  //     </div>
  //   );
  // };
  const renderContactDetailsSection = () => {
    const contactDetails = sections.contactDetails;
    if (!contactDetails || !contactDetails.isDisplay || !contactDetails.isShow) return null;
  
    const iconMapper = {
      email: <FaEnvelope />,
      phone: <FaPhone />,
      address: <FaHome />, // Use one icon for city, country, and postalCode
      website: <FaGlobeAmericas />,
      linkedin: <FaLinkedin />,
      customfield: <FaLocationArrow />
    };
  
    // Helper function to format address information
    const getAddressInfo = (inputs) => {
      const addressInfo = {};
      inputs.forEach(input => {
        if (['city', 'country', 'postalcode'].includes(input.inputKey.toLowerCase()) && input.inputValue) {
          addressInfo[input.inputKey] = input.inputValue;
        }
      });
      return addressInfo;
    };
  
    const ensureHttps = (url) => {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
      }
      return url;
    };
  
    const contactInputs = contactDetails.inputGroups.map((group) => {
      const address = getAddressInfo(group.inputs);
      const addressString = Object.values(address).join(', ');
  
      let orderedElements = {
        email: null,
        phone: null,
        address: addressString ? (
          <div key="address" className="contact-detail address">
            <p className="contact-value"><span className='contact-icon'>{iconMapper['address']}</span> <span className='contact-text'>{addressString}</span></p>
          </div>
        ) : null,
        linkedin: null,
        website: null,
        customfield: null
      };
  
      group.inputs.forEach((input, index) => {
        if (!input.show || !input.inputValue || ['city', 'country', 'postalcode'].includes(input.inputKey.toLowerCase())) return;
  
        const icon = iconMapper[input.inputKey.toLowerCase()] || null;
        let element = (
          <div key={index} className={`contact-detail ${input.inputKey.toLowerCase()}`}>
            <p className="contact-value"><span className='contact-icon'>{icon}</span> <span className='contact-text'>{input.inputValue}</span></p>
          </div>
        );
  
        // Handling clickable links
        if (input.inputKey.toLowerCase() === 'email') {
          element = (
            <div key={index} className={`contact-detail ${input.inputKey.toLowerCase()}`}>
              <a href={`mailto:${input.inputValue}`} className="contact-value">
                <span className='contact-icon'>{icon}</span>
                <span className='contact-text'>{input.inputValue}</span>
              </a>
            </div>
          );
        } else if (input.inputKey.toLowerCase() === 'linkedin' || input.inputKey.toLowerCase() === 'website' || input.inputKey.toLowerCase() === 'customfield') {
          const urlWithHttps = ensureHttps(input.inputValue);
          element = (
            <div key={index} className={`contact-detail ${input.inputKey.toLowerCase()}`}>
              <a href={urlWithHttps} target="_blank" rel="noopener noreferrer" className="contact-value">
                <span className='contact-icon'>{icon}</span>
                <span className='contact-text'>{input.inputValue}</span>
              </a>
            </div>
          );
        }
  
        if (input.inputKey.toLowerCase() in orderedElements) {
          orderedElements[input.inputKey.toLowerCase()] = element;
        }
      });
  
      // Combine all elements in the specified order
      return Object.values(orderedElements).filter(el => el !== null);
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('contactDetails')}} className="section contact-details-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }} className="section-title">{contactDetails.customSectionTitle || contactDetails.sectionTitle}</h2>
        <div className="section-content contact-details-content">
          {contactInputs}
        </div>
      </div>
    );
  };
  
  
  
  const renderEducationSection = () => {
    const education = sections.education;
    if (!education || !education.isDisplay || !education.isShow) return null;
  
    // const formatDateRange = (startDate, endDate, isPresent) => { 
    //   if (!startDate) { return }
    //   if (isPresent) {  return `${startDate} - Present`;} else { 
    //     return `${startDate} - ${endDate}`;}
    // };      
  
    const educationContent = education.inputGroups.map((group, index) => {
      const { inputs } = group;
      const courseName = inputs.find(input => input.inputKey === 'courseName').inputValue;
      const schoolName = inputs.find(input => input.inputKey === 'collegeName').inputValue;
      const startDate = inputs.find(input => input.inputKey === 'startDate').inputValue;
      const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
      const isPresent = inputs.find(input => input.inputKey === 'present').inputValue;
      const description = inputs.find(input => input.inputKey === 'description').inputValue;
      return (
        <div key={index} className="education-entry sub-section">
          <div className='sub-header'>
            <div className='sub-section-col'>
            <h3 className="course-name">{courseName}</h3>
            <p className="school-name">{schoolName}</p>
            </div>
            <div className='sub-section-col'>
            <p className="date-range">{formatDateRange(startDate, endDate, isPresent)}</p>
            </div>
          </div>
          {description && <p className="textarea description" dangerouslySetInnerHTML={sanitizeHtml(description)}></p>}
        </div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('education')}} className="section education-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{education.customSectionTitle || education.sectionTitle}</h2>
        <div className="section-content education-content">
          {educationContent}
        </div>
      </div>
    );
  };
  
  const renderWorkExperienceSection = () => {
    const workExperience = sections.workExperience;
    if (!workExperience || !workExperience.isDisplay || !workExperience.isShow) return null;
  
    const workExperienceContent = workExperience.inputGroups.map((group, index) => {
      const { inputs } = group;
      const position = inputs.find(input => input.inputKey === 'position').inputValue;
      const companyName = inputs.find(input => input.inputKey === 'companyName').inputValue;
      const startDate = inputs.find(input => input.inputKey === 'startDate').inputValue;
      const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
      const isPresent = inputs.find(input => input.inputKey === 'present').inputValue;
      const workSummary = inputs.find(input => input.inputKey === 'workSummary').inputValue;
  
      return (
        <div key={index} className="work-experience-entry sub-section">
          <div className='sub-header'>
            <div className='sub-section-col'>
              <h3 className="position">{position}</h3>
              <p className="company-name">{companyName}</p>
            </div>
            <div className='sub-section-col'>
              <p className="date-range">{formatDateRange(startDate, endDate, isPresent)}</p>
            </div>
          </div>
          {workSummary && <p className="textarea work-summary" dangerouslySetInnerHTML={convertMarkdownToHtml(workSummary)}></p>}
        </div>
      );
    });
  
    return (
      <div onClick={() => { handleCloseTemplate(); onSectionClick && onSectionClick('workExperience') }} className="section work-experience-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }} className="section-title">{workExperience.customSectionTitle || workExperience.sectionTitle}</h2>
        <div className="section-content work-experience-content">
          {workExperienceContent}
        </div>
      </div>
    );
  };
  
  
  const renderProjectsSection = () => {
    const projects = sections.projects;
    if (!projects || !projects.isDisplay || !projects.isShow) return null;
  
    // Function to format date range or single date
    // const formatDateRange = (startDate, endDate, isPresent) => { 
    //   if (!startDate) { return }
    //   if (isPresent) {  return `${startDate} - Present`;} else { 
    //     return `${startDate} - ${endDate}`;}
    // };
  
    const projectsContent = projects.inputGroups.map((group, index) => {
      const { inputs } = group;
      const projectTitle = inputs.find(input => input.inputKey === 'projectTitle').inputValue;
      const role = inputs.find(input => input.inputKey === 'role').inputValue;
      const startDate = inputs.find(input => input.inputKey === 'startDate').inputValue;
      const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
      const isPresent = inputs.find(input => input.inputKey === 'present').inputValue;
      const description = inputs.find(input => input.inputKey === 'description').inputValue;
  
      return (
        <div key={index} className="project-entry sub-section">         
          <div className='sub-header'>
            <div className='sub-section-col'>
            <h3 className="project-title">{projectTitle}</h3>
            <p className="role">{role}</p>          
            </div>
            <div className='sub-section-col'>
            <p className="date-range">{formatDateRange(startDate, endDate, isPresent)}</p>
            </div>
          </div> 
          <p className="textarea project-description" dangerouslySetInnerHTML={sanitizeHtml(description)}></p>
        </div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('projects')}} className="section projects-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{projects.customSectionTitle || projects.sectionTitle}</h2>
        <div className="section-content projects-content">
          {projectsContent}
        </div>
      </div>
    );
  };
  
  const renderSkillsSection = () => {
    const skills = sections.skills;
    if (!skills || !skills.isDisplay || !skills.isShow) return null;
  
    const skillStyleSetting = skills.settings.find(setting => setting.inputKey === "skillStyle");
    const skillStyle = skillStyleSetting && skillStyleSetting.inputValue ? skillStyleSetting.inputValue : 'circle';
    
    let skillsContent;
    if(skillStyle === 'none') {
      skillsContent = (
        <ul className="skills-list no-bullets">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'skill').inputValue;
            if (!skillName) return null; // Skip rendering if there is no skillName
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else if(skillStyle === 'bullets') {
      skillsContent = (
        <ul className="skills-list">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'skill').inputValue;
            if (!skillName) return null; // Skip rendering if there is no skillName
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else if(skillStyle === 'badge') {
      skillsContent = (
        <ul className="badge-list">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'skill').inputValue;
            if (!skillName) return null; 
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else {
      skillsContent = skills.inputGroups.map((group, index) => {
        const skillName = group.inputs.find(input => input.inputKey === 'skill').inputValue;
        if (!skillName) return null; // Skip rendering this group if there is no skillName
        
        const skillLevel = group.inputs.find(input => input.inputKey === 'level').inputValue;
        
        let skillScore = 1; // Default to 1
        if(skillLevel === "Excellent") skillScore = 5;
        else if(skillLevel === "Very good") skillScore = 4;
        else if(skillLevel === "Good") skillScore = 3;
        else if(skillLevel === "Moderate") skillScore = 2;
        else if(skillLevel === "Beginner") skillScore = 1;
    
        const renderSkillVisualization = () => {
          const backgroundColor = '#ffffff6b';
          const foregroundColor = 'white';
          
          if(skillStyle === 'circle' || skillStyle === 'square') {
            return Array.from({ length: 5 }).map((_, circleIndex) => (
              <span key={`${skillName}-${circleIndex}`} className={circleIndex < skillScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '10px',
                height: '10px',
                borderRadius: skillStyle === 'circle' ? '50%' : '0',
                backgroundColor: circleIndex < skillScore ? foregroundColor : backgroundColor,
              }} />
            ));
          } else if(skillStyle === 'bar' || skillStyle === 'bar-rounded') {
            const levelPercentage = skillScore * 20;
            return (
              <div className="progress-bar-container ratingBackColor" style={{ width: '100%', backgroundColor, borderRadius: skillStyle === 'bar-rounded' ? '5px' : '0' }}>
                <div className="progress-bar ratingForeColor" style={{ width: `${levelPercentage}%`, backgroundColor: foregroundColor, height: '6px', borderRadius: skillStyle === 'bar-rounded' ? '5px' : '0' }}></div>
              </div>
            );
          } else if(skillStyle === 'dash-line') {
            return Array.from({ length: 5 }).map((_, dashIndex) => (
              <span key={`${skillName}-${dashIndex}`} className={dashIndex < skillScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '90%',
                height: '5px',
                backgroundColor: dashIndex < skillScore ? foregroundColor : backgroundColor
              }} />
            ));
          }
        };
    
        const dynamicClassName = `skill-set ${skillStyle !== 'none' ? skillStyle : ''}`;
        
        return (
          <div key={`${skillName}-${index}`} className={dynamicClassName}>
            <p className="skill-name">{skillName}</p>
            {skillStyle !== 'none' && <div className="skill-level-visualization">{renderSkillVisualization()}</div>}
          </div>
        );
      });
    }
    
    return (
      <div onClick={() => onSectionClick && onSectionClick('skills')} className="section skills-section">
        <h2 className="section-title" style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}>{skills.customSectionTitle || skills.sectionTitle}</h2>
        <div className={`section-content skills-content ${skillStyle}-content`}>
          {skillsContent}
        </div>
      </div>
    );
  }; 
  
  const renderSoftSkillsSection = () => {
    const skills = sections.softSkills;
    if (!skills || !skills.isDisplay || !skills.isShow) return null;
  
    const skillStyleSetting = skills.settings.find(setting => setting.inputKey === "softSkillStyle");
    const skillStyle = skillStyleSetting && skillStyleSetting.inputValue ? skillStyleSetting.inputValue : 'circle';
    
    let skillsContent;
    if(skillStyle === 'none') {
      skillsContent = (
        <ul className="skills-list no-bullets">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'softSkill').inputValue;
            if (!skillName) return null; // Skip rendering if there is no skillName
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else if(skillStyle === 'bullets') {
      skillsContent = (
        <ul className="skills-list">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'softSkill').inputValue;
            if (!skillName) return null; // Skip rendering if there is no skillName
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else if(skillStyle === 'badge') {
      skillsContent = (
        <ul className="badge-list">
          {skills.inputGroups.map((group, index) => {
            const skillName = group.inputs.find(input => input.inputKey === 'softSkill').inputValue;
            if (!skillName) return null; 
            return <li key={`${skillName}-${index}`}>{skillName}</li>;
          })}
        </ul>
      );
    } else {
      skillsContent = skills.inputGroups.map((group, index) => {
        const skillName = group.inputs.find(input => input.inputKey === 'softSkill').inputValue;
        if (!skillName) return null; // Skip rendering this group if there is no skillName
        
        const skillLevel = group.inputs.find(input => input.inputKey === 'level').inputValue;
        
        let skillScore = 1; // Default to 1
        if(skillLevel === "Excellent") skillScore = 5;
        else if(skillLevel === "Very good") skillScore = 4;
        else if(skillLevel === "Good") skillScore = 3;
        else if(skillLevel === "Moderate") skillScore = 2;
        else if(skillLevel === "Beginner") skillScore = 1;
    
        const renderSkillVisualization = () => {
          const backgroundColor = '#ffffff6b';
          const foregroundColor = 'white';
          
          if(skillStyle === 'circle' || skillStyle === 'square') {
            return Array.from({ length: 5 }).map((_, circleIndex) => (
              <span key={`${skillName}-${circleIndex}`} className={circleIndex < skillScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '10px',
                height: '10px',
                borderRadius: skillStyle === 'circle' ? '50%' : '0',
                backgroundColor: circleIndex < skillScore ? foregroundColor : backgroundColor,
              }} />
            ));
          } else if(skillStyle === 'bar' || skillStyle === 'bar-rounded') {
            const levelPercentage = skillScore * 20;
            return (
              <div className="progress-bar-container ratingBackColor" style={{ width: '100%', backgroundColor, borderRadius: skillStyle === 'bar-rounded' ? '5px' : '0' }}>
                <div className="progress-bar ratingForeColor" style={{ width: `${levelPercentage}%`, backgroundColor: foregroundColor, height: '6px', borderRadius: skillStyle === 'bar-rounded' ? '5px' : '0' }}></div>
              </div>
            );
          } else if(skillStyle === 'dash-line') {
            return Array.from({ length: 5 }).map((_, dashIndex) => (
              <span key={`${skillName}-${dashIndex}`} className={dashIndex < skillScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '90%',
                height: '5px',
                backgroundColor: dashIndex < skillScore ? foregroundColor : backgroundColor
              }} />
            ));
          }
        };
    
        const dynamicClassName = `skill-set ${skillStyle !== 'none' ? skillStyle : ''}`;
        
        return (
          <div key={`${skillName}-${index}`} className={dynamicClassName}>
            <p className="skill-name">{skillName}</p>
            {skillStyle !== 'none' && <div className="skill-level-visualization">{renderSkillVisualization()}</div>}
          </div>
        );
      });
    }
    
    return (
      <div onClick={() => onSectionClick && onSectionClick('softSkills')} className="section skills-section">
        <h2 className="section-title" style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}>{skills.customSectionTitle || skills.sectionTitle}</h2>
        <div className={`section-content skills-content ${skillStyle}-content`}>
          {skillsContent}
        </div>
      </div>
    );
  };
  
  const renderLanguagesSection = () => {
    const languages = sections.languages;
    if (!languages || !languages.isDisplay || !languages.isShow) return null;
  
    const languagesStyleSetting = languages.settings.find(setting => setting.inputKey === "levelStyle");
    const languagesStyle = languagesStyleSetting && languagesStyleSetting.inputValue ? languagesStyleSetting.inputValue : 'circle';
    
    let languagesContent;
    if(languagesStyle === 'none') {
      languagesContent = (
        <ul className="languages-list no-bullets">
          {languages.inputGroups.map((group, index) => {
            const languagesName = group.inputs.find(input => input.inputKey === 'language').inputValue;
            if (!languagesName) return null; // Skip rendering if there is no languagesName
            return <li key={`${languagesName}-${index}`}>{languagesName}</li>;
          })}
        </ul>
      );
    } else if(languagesStyle === 'bullets') {
      languagesContent = (
        <ul className="languages-list">
          {languages.inputGroups.map((group, index) => {
            const languagesName = group.inputs.find(input => input.inputKey === 'language').inputValue;
            if (!languagesName) return null; // Skip rendering if there is no languagesName
            return <li key={`${languagesName}-${index}`}>{languagesName}</li>;
          })}
        </ul>
      );
    } else if(languagesStyle === 'badge') {
      languagesContent = (
        <ul className="badge-list">
          {languages.inputGroups.map((group, index) => {
            const languagesName = group.inputs.find(input => input.inputKey === 'language').inputValue;
            if (!languagesName) return null; 
            return <li key={`${languagesName}-${index}`}>{languagesName}</li>;
          })}
        </ul>
      );
    } else {
      languagesContent = languages.inputGroups.map((group, index) => {
        const languagesName = group.inputs.find(input => input.inputKey === 'language').inputValue;
        if (!languagesName) return null; // Skip rendering this group if there is no languagesName
        
        const languagesLevel = group.inputs.find(input => input.inputKey === 'level').inputValue;
        
        let languagesScore = 1; // Default to 1
        if(languagesLevel === "Beginner") languagesScore = 1;
        else if(languagesLevel === "Moderate") languagesScore = 2;
        else if(languagesLevel === "Good") languagesScore = 3;
        else if(languagesLevel === "Very good") languagesScore = 4;
        else if(languagesLevel === "Fluent") languagesScore = 5;
    
        const renderLanguagesVisualization = () => {
          const backgroundColor = '#ffffff6b';
          const foregroundColor = 'white';
          
          if(languagesStyle === 'circle' || languagesStyle === 'square') {
            return Array.from({ length: 5 }).map((_, circleIndex) => (
              <span key={`${languagesName}-${circleIndex}`} className={circleIndex < languagesScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '10px',
                height: '10px',
                borderRadius: languagesStyle === 'circle' ? '50%' : '0',
                backgroundColor: circleIndex < languagesScore ? foregroundColor : backgroundColor,
              }} />
            ));
          } else if(languagesStyle === 'bar' || languagesStyle === 'bar-rounded') {
            const levelPercentage = languagesScore * 20;
            return (
              <div className="progress-bar-container ratingBackColor" style={{ width: '100%', backgroundColor, borderRadius: languagesStyle === 'bar-rounded' ? '5px' : '0' }}>
                <div className="progress-bar ratingForeColor" style={{ width: `${levelPercentage}%`, backgroundColor: foregroundColor, height: '6px', borderRadius: languagesStyle === 'bar-rounded' ? '5px' : '0' }}></div>
              </div>
            );
          } else if(languagesStyle === 'dash-line') {
            return Array.from({ length: 5 }).map((_, dashIndex) => (
              <span key={`${languagesName}-${dashIndex}`} className={dashIndex < languagesScore ? 'ratingForeColor' : 'ratingBackColor'} style={{
                display: 'inline-block',
                margin: '0 2px',
                width: '90%',
                height: '5px',
                backgroundColor: dashIndex < languagesScore ? foregroundColor : backgroundColor
              }} />
            ));
          }
        };
    
        const dynamicClassName = `languages-set ${languagesStyle !== 'none' ? languagesStyle : ''}`;
        
        return (
          <div key={`${languagesName}-${index}`} className={dynamicClassName}>
            <p className="languages-name">{languagesName}</p>
            {languagesStyle !== 'none' && <div className="languages-level-visualization">{renderLanguagesVisualization()}</div>}
          </div>
        );
      });
    }
    
    return (
      <div onClick={() => onSectionClick && onSectionClick('languages')} className="section languages-section">
        <h2 className="section-title" style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}>{languages.customSectionTitle || languages.sectionTitle}</h2>
        <div className={`section-content languages-content ${languagesStyle}-content`}>
          {languagesContent}
        </div>
      </div>
    );
  };
  
  const renderProfileLinksSection = () => {
    const profileLinks = sections.profileLinks;
    if (!profileLinks || !profileLinks.isDisplay || !profileLinks.isShow) return null;

    const ensureHttps = (url) => {
        return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    };

    const profileLinksContent = profileLinks.inputGroups.map((group, index) => {
        const profileType = group.inputs.find(input => input.inputKey === 'profileType').inputValue;
        const profileURL = group.inputs.find(input => input.inputKey === 'profileURL').inputValue;

        return (
            <div key={index} className="profile-link-entry">
                <p className="profile-type">{profileType}</p>
                <a href={ensureHttps(profileURL)} target="_blank" rel="noopener noreferrer" className="profile-url">
                    {profileURL}
                </a>
            </div>
        );
    });

    return (
        <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('profileLinks')}} className="section profile-links-section">
            <h2 className="section-title" style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}>{profileLinks.customSectionTitle || profileLinks.sectionTitle}</h2>
            <div className="section-content profile-links-content">
                {profileLinksContent}
            </div>
        </div>
    );
};

  
  const renderReferencesSection = () => {
    const references = sections.references;
    if (!references || !references.isDisplay || !references.isShow) return null;
  
    const referencesContent = references.inputGroups.map((group, index) => {
      const referenceName = group.inputs.find(input => input.inputKey === 'referenceName').inputValue;
      const organization = group.inputs.find(input => input.inputKey === 'organization').inputValue;
      const city = group.inputs.find(input => input.inputKey === 'city').inputValue;
      const phoneNumber = group.inputs.find(input => input.inputKey === 'phoneNumber').inputValue;
      const emailAddress = group.inputs.find(input => input.inputKey === 'emailAddress').inputValue;
  
      return (
        <div key={index} className="reference-entry sub-section">
          <p className="reference-name">{referenceName}</p>
          {organization && <p className="organization">{organization}</p>}
          {city && <p className="city">{city}</p>}
          <p className="phone-number">{phoneNumber}</p>
          <p className="email-address" dangerouslySetInnerHTML={sanitizeHtml(emailAddress)}></p>
        </div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('references')}} className="section references-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{references.customSectionTitle || references.sectionTitle}</h2>
        <div className="section-content references-content">
          {referencesContent}
        </div>
      </div>
    );
  };
  
  const renderAwardsSection = () => {
    const awards = sections.awards;
    if (!awards || !awards.isDisplay || !awards.isShow) return null;
  
    const awardsContent = awards.inputGroups.map((group, index) => {
      const { inputs } = group;
      const awardName = inputs.find(input => input.inputKey === 'awardName').inputValue;
      const issuingOrg = inputs.find(input => input.inputKey === 'issuingOrganization').inputValue;
      const issuedDate = inputs.find(input => input.inputKey === 'issuedDate').inputValue;
      const description = inputs.find(input => input.inputKey === 'description').inputValue;
  
      return (
        <div key={index} className="award-entry sub-section">
          <h3 className="award-name">{awardName}</h3>
          <p className="issuing-organization">{issuingOrg}</p>
          {issuedDate && <p className="issued-date">{formatDate(issuedDate)}</p>}
          {description && <p className="textarea award-description" dangerouslySetInnerHTML={sanitizeHtml(description)}></p>}
        </div>
      );
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('awards')}} className="section awards-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{awards.customSectionTitle || awards.sectionTitle}</h2>
        <div className="section-content awards-content">
          {awardsContent}
        </div>
      </div>
    );
  };
  
  const renderCoursesSection = () => {
    const courses = sections.courses;
    if (!courses || !courses.isDisplay || !courses.isShow) return null;
  
    const coursesContent = courses.inputGroups.map((group, index) => {
        const { inputs } = group;
        const courseName = inputs.find(input => input.inputKey === 'courseName').inputValue;
        const institution = inputs.find(input => input.inputKey === 'institution').inputValue;
        const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
        const description = inputs.find(input => input.inputKey === 'description').inputValue;

        // Sanitize the description before rendering
        const sanitizedDescription = sanitizeHtml(description);

        return (
            <div key={index} className="course-entry sub-section">
                <h3 className="course-name">{courseName}</h3>
                <p className="institution">{institution}</p>
                <p className="end-date">{endDate ? formatDate(endDate) : 'Present'}</p>
                {description && <p className="textarea course-description" dangerouslySetInnerHTML={sanitizedDescription}></p>}
            </div>
        );
    });

    return (
        <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('courses')}} className="section courses-section">
            <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{courses.customSectionTitle || courses.sectionTitle}</h2>
            <div className="section-content courses-content">
                {coursesContent}
            </div>
        </div>
    );
};
const renderInternshipsSection = () => {
  const internships = sections.internships;
  if (!internships || !internships.isDisplay || !internships.isShow) return null;

    // Function to format date range or single date
    // const formatDateRange = (startDate, endDate, isPresent) => { 
    //   if (!startDate) { return }
    //   if (isPresent) {  return `${startDate} - Present`;} else { 
    //     return `${startDate} - ${endDate}`;}
    // };

  const internshipsContent = internships.inputGroups.map((group, index) => {
    const { inputs } = group;
    const position = inputs.find(input => input.inputKey === 'position').inputValue;
    const company = inputs.find(input => input.inputKey === 'company').inputValue;
    const city = inputs.find(input => input.inputKey === 'city').inputValue;
    const startDate = inputs.find(input => input.inputKey === 'startDate').inputValue;
    const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
    const isPresent = inputs.find(input => input.inputKey === 'present').inputValue;
    const description = inputs.find(input => input.inputKey === 'description').inputValue;

    // Apply sanitizeHtml for the description
    const sanitizedDescription = sanitizeHtml(description);

    return (
      <div key={index} className="internship-entry sub-section">
          <div className='sub-header'>
            <div className='sub-section-col'>
            <h3 className="position">{position}</h3>
               <p className="company">{company}</p>
                 {city && <p className="city">{city}</p>}         
            </div>
            <div className='sub-section-col'>
            <p className="date-range">{formatDateRange(startDate, endDate, isPresent)}</p>
            </div>
          </div>     
        <p className="textarea internship-description" dangerouslySetInnerHTML={sanitizedDescription}></p>
      </div>
    );
  });

  return (
    <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('internships')}} className="section internships-section">
      <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{internships.customSectionTitle || internships.sectionTitle}</h2>
      <div className="internships-content">
        {internshipsContent}
      </div>
    </div>
  );
};

const renderCertificationsSection = () => {
  const certifications = sections.certifications;
  if (!certifications || !certifications.isDisplay || !certifications.isShow) return null;

  const certificationsContent = certifications.inputGroups.map((group, index) => {
      const { inputs } = group;
      const certificate = inputs.find(input => input.inputKey === 'certificate').inputValue;
      const institution = inputs.find(input => input.inputKey === 'institution').inputValue;
      const issueDate = inputs.find(input => input.inputKey === 'issueDate').inputValue;
      const certUrlOrId = inputs.find(input => input.inputKey === 'certificationUrl').inputValue;

      // Assuming the certUrlOrId might contain special characters that need HTML encoding
      const sanitizedCertUrlOrId = sanitizeHtml(certUrlOrId);

      return (
          <div key={index} className="certification-entry sub-section">
              <h3 className="certificate-name">{certificate}</h3>
              <p className="institution-name">{institution}</p>
              <p className="issue-date">{issueDate}</p>
              {certUrlOrId && <p className="certification-url-id" dangerouslySetInnerHTML={sanitizedCertUrlOrId}></p>}
          </div>
      );
  });

  return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('certifications')}} className="section certifications-section">
          <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{certifications.customSectionTitle || certifications.sectionTitle}</h2>
          <div className="section-content certifications-content">
              {certificationsContent}
          </div>
      </div>
  );
};

  const renderHobbiesSection = () => {
    const hobbies = sections.hobbies;
    if (!hobbies || !hobbies.isDisplay || !hobbies.isShow) return null;
  
    const hobbiesContent = hobbies.inputGroups.map((group, index) => {
      const hobby = group.inputs.find(input => input.inputKey === 'hobby').inputValue;
      if (!hobby) return null; // Skip rendering empty hobby entries
  
      return <li key={index} className="hobby">{hobby}</li>;
    });
  
    return (
      <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('hobbies')}} className="section hobbies-section">
        <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{hobbies.customSectionTitle || hobbies.sectionTitle}</h2>
        <div className="section-content hobbies-content">
          <ul>
            {hobbiesContent}
          </ul>
        </div>
      </div>
    );
  };
  const renderVolunteerSection = () => {
    const volunteer = sections.volunteer;
    if (!volunteer || !volunteer.isDisplay || !volunteer.isShow) return null;

    // Function to format date range or single date
    // const formatDateRange = (startDate, endDate, isPresent) => { 
    //   if (!startDate) { return }
    //   if (isPresent) {  return `${startDate} - Present`;} else { 
    //     return `${startDate} - ${endDate}`;}
    // };  

    const volunteerContent = volunteer.inputGroups.map((group, index) => {
        const { inputs } = group;
        const role = inputs.find(input => input.inputKey === 'role').inputValue;
        const organization = inputs.find(input => input.inputKey === 'organization').inputValue;
        const startDate = inputs.find(input => input.inputKey === 'startDate').inputValue;
        const endDate = inputs.find(input => input.inputKey === 'endDate').inputValue;
        const isPresent = inputs.some(input => input.inputKey === 'present' && input.inputValue);
        const description = inputs.find(input => input.inputKey === 'description').inputValue;

        // Apply sanitizeHtml for the description
        const sanitizedDescription = sanitizeHtml(description);

        return (
            <div key={index} className="volunteer-entry sub-section">
                <div className='sub-header'>
              <div className='sub-section-col'>
              <h3 className="role">{role}</h3>
                  <p className="organization">{organization}</p>       
              </div>
              <div className='sub-section-col'>
              <p className="date-range">{formatDateRange(startDate, endDate, isPresent)}</p>
              </div>
            </div> 
              {description && <p className="textarea volunteer-description" dangerouslySetInnerHTML={sanitizedDescription}></p>}
              </div>
        );
    });

    return (
        <div onClick={() => {handleCloseTemplate(); onSectionClick && onSectionClick('volunteer')}} className="section volunteer-section">
            <h2 style={{ fontSize: `${textTransform === 'uppercase' ? h2 - 2 : h2}px`, textTransform: textTransform }}  className="section-title">{volunteer.customSectionTitle || volunteer.sectionTitle}</h2>
            <div className="section-content volunteer-content">
                {volunteerContent}
            </div>
        </div>
    );
};

  /*Resume Layout*/
  const renderSingleColumn = () => (
    <div className="single-column">  
       {/* { (selectedStyle === 'Executive' || selectedStyle === 'Minimalist') && renderHeaderSection() } */}
       {renderHeaderSection()}
       <div className='wrap'>       
            {sectionOrder.singleColumn.filter(key => key !== 'personalDetails' && key !== 'contactDetails').map(renderSection)}
       </div>
    </div>
  );

  const renderTwoColumns = () => (   
    <div className="column-wrap">
      <div className='columns'>      
      <div className="left-column">
        {renderPhotoSection()}
        {selectedStyle === 'Influential' && renderPhotoSection()} 
        {!(selectedStyle === 'Modern' || selectedStyle === 'Vibrant' || selectedStyle === 'Inspired') && renderPersonalDetailsSection()}
        {sectionOrder.twoColumns.leftColumn.filter(key => key !== 'personalDetails').map(renderSection)}
      </div>
      <div className="right-column">        
        {(selectedStyle === 'Modern' || selectedStyle === 'Vibrant' || selectedStyle === 'Inspired') && renderHeaderOnlyTitleSection()}
        {sectionOrder.twoColumns.rightColumn.filter(key => key !== 'personalDetails').map(renderSection)}
      </div>
      </div>
      </div>
  );
  
  const renderHeaderSection = () => (
    <div className="header-section">
      <div className='photo-section'>
      {renderPhotoSection()}
      </div>
      <div className='details-section'>        
      {renderPersonalDetailsSection()}
      {renderContactDetailsSection()}
      </div>
    </div>
  );
  const renderHeaderOnlyTitleSection = () => (
    <div className={`header-section ${isPhotoThere && photoVisible ? 'photo-exists' : ''}`}> 
      <div className='details-section'>        
      {renderPersonalDetailsSection()} 
      </div> 
      {(selectedStyle === 'Visionary' || selectedStyle === 'Versatile' || selectedStyle === 'Classic') && renderPhotoSection()}
    </div>
  );

  const renderThreeColumns = () => (
    <>      
       {selectedStyle === 'Visionary' || selectedStyle === 'Versatile' || selectedStyle === 'Classic' ? renderHeaderOnlyTitleSection() : renderHeaderSection()}
      <div className='column-wrap'>
      <div className="columns">
        <div className="left-column">
        {(selectedStyle === 'Visionary' || selectedStyle === 'Versatile' || selectedStyle === 'Classic') && renderContactDetailsSection()}
          {sectionOrder.twoColumns.leftColumn
            .filter(key => key !== 'personalDetails' && key !== 'contactDetails')
            .map(renderSection)}
        </div>
        <div className="right-column"> 
          {sectionOrder.twoColumns.rightColumn
            .filter(key => key !== 'personalDetails' && key !== 'contactDetails')
            .map(renderSection)}
        </div>
      </div>
      </div>
    </>
  );
  const renderFourColumns = () => (
    <>   
     <div className='column-wrap'>
      <div className="columns">
        <div className="left-column">
        {/* {renderPhotoSection()} */}
        {selectedStyle !== 'Impactful' && renderPhotoSection()} 
          {sectionOrder.twoColumns.leftColumn
            .filter(key => key !== 'personalDetails' )
            .map(renderSection)}
        </div>
        <div className="right-column">
          {selectedStyle !== 'Impactful' && renderHeaderOnlyTitleSection()}
          {selectedStyle === 'Modern' && renderHeaderOnlyTitleSection()}
          {selectedStyle === 'Impactful' && renderHeaderSection()}
        {/* {renderHeaderSection()} */}
          {sectionOrder.twoColumns.rightColumn
            .filter(key => key !== 'personalDetails' && key !== 'contactDetails')
            .map(renderSection)}
        </div>
      </div>
      </div>
    </>
  );
  const threeColumnStyles = [
    'Professional', 
    'Explorer',
    'Classic',
    'Bold',
    'Creative',
    'Visionary',
    'Innovative',
    'Versatile'
  ];
  const fourColumnStyles = [
    'Impactful',
    'Elegant',
    'Influential',
    'Ambitious'
  ];
  const singleColumnStyles = [
    'Executive',
    'Expertise',
    'Minimalist',
    'Aspirations',
    'Empowering',
    'Proactive',
    'Dynamic'
  ];
  const fiveColumnStyles = [
    'Executive'
  ];
  const determineLayout = () => {
    if (threeColumnStyles.includes(selectedStyle)) {
      return renderThreeColumns();
    } else if (singleColumnStyles.includes(selectedStyle)) {
      setIsSingleLayout(true);
      return renderSingleColumn();
    } else if (fourColumnStyles.includes(selectedStyle)) { // Fixed typo here
      return renderFourColumns();
    } else {
      return renderTwoColumns();
    }
  };
  
  const getDynamicSectionStyles = () => {
    let spacingValue = '30px'; 
    switch(sectionSpacing) {
      case 'veryClose':
        spacingValue = '5px';
        break;
      case 'close':
        spacingValue = '15px';
        break;
      case 'normal':
        spacingValue = '25px';
        break;
      case 'wide':
        spacingValue = '35px';
        break;
      case 'veryWide':
        spacingValue = '45px';
        break;
      default:
        spacingValue = '25px';
    }
  
    return {
      '--section-margin': spacingValue,
    };
  };
 
  const FONT_SIZES = {
    S: 12,
    M: 13,
    L: 14,
    XL: 15,
    XXL: 16,
    XXXL: 17
  };
  const getFontSizes = (size) => {
    const baseSize = FONT_SIZES[size];
    return {
      body: baseSize,
      h1: baseSize * 2.3, // 2 times the base size
      h2: baseSize * 1.3, // 1.5 times the base size
    };
  };
  
  const { body, h1, h2 } = getFontSizes(fontSize);

  function getStyleBySelectedStyle(selectedStyle, selectedColor, body, lineHeight) {
    const baseStyle = {
      fontSize: `${body}px`, 
      lineHeight: lineHeight,
    };
  
    const styleVariations = {
      'Professional': {
        '--header-bg-color': selectedColor,
        '--rating-fore-color': selectedColor,
      },
      'Executive': {
        '--header-h1-color': selectedColor,
        '--section-title-color': selectedColor,
        '--rating-fore-color': selectedColor,
      },
      'Modern': {
        '--modern-resume-after-bg': selectedColor,
        '--modern-section-title-color': selectedColor,
        '--modern-title-color': selectedColor,
        '--modern-color': selectedColor
      },
      'Creative': {
        '--header-h1-color': selectedColor,
        '--section-title-color': selectedColor,
        '--rating-fore-color': selectedColor,
      },
      'Impactful': {
        '--impactful-resume-after-bg': selectedColor,
        '--impactful-color': selectedColor
      },
      'Dynamic': {
        '--header-h1-color': selectedColor,
        '--section-title-color': selectedColor,
        '--rating-fore-color': selectedColor,
      },
      'Explorer' : {
        '--explorer-resume-header-bg': selectedColor,
      },
      'Classic': {
        '--header-h1-color': selectedColor,
        '--section-title-color': selectedColor
      },
      'Elegant': {
        '--header-h1-color': selectedColor,
        '--section-title-color': selectedColor
      },
      'Bold':{
        '--bold-resume-header-bg': selectedColor,
        '--bold-rating-fore-color': selectedColor
        },
        'Expertise':{
          '--expertise-resume-header-bg': selectedColor,
        },
        'Aspirations':{
          '--aspirations-resume-header-bg': selectedColor,
        },
      'Minimalist':{
        '--minimalist-header-txt-color': selectedColor,
      },
      'Influential':{
        '--influential-resume-after-bg': selectedColor,
        '--influential-header-boder': selectedColor,
        '--influential-color': selectedColor
      },
      'Inspired':{
        '--inspired-resume-after-bg': selectedColor,
        '--inspired-header-boder': selectedColor,
        '--inspired-color':selectedColor,
      },
      'Versatile': {
        '--versatile-resume-header-bg': selectedColor,
        '--versatile-header-boder': selectedColor,
        '--versatile-rating-fore-color': selectedColor,
      },
      'Vibrant': {
        '--vibrant-resume-after-bg': selectedColor,
        '--vibrant-title-color': selectedColor,
        '--vibrant-border-color': selectedColor,
        '--vibrant-color': selectedColor
      },  
      'Empowering': {
        '--empowering-border-color': selectedColor,
        '--empowering-title-color': selectedColor,
        '--empowering-border-bottom-color': selectedColor,
      },    
      'Innovative': {
        '--innovative-title-color': selectedColor,
        '--innovative-contact-fill': selectedColor,
        '--innovative-rating-bg-color': selectedColor,
      },   
      'Ambitious': {
        '--ambitious-title-color': selectedColor,
        '--ambitious-boder-color': selectedColor
      },     
      'Visionary': {
        '--visionary-color': selectedColor
      },  
      'Proactive': {
        '--proactive-color': selectedColor
      }, 


      // Add more styles for other selected styles as needed
    };
  
    return {
      ...baseStyle,
      ...(styleVariations[selectedStyle] || {}),
    };
  }
  
  const fetchWithRetry = async (url, options, retries = 3) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`Attempt ${attempt} failed: ${response.statusText}`);
        }
        return response;
      } catch (error) {
        console.error(error);
        if (attempt === retries) {
          throw new Error(`Failed after ${retries} attempts`);
        }
      }
    }
  };

  
  const handleDownloadPdf = async () => {
    setModalOpen(true);
    setIsDownloading(true);
    setDownloadError(false);
  
    const html = document.getElementById('resume').outerHTML;
    
    // Mapping of font options to Google Fonts URLs
    const fontLinkMapping = {
      'roboto-regular': '<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">',
      'montserrat-regular': '<link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">',
      'lora-regular': '<link href="https://fonts.googleapis.com/css2?family=Lora:ital@0;1&display=swap" rel="stylesheet">',
      'raleway-regular': '<link href="https://fonts.googleapis.com/css2?family=Raleway:ital@0;1&display=swap" rel="stylesheet">',
      'rubik-regular': '<link href="https://fonts.googleapis.com/css2?family=Rubik&display=swap" rel="stylesheet">',
      'inconsolata-regular': '<link href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap" rel="stylesheet">',
      'signika-regular': '<link href="https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap" rel="stylesheet">',
      'poppins-regular': '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet">',
      'ubuntu-regular': '<link href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap" rel="stylesheet">',
      'prompt-regular': '<link href="https://fonts.googleapis.com/css2?family=Prompt&display=swap" rel="stylesheet">',
      'roboto-mono': '<link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet">',
      'playwrite-de-grund': '<link href="https://fonts.googleapis.com/css2?family=Playwrite+DE+Grund&display=swap" rel="stylesheet">',
      'abeezee-regular': '<link href="https://fonts.googleapis.com/css2?family=ABeeZee&display=swap" rel="stylesheet">',
      'lexend-regular': '<link href="https://fonts.googleapis.com/css2?family=Lexend&display=swap" rel="stylesheet">',
      'pt-serif-regular': '<link href="https://fonts.googleapis.com/css2?family=PT+Serif&display=swap" rel="stylesheet">'
    };
  
    const selectedFontLink = fontLinkMapping[selectedFont] || '';
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Resume</title> 
        ${selectedFontLink}
        <style>${cssString}</style>
      </head>
      <body>
          ${html}
      </body>
    </html>
  `;

  const stylesRightSidebar = ['influential', 'impactful'];
  const floatTy = stylesRightSidebar.includes(selectedStyle.toLowerCase()) ? "right" : "left";
  let sidebarColor = selectedColor;
  const stylesRequiringTransparentSidebar = [
    'professional', 'executive', 'visionary', 'creative', 'dynamic', 'classic',
    'elegant', 'expertise', 'aspirations', 'minimalist', 'versatile', 'proactive',
    'empowering', 'ambitious', 'innovative'
  ];

  if (stylesRequiringTransparentSidebar.includes(selectedStyle.toLowerCase())) {
    sidebarColor = 'transparent';
  } else if (selectedStyle.toLowerCase() === 'explorer') {
    sidebarColor = '#f1f1f1';
  } else if (selectedStyle.toLowerCase() === 'bold') {
    sidebarColor = 'rgb(38 38 45)';
  }
  const local = 'http://localhost:3002/generate-pdf';
  const production = 'https://rd-server-418cf5202d66.herokuapp.com/generate-pdf';
  const pdfApi = production;
  const pdfPayLoad = {
    cvHtml: htmlContent,
    cvStyle: selectedStyle,
    cvColor: sidebarColor,
    docType: 'resume',
    floatType: floatTy,
    pageFormat: pageFormat
  };

  try {
    const response = await fetchWithRetry(pdfApi, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pdfPayLoad),
      mode: 'cors'
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'resume.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      setIsDownloading(false); 
      setTimeout(() => {
        setModalOpen(false);
      }, 3000); 
    } else {
      throw new Error('Response was not ok.');
    }
  } catch (error) {
    console.error('Error generating PDF:', error); 
    setDownloadError(true);  
    setIsDownloading(false);
  }
};
  

// Handler Save
const handleFontSizeChange = async (newSize) => {
  setFontSize(newSize);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      fontSize: newSize,
    },
  };

  try {
    await saveResume(updatedResumeData);
    updateResumeData(updatedResumeData);
    console.log('Font size updated successfully');
  } catch (error) {
    console.error('Failed to update font size:', error);
  }
};



const handleStyleChange = async (newStyle) => {
  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      selectedStyle: newStyle,
      selectedColor: defaultColorsByStyle[newStyle]
    }
  };

  setSelectedStyle(newStyle);
  setPresentStyle(newStyle);
  setIsSingleLayout(false);
  setSelectedColor(defaultColorsByStyle[newStyle]);

  try {
    await saveMetadata(resumeID, { selectedStyle: newStyle, selectedColor: defaultColorsByStyle[newStyle] });
    console.log('Template style updated successfully');
    updateResumeData(updatedResumeData); // Update the parent component's state
  } catch (error) {
    console.error('Failed to update template style:', error);
  }
};


const handleFontChange = async (newFont) => {
  setSelectedFont(newFont);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      selectedFont: newFont,
    },
  };

  try {
    await saveResume(updatedResumeData);
    console.log('Font updated successfully');
    updateResumeData(updatedResumeData);
  } catch (error) {
    console.error('Failed to update font:', error);
  }
};

const handleColorChange = async (e) => {
  const newColor = e.target.value;
  setSelectedColor(newColor);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      selectedColor: newColor,
    },
  };

  await updateResume(updatedResumeData);
};
const handleTextTransformChange = async (newTextTransform) => {
  setTextTransform(newTextTransform);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      textTransform: newTextTransform,
    },
  };

  try {
    await saveResume(updatedResumeData);
    updateResumeData(updatedResumeData);
    console.log('Text transform updated successfully');
  } catch (error) {
    console.error('Failed to update text transform:', error);
  }
};

const handleLineHeightChange = async (newLineHeight) => {
  setLineHeight(newLineHeight);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      lineHeight: newLineHeight,
    },
  };

  try {
    await saveResume(updatedResumeData);
    updateResumeData(updatedResumeData);
    console.log('Line height updated successfully');
  } catch (error) {
    console.error('Failed to update line height:', error);
  }
};

const handleSectionSpacingChange = async (newSectionSpacing) => {
  setSectionSpacing(newSectionSpacing);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      sectionSpacing: newSectionSpacing,
    },
  };

  try {
    await saveResume(updatedResumeData);
    updateResumeData(updatedResumeData);
    console.log('Section spacing updated successfully');
  } catch (error) {
    console.error('Failed to update section spacing:', error);
  }
};

const handleColorChangeOnBlur = async (e) => {
  const newColor = e.target.value;
  setSelectedColor(newColor);

  const updatedResumeData = {
    ...resumeData,
    metadata: {
      ...resumeData.metadata,
      selectedColor: newColor,
    },
  };

  try {
    await saveResume(updatedResumeData);
    updateResumeData(updatedResumeData);
    console.log('Color updated successfully');
  } catch (error) {
    console.error('Failed to update color:', error);
  }
};

const handleColorChangeOnInput = (e) => {
  setSelectedColor(e.target.value);
};
const updateResume = async (updatedResumeData) => {
  try {
    await saveResume(updatedResumeData);
    console.log('Resume updated successfully');
  } catch (error) {
    console.error('Failed to update resume:', error);
  }
};

const templates = [
  'Professional', 'Executive', 'Modern', 'Visionary', 'Creative', 'Impactful',
  'Dynamic', 'Explorer', 'Classic', 'Elegant', 'Bold', 'Expertise', 'Aspirations',
  'Minimalist', 'Influential', 'Inspired', 'Versatile', 'Vibrant', 'Proactive',
  'Empowering', 'Ambitious', 'Innovative'
];

const renderTemplateCollection = () => (
  <div className="template-collection p-5 flex flex-wrap justify-center">
    {templates.map((template, index) => (
      <div
        key={index}
        className={`template-item m-2 cursor-pointer ${selectedStyle === template ? 'active-template' : ''}`}
        onClick={() => handleStyleChange(template)}
        style={{
          maxWidth: '250px',
          height: 'auto',
          margin: '0',
          display: 'inline-block',
          textAlign: 'center',
          cursor: 'pointer'
        }}
      >
        
        <div className='temp-thumbnail relative' style={{"width":"250px", "height":"331px"}}><div className='selectedTemp'><TbCircleCheck /></div>
        <img
                                src={images[`${template}.png`] || images[`${template}.jpg`]}
                                alt={template}
                                width="312"
                                className="w-full"
                            />
                            </div>
        <div className='temp-footer mt-2'> <strong>{template}</strong> </div>
      </div>
    ))}
  </div>
);

const [isActive, setIsActive] = useState(false);

const handleSelectTemplate = () => {
  setIsActive(!isActive);
  setShowTemplateCollection(!isActive);
  setShowTemplates(!isActive);
  onToggleTemplates(true); 
};
const handleCloseTemplate = () => {
  if(isActive){
    setIsActive(false);
    setShowTemplateCollection(false);
    setShowTemplates(false);
    onToggleTemplates(false); 
  }
};
return (<>
  {showTemplates && 
    <div className="flex flex-row w-full md:w-[40%] h-screen select-resume-template">        
    <div className="flex flex-col w-full h-screen border-l-10 border-cyan-400">
          {/* <div className="head-sec flex justify-between p-5 py-2"> 
          <h3 className='text-md font-bold text-white'>Select Template</h3>
          <button onClick={() => {setShowTemplateCollection(false); setShowTemplates(false) }} 
          className="text-md text-white-900 rounded italic py-1 my-1 mr-1 ml-4 px-2 hover:text-white-600 focus:outline-none focus:border-gray-500">
              <TbX />
          </button>
          </div> */}
          <div className="flex-1 bg-white overflow-auto custom-scrollbar">         
       {renderTemplateCollection()}
          </div>
    </div>

    <div className="md:hidden sticky-footer flex justify-center">
    <button
            onClick={handleCloseTemplate}
            className="flex flex-col items-center fo-btn text-white text-md py-1.5 px-4 rounded"
          >
            <BiArrowBack className="text-xl" />
            <span className="text-xs">Back to Preview</span>
          </button>
    </div>
    </div>
    
  }
<div className={`json-preview w-full md:w-[60%] bg-gray-100 h-lvh overflow-hide ${!showResume ? 'edit-mode' : 'preview-mode'} ${showTemplates ? 'templateShowing': ''}`}>
  {!showPreviewOnly && (
<div className="flex flex-col h-screen">

<div className="flex items-center justify-between" style={{backgroundColor:"#656e83"}}> 

<div className='resume-customize w-full flex items-center p-4'>
 {/* Change Templates Button */}
 <button
        onClick={handleSelectTemplate}
        className={`custom-dropdown-header inline-flex items-center px-4 py-0 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none ${isActive ? 'active' : ''}`}
      >
        Select Template
      </button>
 <CustomDropdown
        options={fontOptions}
        value={selectedFont}        
        label={'Font'}
        onChange={handleFontChange}
        className="font-dropdown"
        icon={FaFont}
      />
 <CustomDropdown
        options={fontSizeOptions}
        value={fontSize}
        label={'Font Size'}
        onChange={handleFontSizeChange}
        icon={TbTextSize} // Replace with your desired icon for font size
      />
<CustomDropdown
        options={lineHeightOptions}
        value={lineHeight}
        label={'Line Height'}
        onChange={handleLineHeightChange}
        icon={TbLineHeight} // Pass the line-height icon here if available
      />
<CustomDropdown
        options={textTransformOptions}
        value={textTransform}
        label={'Text Transform'}
        onChange={handleTextTransformChange} 
        icon={TbLetterCaseToggle }
      />     
<CustomDropdown
        options={sectionSpacingOptions}
        value={sectionSpacing}
        label={'Section Gap'}
        onChange={handleSectionSpacingChange}
        icon={TbSection} // Use an icon that represents spacing
      />     
<CustomDropdown
        options={textAlignOptions}
        value={textAlignment}
        label={'Text Alignment'}
        onChange={handleTextAlignmentChange}
        className="text-align-dropdown"
        icon={TbAlignJustified} // You can choose an appropriate icon
      />   
<CustomDropdown
  options={pageFormatOptions}
  value={pageFormat}
  label={'Page Format'}
  onChange={handlePageFormatChange}
  className="text-align-dropdown"
  icon={TbFile} 
      />      
<input 
  type="color"   
  value={selectedColor} 
  className='select-color'
  onInput={handleColorChangeOnInput}
  onBlur={handleColorChangeOnBlur} 
/>
 <button className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={(e) => {
  e.preventDefault(); handleDownloadPdf();}}>
 <TbDownload className='mr-2' /> Download
  </button>

{isEditor && <>
  {isPublished ? (
  <button
    className="hidden inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    onClick={handleUpdatePublish}
  >
    Update Publish
  </button>
) : (
  <button
    className="hidden inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    onClick={handlePublish}
  >
    Publish
  </button>
)}
   {/* <button
   className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
   onClick={handleCopyID}
 >
   Copy ID
 </button> */}

  <button
  className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  onClick={handleImageDownload}
>
  <TbDownload className='mr-2' /> Image
</button>

{publishedResumeId && (
  <div className="published-resume-id flex items-center">
    <p className="mr-2 text-white text-sm"><strong className='text-gray-300'>Published Resume ID:</strong> <i className='pl-4 pr-2'>{publishedResumeId}</i></p>
    <CopyToClipboard text={publishedResumeId} onCopy={handleCopy}>
      <button className="text-blue-400 hover:text-green-400 focus:outline-none">
        <FaCopy />
      </button>
    </CopyToClipboard>
    {copySuccess && <span className="ml-2 text-green-300 text-sm">{copySuccess}</span>}
  </div>
)}

</>}




 </div>
 <Link to="/documents">
            <button className="text-md text-gray-100 rounded italic py-1 my-1 mr-4 ml-4 px-2 hover:text-gray-400 focus:outline-none focus:border-gray-500">
                <TbX />
            </button>
        </Link>
</div>



<div className="flex-1 overflow-auto custom-scrollbar p-5" style={{backgroundColor:"#656e83"}}>

  <div className='resume-preview'>
    <div
        id="resume"
        className={`resume-container content-${textAlignment} ${selectedStyle.toLowerCase()} ${selectedFont.replace(/\s+/g, '-').toLowerCase()}`}
        style={{ ...getStyleBySelectedStyle(selectedStyle, selectedColor, body, lineHeight), ...getDynamicSectionStyles(), transform: 'scale(1)', transformOrigin: 'center top' }}
      >
          {determineLayout()}
    </div>
 </div> 
</div>
<ModalComponent isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            <div className='download-modal'>
            {isDownloading ? (
                <>
                  <div className='mid-wrap'>
                  <div className="loader"></div>                 
                  </div>
                  <h2 className='mb-3'>Downloading your resume...</h2> 
                  <p>Please wait while we're preparing your PDF!</p>
                </>
              ) : downloadError ? (
                <>
                  <h2 className='mb-3'>Sorry, something went wrong.</h2>
                  <p>Please try again later or contact support for help.</p>
                </>
              ) : (
                <>
                  <div className='mid-wrap'>
                  <div> <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>
</div>
                  </div>
                  <h2 className='mb-3'>Download Successful!</h2>
                  <p>Good luck with your job search!</p>
                </>
              )}
            </div>
</ModalComponent>


</div>  
 )} 
 {showPreviewOnly && (
 <div
        id="resume"
        className={`resume-container content-${textAlignment} ${selectedStyle.toLowerCase()} ${selectedFont.replace(/\s+/g, '-').toLowerCase()}`}
        style={{ ...getStyleBySelectedStyle(selectedStyle, selectedColor, body, lineHeight), ...getDynamicSectionStyles(), transform: 'scale(0.30)', transformOrigin: 'left top' }}
      >
          {determineLayout()}
    </div>
     )} 
</div>
</>);
}

export default ResumePreview;
