import React, { useState, useCallback, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import { fetchSavedJobs, saveJob, deleteSavedJob } from '../services/resumeService';
import { BiBriefcase, BiMap, BiSearchAlt, BiCalendar, BiBriefcaseAlt2, BiAlarm, BiRocket } from 'react-icons/bi';
import { GoHeartFill, GoHeart } from 'react-icons/go';
import { BsStars } from 'react-icons/bs';
import { useAuth } from '../context/AuthContext'; 
import Tooltip from 'react-tooltip-lite';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { useSearchParams } from 'react-router-dom';  // Import useSearchParams to handle query params
import { useNavigate } from 'react-router-dom';

const Jobs = () => {
  const { user } = useAuth();
  const [userId, setUserId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({ query: '', location: '', datePosted: '' });
  const [activeTab, setActiveTab] = useState('search'); // 'search' or 'saved'
  const [pageIndex, setPageIndex] = useState(0);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [triggerSearchFromURL, setTriggerSearchFromURL] = useState(false); 
  
  const [searchParams, setSearchParams] = useSearchParams(); // To handle query params

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();


  const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen); 
  };
  const handleLinkClick = () => {
      setDropdownOpen(false); 
  };
  const handleApplyClick = () => {
      console.log('Apply Now clicked');
  };

  // Function to convert "X days ago" to a numeric value for sorting
  const convertDaysAgoToDate = (daysAgo) => {
    const match = daysAgo.match(/(\d+)\s+days? ago/);
    return match ? parseInt(match[1], 10) : Infinity;
  };

  useEffect(() => {
    if (user) {
      setUserId(user.uid);
      fetchSavedJobsForUser();
    }
  }, [user]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (e) => {
      setSearchInitiated(e.matches);
    };

    handleMediaQueryChange(mediaQuery); // Set the initial state based on the current viewport size
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    const query = searchParams.get('query');
    const location = searchParams.get('location');
    const datePosted = searchParams.get('datePosted');

    // If query params are present, pre-fill the form values and trigger search from URL
    if (query || location || datePosted) {
      setFormValues({
        query: query || '',
        location: location || '',
        datePosted: datePosted || '',
      });
      setTriggerSearchFromURL(true);  // Set this to trigger search only from URL access
    }
  }, [searchParams]);
  
const [refresh, setRefresh] = useState(false);

// Use state to track when saved jobs are fully loaded
const [savedJobsLoaded, setSavedJobsLoaded] = useState(false);

// Modify fetchSavedJobsForUser to set the state when the jobs are loaded
const fetchSavedJobsForUser = useCallback(async () => {
  if (!user) return;

  setIsLoading(true);
  setErrorMessage(''); // Clear previous error message

  try {
    console.log("Fetching saved jobs for user:", user.uid);
    const jobs = await fetchSavedJobs(user.uid);
    setSavedJobs(jobs);
    setSavedJobsLoaded(true);  // Mark that saved jobs have been loaded
  } catch (error) {
    console.error('Error fetching saved jobs:', error);
    setErrorMessage('Failed to fetch saved jobs. Please try again later.');
  } finally {
    setIsLoading(false);
  }
}, [user]);

useEffect(() => {
  if (user) {
    setUserId(user.uid);  
    fetchSavedJobsForUser();
  }
}, [user]);

useEffect(() => {
  if (user && triggerSearchFromURL && savedJobsLoaded) {
    console.log("Triggering search from URL with user:", user.uid);
    handleSearchSubmit(); 
    setTriggerSearchFromURL(false);  
  }
}, [user, triggerSearchFromURL, savedJobsLoaded]);

// Updated fetchJobs to rely on savedJobs being available
const fetchJobs = useCallback(async (index) => {
  if (!user || !savedJobsLoaded) {
    console.log('User or Saved Jobs not available yet');
    return;
  }

  setIsLoading(true);
  setErrorMessage('');

  try {
    console.log('User:', user);
    console.log('UserId:', userId);

    // Proceed with fetching jobs from the search API
    const url = new URL('https://rd-server-418cf5202d66.herokuapp.com/search-jobs');
    url.searchParams.append('query', formValues.query);
    url.searchParams.append('location', formValues.location);
    url.searchParams.append('index', index);

    if (formValues.datePosted) {
      url.searchParams.append('datePosted', formValues.datePosted);
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch jobs');
    }

    const data = await response.json();
    const sortedJobs = data.jobs.sort((a, b) => {
      const daysA = convertDaysAgoToDate(a.datePosted);
      const daysB = convertDaysAgoToDate(b.datePosted);
      return daysA - daysB;
    });

    const updatedJobs = sortedJobs.map((job) => {
      const isJobSaved = savedJobs.some((savedJob) => savedJob.id === job.id);
      console.log(`Job title: ${job.title}, isSaved: ${isJobSaved}`);

      return { ...job, isSaved: isJobSaved, status: isJobSaved ? 'saved' : job.status };
    });

    setJobs((prevJobs) => {
      const combinedJobs = [...prevJobs, ...updatedJobs];
      const uniqueJobs = Array.from(new Set(combinedJobs.map(job => job.id)))
        .map(id => combinedJobs.find(job => job.id === id));
      return uniqueJobs;
    });

    if (updatedJobs.length > 0 && index === 0) {
      setSelectedJob(updatedJobs[0]);
    }

    // Force a re-render to reflect the status updates
    setRefresh(!refresh);

  } catch (error) {
    console.error('Error fetching jobs:', error);
    setErrorMessage('Failed to fetch jobs. Please try again later.');
  } finally {
    setIsLoading(false);
  }
}, [user, savedJobsLoaded, savedJobs, formValues, refresh]);



  useEffect(() => {
    if (pageIndex > 0) {
      fetchJobs(pageIndex);
    }
  }, [pageIndex, fetchJobs]);

  const loadMoreJobs = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  };

  const handleInputChange = (e) => {
    setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearchSubmit = async (e) => {
    console.log(formValues)
    if (e) e.preventDefault(); 
    setActiveTab('search');
    setPageIndex(0);
    setJobs([]);
    setSearchInitiated(true);
    await fetchSavedJobsForUser();
    fetchJobs(0);
  };

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  const switchTab = async (tabName) => {
    setActiveTab(tabName);
    setSearchInitiated(true);
    if (tabName === 'saved') {
      await fetchSavedJobsForUser();
      if (savedJobs.length > 0) {
        setSelectedJob(savedJobs[0]);
      }
    } else {
      if (jobs.length > 0) {
        setSelectedJob(jobs[0]);
      }
    }
  };

  const removeJobFromState = (jobId) => {
    setSavedJobs((prevJobs) => {
      const updatedJobs = prevJobs.filter(job => job.id !== jobId);
      if (updatedJobs.length > 0) {
        setSelectedJob(updatedJobs[0]);
      } else {
        setSelectedJob(null);
      }
      return updatedJobs;
    });
  };

  // const fetchSavedJobsForUser = async () => {
  //   setIsLoading(true);
  //   setErrorMessage(''); // Clear previous error message
  //   if(user){
  //     try {
  //       const jobs = await fetchSavedJobs(user.uid);
  //       setSavedJobs(jobs);
  //       if (activeTab === 'saved' && jobs.length > 0) {
  //         setSelectedJob(jobs[0]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching saved jobs:', error);
  //       setErrorMessage('Failed to fetch saved jobs. Please try again later.');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const onSaveStatusChange = (jobId, isSaved, updatedJob = null) => {
    setJobs((prevJobs) => {
      return prevJobs.map((job) =>
        job.id === jobId ? { ...job, isSaved, ...(updatedJob ? updatedJob : {}) } : job
      );
    });

    setSavedJobs((prevSavedJobs) => {
      if (isSaved) {
        if (updatedJob) {
          return [...prevSavedJobs, updatedJob];
        } else {
          const jobToSave = jobs.find(job => job.id === jobId);
          if (jobToSave) {
            return [...prevSavedJobs, { ...jobToSave, isSaved: true }];
          }
        }
      } else {
        return prevSavedJobs.filter(savedJob => savedJob.id !== jobId);
      }
      return prevSavedJobs;
    });

    setSelectedJob((prevSelectedJob) => {
      if (prevSelectedJob && prevSelectedJob.id === jobId) {
        return { ...prevSelectedJob, isSaved, ...(updatedJob ? updatedJob : {}) };
      }
      return prevSelectedJob;
    });
  };
  const [savingJobId, setSavingJobId] = useState(null);  // Add this state to track the job being saved

  const handleSaveJob = async (job) => {
    if (job.isSaved || isLoading) {
      return; // Prevent saving if already saved or loading
    }
  
    setSavingJobId(job.id);  // Set the job id that is being saved to show spinner
  
    try {
      const updatedJob = await saveJob(userId, job);
      if (updatedJob && updatedJob.docId) {
        onSaveStatusChange(updatedJob.id, true, updatedJob);
        toast.success('Job saved successfully!');
      } else {
        throw new Error('Failed to save job.');
      }
    } catch (error) {
      console.error('Failed to save job:', error);
      toast.error('Failed to save job.');
    } finally {
      setSavingJobId(null);  // Remove the saving job id once the process is done
    }
  };
  

  const handleDeleteJob = async (job) => {
    console.log(job)
    try {
      await deleteSavedJob(userId, job.docId);
      removeJobFromState(job.id);
      toast.success('Job unsaved successfully!');

      // Update job's isSaved status in the jobs array to reflect the change
      setJobs((prevJobs) => 
        prevJobs.map((j) => (j.id === job.id ? { ...j, isSaved: false, status: "none" } : j))
      );

      // Update selected job's isSaved status if it is the one being unsaved
      setSelectedJob((prevSelectedJob) =>
        prevSelectedJob && prevSelectedJob.id === job.id
          ? { ...prevSelectedJob, isSaved: false }
          : prevSelectedJob
      );

    } catch (error) {
      console.error('Failed to delete job:', error);
      toast.error('Failed to unsave job.');
    }
  };

  const handleOptimizeClick = (job) => {
    const jobDescriptionEncoded = encodeURIComponent(job.description);
    const jobTitleEncoded = encodeURIComponent(job.title);
    const url = `/optimize-resume?jobDescription=${jobDescriptionEncoded}&jobTitle=${jobTitleEncoded}`;
    window.open(url, '_blank');
  };

  return (
    <div className="jobs-page">
      {!searchInitiated ? (
        <div className="flex items-center justify-center mt-12 h-[60%]">
          <div className='flex-wrap'>
            <h2 className="text-2xl font-bold mb-5 text-center">Search jobs</h2>
            <div className="w-full p-3 bg-white rounded-lg shadow-md mx-auto" style={{ width: '1000px' }}>
              <form onSubmit={handleSearchSubmit}>
                <div className="job-search-form flex gap-2 text-md">
                  <div className="relative flex-grow">
                    <BiBriefcase className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      name="query"
                      type="text"
                      placeholder="Job Title or Company Name"
                      value={formValues.query}
                      onChange={handleInputChange}
                      className="job-input job-title border-right p-3 pl-12 w-full"
                    />
                  </div>
                  <div className="relative flex-grow-0 flex-basis-1/4">
                    <BiMap className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      name="location"
                      type="text"
                      placeholder="Location"
                      value={formValues.location}
                      onChange={handleInputChange}
                      className="job-input job-location p-3 pl-12 w-full"
                    />
                  </div>
                  <div className="relative flex-grow-0 flex-basis-1/4">
                    <BiCalendar className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <select
                      name="datePosted"
                      value={formValues.datePosted}
                      onChange={handleInputChange}
                      className="job-input job-date-posted p-3 pl-12 w-full"
                    >
                      <option value="">Posted Anytime</option>
                      <option value="month">Within 30 days</option>
                      <option value="week">Within 7 days</option>
                      <option value="3days">Within 3 days</option>
                      <option value="today">Within 1 day</option>
                    </select>
                  </div>
                  <button
                    type="submit"
                    className="job-search-btn flex items-center text-md gap-1.5 ml-3 bg-blue-500 text-white px-4 py-1.3 rounded hover:bg-blue-700 transition-colors duration-300"
                  >
                    <BiSearchAlt /> Search
                  </button>
                </div>
              </form>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-5 items-baseline text-sm mt-4">
                <button                  
                  onClick={() => switchTab('saved')}
                  className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'saved' ? 'active underline' : ''}`}>
                  <GoHeartFill className="mr-1.5" style={{ fill: '#f36262' }} /> Saved Jobs
                </button>
              </div>
              <div className='flex items-center justify-center p-6 text-sm pb-3 text-gray-500 italic'>Explore over 5,00,000+ jobs</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-[40%] h-screen border-l border-gray-200">
            <div className='flex flex-col w-full h-screen border-l-10 border-cyan-400'>
              <div className="p-4 py-6">
                <div className='flex justify-between'>
                  <h2 className="text-lg font-bold mb-2 md:w-[50%]">Search jobs</h2>
                  <div className='flex grow-1 gap-5 items-baseline text-sm'>
                    <button
                      onClick={() => switchTab('search')}
                      className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'search' ? 'active underline' : ''}`}>
                      <BiSearchAlt className='mr-1.5' /> Search Results
                    </button>
                    <button
                      onClick={() => switchTab('saved')}
                      className={`tab flex items-center text-blue-500 hover:text-blue-700 ${activeTab === 'saved' ? 'active underline' : ''}`}>
                      <GoHeartFill className='mr-1.5' style={{ fill: `#f36262` }} /> Saved Jobs
                    </button>
                  </div>
                </div>
                <form onSubmit={handleSearchSubmit} className="space-y-4 w-full">
                  <div className="flex flex-col md:flex-row gap-2 text-sm w-full">
                    <div className="relative w-full">
                      <BiBriefcase className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                        name="query"
                        type="text"
                        placeholder="Job Title"
                        value={formValues.query}
                        onChange={handleInputChange}
                        className="job-input job-title border p-2 rounded pl-10 w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-2 text-sm w-full">
                    <div className="relative w-full md:flex-1">
                      <BiMap className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <input
                        name="location"
                        type="text"
                        placeholder="Location"
                        value={formValues.location}
                        onChange={handleInputChange}
                        className="job-input job-location border p-2 rounded pl-10 w-full"
                      />
                    </div>
                    <div className="relative w-full md:flex-1">
                      <BiCalendar className="absolute z-10 left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      <select
                        name="datePosted"
                        value={formValues.datePosted}
                        onChange={handleInputChange}
                        className="job-input job-date-posted border p-2 rounded pl-10 w-full"
                      >
                        <option value="">Anytime</option>
                        <option value="today">Today</option>
                        <option value="3days">Last 3 days</option>
                        <option value="week">Last week</option>
                        <option value="month">Last month</option>
                      </select>
                    </div>
                    <button type="submit" className="job-search-btn flex items-center gap-3 bg-blue-500 text-white px-3 py-2 text-lg rounded shadow hover:bg-blue-700 transition-colors duration-300 w-full md:w-auto md:px-4">
                      <BiSearchAlt /> Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="flex-1 overflow-auto custom-scrollbar p-5">
                  
                 {isLoading && activeTab === 'search' &&<div className='flex h-full justify-center items-center'><span><LoadingSpinner displayType='inline'/></span></div>}
                {errorMessage && <div className="job-error text-red-500 text-center my-2">{errorMessage}</div>}
                {activeTab === 'search' && !selectedJob && !isLoading && !errorMessage && <div className='flex items-center justify-center h-full text-gray-600'>Enter the job title and location.</div>}
                {(activeTab === 'search' ? jobs : savedJobs.filter(job => job.status === "saved")).map((job) => (
                  <div key={job.id} className={`search-job-card border-b border-i-indigo-500 cursor-pointer p-4 py-6 hover:bg-gray-100 ${selectedJob && job.id === selectedJob.id ? 'activeCard' : ''}`} onClick={() => handleJobSelect(job)}>
                    <div className='flex w-full position-relative'>
                      <div className="company-logos mr-4 w-14">
                        {!job.image || job.image === "https://fonts.gstatic.com/s/i/googlematerialicons/auto_stories/v8/gm_grey-24dp/1x/gm_auto_stories_gm_grey_24dp.png" ? (
                          <div className={`w-14 h-14 rounded bg-red-400 flex items-center justify-center text-white font-bold text-2xl`}>
                            {job.company[0]}
                          </div>
                        ) : (
                          <img src={job.image} alt={job.title} className="" />
                        )}
                      </div>
                      <div className="company-details md:w-[85%]">
                        <div className='flex justify-between'>
                          <div className='grow-1'>
                            <h3 className="text-sm mb-1 font-bold">{job.title}</h3>
                            <p className="text-sm mb-2">
                              {job.company}
                            </p>
                          </div>
                          <div className='saveJob flex relative min-w-[99px]'>
<div>
{savingJobId === job.id ? (
    <LoadingSpinner displayType="small" />  // Show loading spinner if the job is being saved
  ) : !job.isSaved && job.status !== "saved" ? (
    <Tooltip content="Save Job" direction="up">
      <button onClick={() => handleSaveJob(job)} className="text-xl p-1">
        <GoHeart />
      </button>                          
    </Tooltip>
  ) : (
    <Tooltip content="Un-save Job" direction="up">
      <button onClick={() => handleDeleteJob(job)} className="text-xl p-1">
        <GoHeartFill />
      </button>                         
    </Tooltip>
  )}
  </div>
<div>
  {/* Show "Track Job" link if the job is saved */}
  {job.status === "saved" && (
     <button 
     onClick={() => navigate('/job-tracker')}  // Use navigate to go to Job Tracker without reloading
     className="ml-1 text-sm text-blue-500 underline"
   >
     Track Job
   </button>
  )}
    {job.status !== "saved" && (
     <button 
     onClick={() => handleSaveJob(job)}   
     className="ml-1 text-sm text-blue-500 underline"
   >
     Save Job
   </button>
  )}
  </div>
</div>

                        </div>
                        <div className='flex text-gray-500 justify-between'>
                          <div className="text-sm">
                            <p>
                              <BiMap className="inline mr-1" />
                              {job.location}
                            </p>
                            <p className='mt-1'>
                              <BiBriefcaseAlt2 className="inline mr-1" />
                              {job.employmentType}
                            </p>
                          </div>
                          <p className="text-sm ">
                            <BiAlarm className="inline mr-1" />
                            {job.datePosted}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {activeTab === 'search' && jobs.length > 0 && (
                  <div className="flex flex-col items-center">
                    {isLoading && pageIndex > 0 && <div className='my-4 py-6'><LoadingSpinner /></div>}
                    <button onClick={loadMoreJobs} className="load-more-btn bg-gray-200 text-gray-600 my-4 px-4 py-2 text-md rounded hover:text-gray-800 hover:bg-gray-300 transition-colors duration-300">
                      Load more
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-[60%] h-screen">
  <div className='flex flex-col w-full h-screen'>
    <div className="flex-1 overflow-auto custom-scrollbar p-5">

      {/* For the Search Results Tab */}
      {activeTab === 'search' && jobs.length === 0 ? (
        // If there are no jobs in the search tab, display a message
        <div className='flex items-center justify-center h-full text-gray-600'>
          No jobs found. Please search for jobs.
        </div>
      ) : activeTab === 'search' && selectedJob ? (
        // Show job details in the search tab if a job is selected
        <div className="search-job-details space-y-4 bg-white p-8 px-12">
          <h2 className="text-2xl font-bold">{selectedJob.title}</h2>
          <div className='flex'>
            <div className='company-logo'>
              {!selectedJob.image ? (
                <div className={`w-14 h-14 rounded bg-red-400 flex items-center justify-center text-white font-bold text-2xl`}>
                  {selectedJob.company[0]}
                </div>
              ) : (
                <img src={selectedJob.image} alt={selectedJob.title} />
              )}
            </div>
            <div className='company-details text-gray-800 mb-2'>
              <p className="text-md">{selectedJob.company}</p>
              <p className="text-md">{selectedJob.location}</p>
            </div>

             {/* Save/Unsave Job Section */}
  <div className='saveJob relative mt-4'>
    {(!selectedJob.isSaved && selectedJob.status !== "saved") && 
      <Tooltip content="Save Job" direction="up">
        <button 
          onClick={() => handleSaveJob(selectedJob)}
          className="text-xl p-1"
        >
          <GoHeart />
        </button>                          
      </Tooltip>
    }
    {selectedJob.status && selectedJob.status === "saved" &&
      <Tooltip content="Un-save Job" direction="up">
        <button 
          onClick={() => handleDeleteJob(selectedJob)}
          className="text-xl p-1"
        >
          <GoHeartFill />
        </button>                         
      </Tooltip>
    }        
  </div>

          </div>
          <div className='flex justify-between mb-3 py-4 px-4 border-t border-b'>
          {selectedJob.jobProviders.length > 1 ? (
            <div className="relative inline-block text-left">
              <button
  className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700 flex items-center justify-center'
  onClick={toggleDropdown}
>
  Apply Now <span className="ml-2">▼</span> {/* Add Unicode arrow here */}
</button>
              {dropdownOpen && (
                <div className="absolute mt-2 rounded shadow py-2 bg-white z-10 border border-gray-200 w-[250px] rounded-tr-[15px] rounded-tl-[15px] rounded-br-[15px] rounded-bl-[15px]">
                        {selectedJob.jobProviders.map((provider, index) => (
                                    <a
                                      key={provider.url}
                                      href={provider.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                                      onClick={handleLinkClick} // Close the dropdown on link click
                                    >
                                      Apply on {provider.jobProvider}
                                    </a>
                                  ))}
                                </div>
                              )}
                            </div>
                          ) : (
                            <a
                              href={selectedJob.jobProviders[0].url}
                              target="_blank"
                              rel="noopener noreferrer"
          
                              className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
                              onClick={handleApplyClick}
                            >
                              Apply Now
                            </a>
                          )}
                                <div className="relative">
                                  <button
                                    onClick={() => handleOptimizeClick(selectedJob)}
                                    className="flex justify-between bg-green-500 text-white text-sm py-2 px-4 rounded-full hover:bg-green-700"
                                  >
                                    <BiRocket className="mr-2 text-xl" />Optimize Resume
                                  </button>
                                </div>
</div>

          <div className='pt-4'>
            <p className="text-gray-700 whitespace-pre-line text-sm">{selectedJob.description}</p>
          </div>
        </div>
      ) : activeTab === 'search' ? (
        // If no job is selected in the search tab
        <div className='flex items-center justify-center h-full text-gray-600'>
          Select a job to view details.
        </div>
      ) : null}

      {/* For the Saved Jobs Tab */}
      {activeTab === 'saved' && savedJobs.length === 0 ? (
        // If no jobs are saved, display a message
        <div className='flex items-center justify-center h-full text-gray-600'>
          No saved jobs found.
        </div>
      ) : activeTab === 'saved' && selectedJob && selectedJob.status === "saved" ? (
        // Show job details in the saved jobs tab if the job has the status "saved"
        <div className="saved-job-details space-y-4 bg-white p-8 px-12">
          <h2 className="text-2xl font-bold">{selectedJob.title}</h2>
          <div className='flex'>
            <div className='company-logo'>
              {!selectedJob.image ? (
                <div className={`w-14 h-14 rounded bg-red-400 flex items-center justify-center text-white font-bold text-2xl`}>
                  {selectedJob.company[0]}
                </div>
              ) : (
                <img src={selectedJob.image} alt={selectedJob.title} />
              )}
            </div>
            <div className='company-details text-gray-800 mb-2'>
              <p className="text-md">{selectedJob.company}</p>
              <p className="text-md">{selectedJob.location}</p>
            </div>
          </div>
          <div className='flex justify-between mb-3 py-4 px-4 border-t border-b'>
          {selectedJob.jobProviders.length > 1 ? (
            <div className="relative inline-block text-left">
                     <button
  className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700 flex items-center justify-center'
  onClick={toggleDropdown}
>
  Apply Now <span className="ml-2">▼</span> {/* Add Unicode arrow here */}
</button>
              {dropdownOpen && (
                <div className="absolute mt-2 rounded shadow py-2 bg-white z-10 border border-gray-200 w-[250px] rounded-tr-[15px] rounded-tl-[15px] rounded-br-[15px] rounded-bl-[15px]">
                        {selectedJob.jobProviders.map((provider, index) => (
                                    <a
                                      key={provider.url}
                                      href={provider.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                                      onClick={handleLinkClick} // Close the dropdown on link click
                                    >
                                      Apply on {provider.jobProvider}
                                    </a>
                                  ))}
                                </div>
                              )}
                            </div>
                          ) : (
                            <a
                              href={selectedJob.jobProviders[0].url}
                              target="_blank"
                              rel="noopener noreferrer"
          
                              className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
                              onClick={handleApplyClick}
                            >
                              Apply Now
                            </a>
                          )}
                                <div className="relative">
                                  <button
                                    onClick={() => handleOptimizeClick(selectedJob)}
                                    className="flex justify-between bg-green-500 text-white text-sm py-2 px-4 rounded-full hover:bg-green-700"
                                  >
                                    <BiRocket className="mr-2 text-xl" />Optimize Resume
                                  </button>
                                </div>
          </div>
          <div className='pt-4'>
            <p className="text-gray-700 whitespace-pre-line text-sm">{selectedJob.description}</p>
          </div>
        </div>
      ) : activeTab === 'saved' ? (
        // If no valid saved job is selected
        <div className='flex items-center justify-center h-full text-gray-600'>
          Select a saved job to view details.
        </div>
      ) : null}

    </div>
  </div>
</div>


        </div>     
      )}
      <ToastContainer 
       position="top-right"    // Set default position to top-center
       hideProgressBar          // Hide the progress bar globally
       autoClose={3000}         // Optional: auto-close after 3 seconds
       //closeOnClick             // Optional: close on click
       //pauseOnHover             // Optional: pause on hover
       //draggable                // Optional: make it draggable
       />   
    </div>
  );
};

export default Jobs;
