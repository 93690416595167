// src/components/OptimizeResumeWithAI.js
import React, { useState, useEffect } from 'react';
import { createResumeWithDataService } from '../services/createResumeWithDataService';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import LoadingSpinner from '../components/LoadingSpinner';

const OptimizeResumeWithAI = () => {
  const [file, setFile] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadOption, setUploadOption] = useState('upload');
  const navigate = useNavigate();
  const location = useLocation(); // Initialize useLocation

  // Extract query parameters from URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobDescriptionFromUrl = params.get('jobDescription');
    if (jobDescriptionFromUrl) {
      setJobDescription(jobDescriptionFromUrl);
    }
  }, [location]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null); // Clear error when a new file is selected
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setError(null); // Clear error when a new file is dropped
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    if (jobDescription.trim() === '') {
      setError('Please enter a job description.');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('resume', file); // Append the file
    formData.append('jobDescription', jobDescription); // Append the job description

    const local = 'http://localhost:3002/rewrite-resume';
    const production = 'https://rd-server-418cf5202d66.herokuapp.com/rewrite-resume';

    try {
      const response = await fetch(production, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload resume or submit job description.');
      }

      const data = await response.json();
      
      // Check if data and rewrittenResume are defined
      if (data && data.rewrittenResume) {
        const resumeId = await createResumeWithDataService(data.rewrittenResume, navigate);
        if (resumeId) {
          console.log(`Resume created successfully with ID: ${resumeId}`);
        }
      } else {
        throw new Error('Optimized resume data is not available.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Effect to handle the rotating messages while loading
  useEffect(() => {
    if (loading) {
      const messages = [
        'Resume optimization started. Please wait...',
        'Analyzing job description keywords...',
        'Reformatting resume for ATS compatibility...',
        'Improving content clarity and impact...',
        'Finalizing optimized resume version...',
        'Almost done, preparing download link...'
      ];
      let index = 0;

      const interval = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000); // Change message every 2 seconds

      return () => clearInterval(interval);
    } else {
      setLoadingMessage(''); // Clear message when not loading
    }
  }, [loading]);

  return (
    <div className="flex flex-col items-center min-h-screen p-4 mb-9">
      <h2 className="text-2xl font-bold mt-12 mb-4">Optimize Resume with AI</h2>
      <p className="mb-2">Enhance your resume by matching it to a job description and making it ATS-friendly with AI.</p>      

      {/* Options to choose upload method */}
      <div className="flex space-x-4 mb-0 mt-6">
        <button
          className={`py-2 px-4 rounded-lg border-2 ${
            uploadOption === 'upload'
              ? 'bg-gray-800 text-white border-gray-800 hover:bg-gray-700'
              : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
          }`}
          onClick={() => setUploadOption('upload')}
        >
          Upload Resume
        </button>
        <button
          className={`py-2 px-4 rounded-lg border-2 ${
            uploadOption === 'description'
              ? 'bg-gray-800 text-white border-gray-800 hover:bg-gray-700'
              : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
          }`}
          onClick={() => setUploadOption('description')}
        >
          Add Job Description
        </button>
      </div>

      {uploadOption === 'upload' ? (
        <div
          className={`w-full max-w-md bg-white mt-6 rounded-lg shadow-md p-12 border-dashed border-2 ${
            isDragging ? 'border-blue-400' : 'border-gray-300'
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="mb-4 text-center">
            <div className='mb-5'>
              <p className="text-sm font-medium text-gray-700">
                Drag & Drop your resume here or click the button below
              </p>
              <i className="text-xs text-gray-600">Acceptable file types: PDF, DOC, DOCX</i>
            </div>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              className="hidden"
            />
            <button
              onClick={handleButtonClick}
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-300"
            >
              Choose File
            </button>
          </div>

          {file && (
            <p className="mt-2 text-sm text-gray-600 text-center">
              Selected File: {file.name}
            </p>
          )}
        </div>
      ) : (
        <div className="w-full max-w-md bg-white mt-6 rounded-lg shadow-md p-5 border border-gray-300">
          <textarea
            className="w-full h-40 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter your job description here..."
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
          />
        </div>
      )}

      <button
        onClick={handleUpload}
        disabled={loading}
        className={`mx-auto py-2 px-4 rounded-lg mt-8 ${
          loading ? 'bg-blue-400 text-white cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        {loading ? 'Optimizing resume in progress...' : 'Optimize resume with AI'}
      </button>

      {loading && (
        <div className="flex flex-col items-center mt-4">
          <LoadingSpinner displayType="inline" />
          <p className="mt-2 text-gray-700">{loadingMessage}</p>
        </div>
      )}

      {/* Error message display */}
      {error && <p className="mt-4 text-red-500">{error}</p>}
    </div>
  );
};

export default OptimizeResumeWithAI;
