// src/components/Footer.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { BiHome, BiBrain, BiConversation, BiBriefcase, BiShield } from 'react-icons/bi';

const Footer = () => {
  const { user } = useAuth();
  const location = useLocation();

  const getNavItemClass = (path) => {
    return location.pathname === path ? 'flex flex-col items-center active' : 'flex flex-col items-center';
  };

  const isAdmin = Boolean(localStorage.getItem('selectedUserId')) || (user && user.email === process.env.REACT_APP_ADMIN_EMAIL);

  return (
    <nav className="footer-mobile py-4 bg-white fixed bottom-0 w-full text-black shadow-md">
      <ul className="flex justify-around">
        <li className={getNavItemClass('/dashboard')}>
          <Link to="/documents" className="flex flex-col items-center">
            <BiHome className="text-xl" />
            <span className="text-xs">Dashboard</span>
          </Link>
        </li>
        <li className={getNavItemClass('/resume-analysis')}>
          <Link to="/resume-analysis" className="flex flex-col items-center">
            <BiBrain className="text-xl" />
            <span className="text-xs">Analysis</span>
          </Link>
        </li>
        <li className={getNavItemClass('/job-interview')}>
          <Link to="/job-interview" className="flex flex-col items-center">
            <BiConversation className="text-xl" />
            <span className="text-xs">Interview</span>
          </Link>
        </li>
        <li className={getNavItemClass('/jobs')}>
          <Link to="/jobs" className="flex flex-col items-center">
            <BiBriefcase className="text-xl" />
            <span className="text-xs">Jobs</span>
          </Link>
        </li>
        {isAdmin && (
          <li className={getNavItemClass('/admin')}>
            <Link to="/admin" className="flex flex-col items-center">
              <BiShield className="text-xl" />
              <span className="text-xs">Admin</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Footer;
