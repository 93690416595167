import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation to get query params
import LoadingSpinner from '../components/LoadingSpinner';
import { BiBriefcase, BiSearchAlt, BiCopy, BiBarChartAlt2, BiListUl } from 'react-icons/bi';
import { BsStars } from "react-icons/bs";
import { GoHeartFill } from 'react-icons/go';
import { generateJobInterview } from '../services/aiService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobInterview = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object to extract query params
  const [jobTitle, setJobTitle] = useState('');
  const [seniorityLevel, setSeniorityLevel] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const [interviewQA, setInterviewQA] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Extract query parameters on component mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const prefilledJobTitle = params.get('jobTitle');
    const prefilledSeniorityLevel = params.get('seniorityLevel');

    if (prefilledJobTitle) setJobTitle(prefilledJobTitle);
    if (prefilledSeniorityLevel) setSeniorityLevel(prefilledSeniorityLevel);
  }, [location.search]);

  const handleGenerateInterview = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setInterviewQA([]);
    setErrorMessage('');

    try {
      const data = await generateJobInterview(jobTitle, seniorityLevel, questionType, currentLanguage);
      const processedContent = processInterviewContent(data.generated_text);
      setInterviewQA(processedContent);
      setIsLoading(false);
    } catch (error) {
      toast.error('Failed to generate job interview content!');
      console.error('Failed to generate job interview content:', error);
      setErrorMessage('Failed to generate job interview content. Please try again later.');
      setIsLoading(false);
    }
  };

  const processInterviewContent = (content) => {
    const lines = content.split('\n');
    const qaPairs = [];
    let currentQuestion = '';
    let currentAnswer = '';
    let currentTip = '';

    lines.forEach(line => {
      if (line.startsWith('Q.')) {
        if (currentQuestion && currentAnswer && currentTip) {
          qaPairs.push({ question: currentQuestion.replace('Q. ', ''), answer: currentAnswer, tip: currentTip });
          currentAnswer = '';
          currentTip = '';
        }
        currentQuestion = line.replace('Q. ', '');
      } else if (line.startsWith('A.')) {
        currentAnswer = line;
      } else if (line.startsWith('Tip:')) {
        currentTip = line.replace('Tip: Tip:', 'Tip:').trim();
      } else {
        if (currentAnswer) {
          currentAnswer += '\n' + line;
        } else if (currentTip) {
          currentTip += '\n' + line;
        } else {
          currentQuestion += '\n' + line;
        }
      }
    });

    if (currentQuestion && currentAnswer && currentTip) {
      qaPairs.push({ question: currentQuestion.replace('Q. ', ''), answer: currentAnswer, tip: currentTip });
    }

    return qaPairs;
  };

  const handleCopyToClipboard = () => {
    const textToCopy = interviewQA.map(qa => `${qa.question}\n${qa.answer}`).join('\n\n');
    navigator.clipboard.writeText(textToCopy).then(() => { 
      toast.success('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy text!');
    });
  };

  return (
    <div className="jobInterview flex flex-col p-4 md:p-8 items-center justify-top h-full w-full lg:w-[80%] m-auto">
      <h2 className="text-2xl font-bold mb-5 mt-12 text-center">Job Interview Preparation</h2>
      <div className='w-full p-3 bg-white rounded-lg shadow-md sticky top-0 z-10'>
        <form onSubmit={handleGenerateInterview} className="flex flex-wrap gap-2 text-md">
          <div className="flex-1 min-w-[200px] md:min-w-[250px]">
            <div className="relative">
              <BiBriefcase className="absolute z-10 left-2 top-4 transform text-gray-400" />
              <input
                name="jobTitle"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Job Title"
                className="job-input job-title p-2 pl-10 w-full"
              />
            </div>
          </div>
          <div className="relative flex-1 min-w-[170px]">
            <BiBarChartAlt2 className="absolute z-10 left-2 top-4 transform text-gray-400" />
            <select
              name="seniorityLevel"
              value={seniorityLevel}
              onChange={(e) => setSeniorityLevel(e.target.value)}
              className="job-input job-title p-3 pl-10 w-full"
            >
              <option value="Senior">Senior</option>
              <option value="Entry-level">Entry-level</option>
              <option value="Junior">Junior</option>
              <option value="Mid-level">Mid-level</option>
              <option value="Lead">Lead</option>
              <option value="Manager">Manager</option>
              <option value="Director">Director</option>
              <option value="Executive">Executive</option>
            </select>
          </div>
          <div className="relative flex-1 min-w-[180px]">
            <BiListUl className="absolute z-10 left-2 top-4 transform text-gray-400" />
            <select
              name="questionType"
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              className="job-input job-title p-3 pl-10 w-full"
            >
              <option value="General">General Questions</option>
              <option value="Technical">Technical Questions</option>
              <option value="Behavioral">Behavioral Questions</option>
              <option value="Competency">Competency Questions</option>
              <option value="Skill-base">Skill-Based Questions</option>
              <option value="Situational">Situational Questions</option>
              <option value="Cultural Fit">Cultural Fit Questions</option>
              <option value="Experience-Based">Experience-Based Questions</option>
              <option value="Case Study">Case Study Questions</option>
              <option value="Stress">Stress Questions</option>
            </select>
          </div>
          <div className="flex items-center">
            <button type="submit" className="job-search-btn flex items-center text-md gap-1.5 bg-blue-500 text-white px-4 py-2.5 rounded shadow hover:bg-blue-700 transition-colors duration-300">
              <BsStars /> Generate
            </button>
          </div>
        </form>
      </div>
  
      {isLoading && (
        <div className='flex justify-center items-center h-full'>
          <div className='items-center'>
            <LoadingSpinner />
            <p className='mt-3 text-center text-sm italic min-w-[80%]'>Generating your top-class Interview Questions. It may take up to 10 seconds.</p>
          </div>
        </div>
      )}

      {errorMessage && !isLoading && (
        <div className="text-red-500 text-center my-4">{errorMessage}</div>
      )}

      {interviewQA.length > 0 && (
        <>
          <div className="flex justify-between w-full items-center mt-8 py-4">
            <h3 className="text-xl font-bold">Interview Questions and Answers</h3>
            <button
              onClick={handleCopyToClipboard}
              className="flex gap-1.5 text-blue-500 hover:text-blue-700"
            >
              <BiCopy /> Copy
            </button>
          </div>
          <div className="p-8 px-0 interviewWrap w-full">
            <div className="interview-qa">
              {interviewQA.map((qa, index) => (
                <div key={index} className="interviewCard mb-4">
                  <h3><strong>{index + 1}. {qa.question}</strong></h3>
                  <p>{qa.answer}</p>
                  <div className="tip"><strong>Tip:</strong> {qa.tip.replace('Tip: ', '')}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default JobInterview;
