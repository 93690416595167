//JobGuidence.js
const guidanceContent = {
    saved: [
      "Regularly review and update your list of favorite job postings to focus on the most relevant opportunities.",
      "Use resume optimization tools to tailor your resume for each job in your saved to increase your chances of being noticed.",
      "Draft personalized cover letters for your favorite jobs, addressing specific requirements mentioned in the job descriptions.",
      "Leverage an email generator to prepare proactive inquiry emails to potential employers, expressing interest and requesting more information.",
      "Research each company on your saved list to better understand their culture, values, and current needs.",
      "Set reminders for application deadlines to ensure you submit applications on time for your favorite roles."
    ],
    applied: [
      "Customize each resume and cover letter submission to match the job description and requirements closely.",
"Find and research someone who works at the company and add as a contact",

"Set up an informational interview to learn more about the role / company",

"Identify potential referrals to help get your application on the top of the pile",

"Customize your work achievements using the job description keywords",

"Submit your application on the company website if possible"
    ],
    interviewing: [
      "Prepare thoroughly for interviews using an interview prep generator to practice responses to common and behavioral questions.",
      "Research the company's interview process and format to tailor your preparation accordingly.",
      "Bring copies of your optimized resume and cover letter to the interview to refer to specific points if needed.",
      "Use mock interviews and practice tools to build confidence and improve your communication skills.",
      "Prepare thoughtful questions to ask the interviewer, demonstrating your interest in the role and company.",
      "Dress appropriately and ensure your technology setup (for virtual interviews) is working correctly to make a strong impression."
    ],
    offer: [
      "Carefully review the job offer, considering all aspects such as salary, benefits, job role, and growth opportunities.",
      "Utilize an email generator to craft a professional acceptance letter or counter-offer email for negotiations.",
      "Research market rates for the position to ensure your compensation is competitive and justifiable.",
      "Prepare to negotiate respectfully by highlighting your unique skills and experiences that add value to the company.",
      "Clarify any uncertainties regarding the offer, such as job expectations, probation periods, and remote work options.",
      "Ensure that all agreed-upon terms are documented in a formal offer letter to avoid misunderstandings."
    ],
    rejected: [
      "Request feedback from employers to understand why you were not selected and how you can improve.",
      "Reflect on the interview experience to identify areas for improvement and adjust your strategy accordingly.",
      "Use resume optimization tools to revise your resume and better align it with future job postings.",
      "Maintain a positive attitude and continue applying to other opportunities, using rejection as a learning experience.",
      "Leverage an email generator to craft a polite thank-you or follow-up email after receiving a rejection.",
      "Expand your job search criteria or explore different roles that align with your skills and experience."
    ],
    hired: [
      "Celebrate your success and take time to prepare for your new role by reviewing any onboarding materials provided.",
      "Set clear goals for your first 30, 60, and 90 days to align with company expectations and demonstrate your value.",
      "Use an email generator to craft professional introduction emails to your new team members and key stakeholders.",
      "Familiarize yourself with the company’s tools, systems, and processes to ensure a smooth transition into your new role.",
      "Seek feedback from your manager regularly to understand your performance and areas for improvement.",
      "Build relationships with your colleagues and participate in team activities to integrate quickly into the company culture."
    ],
  };

  export default guidanceContent; 