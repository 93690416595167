import React, { useState } from 'react';
import { createResumeWithDataService } from '../services/createResumeWithDataService';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { CgClose } from "react-icons/cg";

const ResumeUploader = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }
  
    const formData = new FormData();
    formData.append('resume', file);
  
    setLoading(true);
    setError(null);
  
    const local = 'http://localhost:3002/upload-resume';
    const production = 'https://rd-server-418cf5202d66.herokuapp.com/upload-resume';
  
    try {
      const response = await fetch(production, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload resume.');
      }
  
      // Ensure that the response has data before proceeding
      const data = await response.json();
      if (!data || !Object.keys(data).length) {
        throw new Error('Received an empty response from the server.');
      }
  
      const resumeId = await createResumeWithDataService(data, navigate);
      if (resumeId) {
        console.log(`Resume created successfully with ID: ${resumeId}`);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleBacktoDashboard = () => {
    navigate(`/documents`);
  };
  return (
    <div className="flex flex-col items-center mt-12 p-6 mb-9">      
        <button className="absolute text-3xl px-4 m-4 top-[20px] right-0 hover:text-blue-600" onClick={handleBacktoDashboard}>
          <CgClose />
        </button>    
      <h2 className="text-2xl font-bold mb-4">Upload Your Current Resume</h2>
      <p className='mb-2'>Transform your current resume into a fresh new looking design.</p> 
      <div
        className={`w-full max-w-md bg-white mt-6 rounded-lg shadow-md p-12 border-dashed border-2 ${
          isDragging ? 'border-blue-400' : 'border-gray-300'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="mb-4 text-center">
           <p className="text-sm font-medium text-gray-700 ">
              Drag & Drop your resume here or click the button below               
            </p>
            <i className="text-xs text-gray-600">Acceptable file types: PDF, DOC, DOCX</i>
          <input
            id="fileInput"
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        <div className="mb-4 text-center">          
          <button
              onClick={handleButtonClick}
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-300"
            >
              Choose File
            </button>
        </div>
        {file && (
          <p className="mt-2 text-sm text-gray-600">
            Selected File: {file.name}
          </p>
        )}
</div>
      <div className='text-center mt-6'>
        <button
            onClick={handleUpload}
            disabled={loading}
            className={`py-2 px-4 rounded-lg mt-4 ${
              loading
                ? 'bg-blue-400 text-white cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            {loading ? 'Transforming...' : 'Upload your resume'}
          </button>
      </div>

        {loading && (
          <div className="flex flex-col items-center mt-4">
            <LoadingSpinner displayType='inline' />
            <p className="mt-2 text-gray-700">Uploading your resume. Please wait...</p>
          </div>
        )}

        {error && <p className="mt-4 text-red-500">{error}</p>}
      
    </div>
  );
};

export default ResumeUploader;
