import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import JobColumn from './JobColumn';
import JobDetailsModal from './JobDetailsModal';
import { fetchJobs, updateJobStatus } from '../../services/jobService'; 
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import { useLocation, useNavigate } from 'react-router-dom'; 

const JobTracker = () => {
  const { user } = useAuth();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialTab, setInitialTab] = useState('Job Description');
  const location = useLocation();
  const navigate = useNavigate();
  const [showOfferJobs, setShowOfferJobs] = useState(true);

  // Function to parse the URL query parameters
  const parseQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const jobId = params.get('jobId');
    const tab = params.get('tab') || 'Job Description';
    return { jobId, tab };
  };

  useEffect(() => {
    const fetchFavoriteJobs = async () => {
      setIsLoading(true); 
      try {
        if (user?.uid) {
          const jobsData = await fetchJobs(user.uid);
          setJobs(jobsData);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setIsLoading(false); 
      }
    };
  
    if (user) {
      fetchFavoriteJobs();
    }
  }, [user]);

  // Automatically open the modal based on the URL query parameters
  useEffect(() => {
    const { jobId, tab } = parseQueryParams();
    if (jobId && jobs.length > 0) {
      const selected = jobs.find((job) => job.docId === jobId);
      if (selected) {
        setSelectedJob(selected);
        setInitialTab(tab);
        setShowModal(true);
      }
    }
  }, [location.search, jobs]);

  const closeJobModal = () => {
    setShowModal(false);
    setSelectedJob(null);
    const url = new URL(window.location);
    url.searchParams.delete('jobId');
    url.searchParams.delete('tab');
    window.history.pushState({}, '', url);
  };

  const handleJobClick = (job, tab = 'Job Description') => {
    setSelectedJob(job);
    setInitialTab(tab);
    setShowModal(true);
  };

  const handleJobDelete = (jobId) => {
    setJobs((prevJobs) => prevJobs.filter(job => job.id !== jobId));
  };

  const handleDrop = async (job, newStatus) => {
    if (!user?.uid) {
      console.error('User ID is not available');
      return;
    }
  
    const docId = job.docId;
  
    // If the new status is "applied", we add the applied date immediately
    const updateData = {
      status: newStatus,
      ...(newStatus === 'applied' && { appliedDate: new Date() }), // Add appliedDate to local state immediately
    };
  
    // Update the local state with the new status and appliedDate
    setJobs((prevJobs) => {
      return prevJobs.map((j) =>
        j.docId === docId ? { ...j, ...updateData } : j
      );
    });
  
    try {
      // Update Firestore
      await updateJobStatus(user.uid, docId, newStatus);
      console.log('Job status updated successfully');
    } catch (error) {
      console.error('Error updating job status:', error);
      // Revert the state in case of an error
      setJobs((prevJobs) => prevJobs.map((j) =>
        j.docId === docId ? { ...j, status: job.status } : j
      ));
    }
  };
  

 const handleStatusChange = async (jobDocId, newStatus) => {
  // Set the appliedDate when status changes to "applied"
  const updateData = {
    status: newStatus,
    ...(newStatus === 'applied' && { appliedDate: new Date() })  // Add appliedDate if status is "applied"
  };

  // Log the updateData to ensure it's correct
  console.log('Updating job with status change:', updateData);

  try {
    // Update the local state with appliedDate
    setJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.docId === jobDocId ? { ...job, ...updateData } : job
      )
    );

    // Update Firestore with new status and appliedDate
    await updateJobStatus(user.uid, jobDocId, newStatus);
    console.log('Job status updated successfully');
  } catch (error) {
    console.error('Error updating job status:', error);
  }
};

  

  const offerJobs = jobs.filter(job => job.status === 'offer');
  const archiveJobs = jobs.filter(job => job.status === 'archive');

  return (
    <div className="w-full h-full">
      <DndProvider backend={HTML5Backend}>
        <div className="flex justify-between items-center w-full p-5">
          <h2 className="text-xl font-bold text-center pt-4 m-0 pl-4">Job Tracker</h2>
          <button
            className={`px-3 py-1 text-sm rounded ${showOfferJobs ? 'bg-gray-200' : 'bg-gray-300'}`}
            onClick={() => setShowOfferJobs(!showOfferJobs)} 
          >
            {showOfferJobs ? 'Archive' : 'Offer'}
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner displayType="block" />
          </div>
        ) : (
          <div className="flex items-top justify-top justify-center gap-5 mx-auto p-4 h-[92%]">
            <JobColumn 
              key="saved" 
              status="saved" 
              jobs={jobs.filter(job => job.status === 'saved')}
              onDrop={handleDrop} 
              onJobClick={handleJobClick}
               onJobDelete={handleJobDelete} 
              onStatusChange={handleStatusChange}
              emptyMessage="No jobs in saved. Perform a job search and add jobs to your saved!"
            />
            <JobColumn 
              key="applied" 
              status="applied" 
              jobs={jobs.filter(job => job.status === 'applied')}
              onDrop={handleDrop} 
              onJobClick={handleJobClick}
              onStatusChange={handleStatusChange}
            />
            <JobColumn 
              key="interviewing" 
              status="interviewing" 
              jobs={jobs.filter(job => job.status === 'interviewing')}
              onDrop={handleDrop} 
              onJobClick={handleJobClick}
              onStatusChange={handleStatusChange}
            />
            <div className="job-column border-2 bg-gray-100 overflow-hidden rounded shadow w-1/6 border border-transparent">  
              <div className="flex justify-center gap-5 w-full">
                {showOfferJobs ? (
                  <JobColumn
                    key="offer"
                    status="offer"
                    jobs={offerJobs}
                    onDrop={handleDrop}
                    onJobClick={handleJobClick}
                    onStatusChange={handleStatusChange}
                  />
                ) : (
                  <JobColumn
                    key="archive"
                    status="archive"
                    jobs={archiveJobs}
                    onDrop={handleDrop}
                    onJobClick={handleJobClick}
                    onStatusChange={handleStatusChange}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {showModal && selectedJob && (
          <JobDetailsModal 
            job={selectedJob} 
            onClose={closeJobModal}
            onJobDelete={handleJobDelete} 
            onStatusChange={handleStatusChange} 
            initialTab={initialTab}
          />
        )}
      </DndProvider>
    </div>
  );
};

export default JobTracker;
