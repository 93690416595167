import { db } from "../config/firebaseConfig";
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc, serverTimestamp } from "firebase/firestore";

/**
 * Fetch all jobs for a user from Firestore
 * @param {string} userId - The ID of the user
 * @returns {Promise<Array>} - A promise that resolves to an array of job objects
 */
export const fetchJobs = async (userId) => {
  try {
    const jobsRef = collection(db, "savedJobs", userId, "jobs");
    const querySnapshot = await getDocs(jobsRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching jobs:", error);
    throw error;
  }
};

/**
 * Save a new job to Firestore for a user
 * @param {string} userId - The ID of the user
 * @param {Object} jobData - The job data to save
 * @returns {Promise<void>}
 */
export const saveJob = async (userId, jobData) => {
  try {
    const jobRef = doc(collection(db, "savedJobs", userId, "jobs"));
    await setDoc(jobRef, jobData);
    console.log("Job saved successfully:", jobRef.id);
  } catch (error) {
    console.error("Error saving job:", error);
    throw error;
  }
};

/**
 * Update a job's status in Firestore
 * @param {string} userId - The ID of the user
 * @param {string} docId - The ID of the job to update
 * @param {string} newStatus - The new status to set for the job
 * @returns {Promise<void>}
 */
export const updateJobStatus = async (userId, docId, newStatus) => {
  try {
    const jobRef = doc(db, "savedJobs", userId, "jobs", docId);
    const updateData = {
      status: newStatus,
      updatedAt: serverTimestamp(),
    };

    if (newStatus === 'applied') {
      console.log(newStatus)
      updateData.appliedDate = serverTimestamp();
    }

    await updateDoc(jobRef, updateData);
    console.log('Job status updated successfully');
  } catch (error) {
    console.error('Error updating job status:', error);
    throw error;
  }
};

/**
 * Add or update notes for a job in Firestore
 * @param {string} userId - The ID of the user
 * @param {string} docId - The ID of the job to update
 * @param {string} notes - The notes content to add or update
 * @returns {Promise<void>}
 */
export const updateJobNotes = async (userId, docId, notes) => {
  try {
    const jobRef = doc(db, "savedJobs", userId, "jobs", docId);
    await updateDoc(jobRef, {
      notes,
      updatedAt: serverTimestamp(),
    });
    console.log('Job notes updated successfully');
  } catch (error) {
    console.error('Error updating job notes:', error);
    throw error;
  }
};

/**
 * Delete a job from Firestore for a user
 * @param {string} userId - The ID of the user
 * @param {string} docId - The ID of the job to delete
 * @returns {Promise<void>}
 */
export const deleteJob = async (userId, docId) => {
  try {
    const jobRef = doc(db, "savedJobs", userId, "jobs", docId);
    await deleteDoc(jobRef);
    console.log("Job deleted successfully:", docId);
  } catch (error) {
    console.error("Error deleting job:", error);
    throw error;
  }
};
