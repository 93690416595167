// src/components/Assessment.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { db } from "../config/firebaseConfig";

const Assessment = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [section, setSection] = useState("Cognitive Abilities");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      const docRef = doc(db, "sections", section);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setQuestions(docSnap.data().questions);
      } else {
        console.log("No such document!");
      }
    };

    fetchQuestions();
  }, [section]);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer
    }));
  };

  const handleSubmit = async () => {
    try {
      await addDoc(collection(db, 'assessments'), {
        section,
        answers,
        timestamp: new Date()
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting assessment: ", error);
    }
  };

  if (submitted) {
    return (
      <div>
        <h2 className="text-xl font-bold text-green-500">
          Thank you for completing the {section} section!
        </h2>
        <button className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          onClick={() => {
            setSubmitted(false);
            setSection("Next Section Name");  // Switch to the next section
            setAnswers({});
          }}
        >
          Continue to Next Section
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-6">{section} Assessment</h1>
      {questions.map((question) => (
        <div key={question.id} className="my-5">
          <h3 className="text-xl font-semibold">{question.text}</h3>
          {question.options.map((option, index) => (
            <div key={index} className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name={`answer-${question.id}`}
                  value={option}
                  className="form-radio h-5 w-5"
                  onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                />
                <span className="ml-2">{option}</span>
              </label>
            </div>
          ))}
        </div>
      ))}
      <button
        onClick={handleSubmit}
        className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Submit Section
      </button>
    </div>
  );
};

export default Assessment;
