import { db } from "../config/firebaseConfig";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import ResumeModal from '../modals/ResumeModal';

/**
 * Helper function to remove undefined values from an object
 * @param {Object} obj The object to clean
 * @returns {Object} Cleaned object without undefined values
 */
const removeUndefinedValues = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
};

/**
 * Convert job responsibilities array to HTML formatted string for ul/li structure
 * @param {Array} responsibilities Array of job responsibilities
 * @returns {String} HTML formatted string for job responsibilities
 */
const formatJobResponsibilities = (responsibilities) => {
  return `<ul>${responsibilities.map(responsibility => `<li>${responsibility}</li>`).join('')}</ul>`;
};

/**
 * Save a resume to Firestore
 * @param {Object} extractedData The extracted data from the uploaded resume
 * @param {Function} navigate The navigation function to redirect after creation
 * @returns {Promise<void>}
 */
export const createResumeWithDataService = async (extractedData, navigate) => {
  console.log(extractedData);

  // Destructure with default values to avoid undefined errors
  const { 
    personalInformation, 
    experience = [], 
    education = [], 
    skills = [], 
    languages = [], 
    projects = [], 
    achievements = [], 
    awards = [], 
    certifications = [], 
    courses = [], 
    references = [] 
  } = extractedData;

  // Construct resume data using extracted information
  const resumeData = {
    formObject: {
      ...ResumeModal.formObject,
      personalDetails: {
        ...ResumeModal.formObject.personalDetails,
        inputGroups: [
          {
            ...ResumeModal.formObject.personalDetails.inputGroups[0],
            inputs: ResumeModal.formObject.personalDetails.inputGroups[0].inputs.map((input) => {
              switch (input.inputKey) {
                case 'firstName':
                  return { ...input, inputValue: personalInformation.name.split(' ')[0] || '' };
                case 'lastName':
                  return { ...input, inputValue: personalInformation.name.split(' ').slice(1).join(' ') || '' };
                case 'jobTitle':
                  return { ...input, inputValue: personalInformation.jobTitle || '' }; 
                default:
                  return input;
              }
            }),
          },
        ],
      },
      professionalSummary: {
        ...ResumeModal.formObject.professionalSummary,
        inputGroups: [
          {
            ...ResumeModal.formObject.professionalSummary.inputGroups[0],
            inputs: ResumeModal.formObject.professionalSummary.inputGroups[0].inputs.map((input) => {
              if (input.inputKey === 'summary') {
                return { ...input, inputValue: personalInformation.professionalSummaryOrObjective || '' };
              }
              return input;
            }),
          }
        ],
      },
      contactDetails: {
        ...ResumeModal.formObject.contactDetails,
        inputGroups: [
          {
            ...ResumeModal.formObject.contactDetails.inputGroups[0],
            inputs: ResumeModal.formObject.contactDetails.inputGroups[0].inputs.map((input) => {
              switch (input.inputKey) {
                case 'phone':
                  return { ...input, inputValue: personalInformation.phone || '' };
                case 'email':
                    return { ...input, inputValue: personalInformation.email || '' };
                case 'city':
                  return { ...input, inputValue: personalInformation.city || '' };
                case 'country':
                    return { ...input, inputValue: personalInformation.country || '' };
                case 'postalCode':
                    return { ...input, inputValue: personalInformation.postalCode || '' };
                case 'website':
                    return { ...input, inputValue: personalInformation.website || '' };
                case 'linkedin':
                    return { ...input, inputValue: personalInformation.linkedin || '' };
                default:
                  return input;
              }
            }),
          },
        ],
      },
      workExperience: {
        ...ResumeModal.formObject.workExperience,
        isShow: Array.isArray(experience) && experience.length > 0,
        inputGroups: experience.map((exp) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          aiWriter: {
            isStatus: true,
            actions: [{ id: 'generate' }, { id: 'rewrite' }],
          },
          inputs: [
            {
              inputKey: 'position',
              inputLabel: 'Position',
              inputType: 'text',
              placeholder: 'Enter your position',
              show: true,
              required: true,
              inputValue: exp.jobRole || '',
            },
            {
              inputKey: 'companyName',
              inputLabel: 'Company Name',
              inputType: 'text',
              placeholder: 'Enter company name',
              show: true,
              required: true,
              inputValue: exp.company || '',
            },
            {
              inputKey: 'startDate',
              inputLabel: 'Start Date',
              inputType: 'daterange',
              placeholder: 'Enter start date',
              show: true,
              required: false,
              inputValue: exp.fromDate || '',
            },
            {
              inputKey: 'endDate',
              inputLabel: 'End Date',
              inputType: 'daterange',
              placeholder: 'Enter end month/year or select Present',
              show: true,
              required: false,
              inputValue: exp.toDate || '',
            },
            {
              inputKey: 'present',
              inputLabel: 'Present',
              inputType: 'checkbox',
              placeholder: '',
              show: true,
              required: false,
              inputValue: !exp.toDate ? true : false,
            },
            {
              inputKey: 'workSummary',
              inputLabel: 'Work Summary',
              inputType: 'textarea',
              placeholder: 'Describe your responsibilities, achievements, etc.',
              show: true,
              required: false,
              inputValue: formatJobResponsibilities(exp.jobResponsibilities) || '',
            },
          ],
        })),
      },
      education: {
        ...ResumeModal.formObject.education,
        isShow: Array.isArray(education) && education.length > 0,
        inputGroups: education.map((edu) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          aiWriter: {
            isStatus: true,
            actions: [{ id: 'generate' }, { id: 'rewrite' }],
          },
          inputs: [
            {
              inputKey: 'courseName',
              inputLabel: 'Course Name',
              inputType: 'text',
              placeholder: 'Enter your course name',
              show: true,
              required: true,
              inputValue: edu.course || '',
            },
            {
              inputKey: 'collegeName',
              inputLabel: 'School/College Name',
              inputType: 'text',
              placeholder: 'Enter your school/college name',
              show: true,
              required: true,
              inputValue: edu.collegeOrUniversity || '',
            },
            {
              inputKey: 'startDate',
              inputLabel: 'Start Date',
              inputType: 'daterange',
              placeholder: 'Enter start month/year',
              show: true,
              required: true,
              inputValue: edu.fromDate || '',
            },
            {
              inputKey: 'endDate',
              inputLabel: 'End Date',
              inputType: 'daterange',
              placeholder: 'Enter end month/year or select Present',
              show: true,
              required: false,
              inputValue: edu.toDate || '',
            },
            {
              inputKey: 'present',
              inputLabel: 'Present',
              inputType: 'checkbox',
              placeholder: '',
              show: true,
              required: false,
              inputValue: !edu.toDate ? true : false,
            },
            {
              inputKey: 'description',
              inputLabel: 'Description',
              inputType: 'textarea',
              placeholder: 'Describe your studies, achievements, etc.',
              show: true,
              required: false,
              inputValue: '',
              aiWriter: {
                isStatus: true,
                actions: [{ id: 'generate' }, { id: 'rewrite' }],
              },
            },
          ],
        })),
      },
      skills: {
        ...ResumeModal.formObject.skills,
        isShow: Array.isArray(skills) && skills.length > 0,
        inputGroups: skills.map((skill) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: 'skill',
              inputLabel: 'Skill',
              inputType: 'text',
              placeholder: 'Enter a skill',
              show: true,
              required: true,
              inputValue: skill || '',
            },
            {
              inputKey: "level",
              inputLabel: "Level",
              inputType: "dropdown",
              placeholder: "Select skill level",
              show: true,
              required: true,
              inputValue: 'Very good',
              options: [
                "Beginner",
                "Moderate",
                "Good",
                "Very good",
                "Excellent"
              ]
            }
          ],
        })),
      },
      languages: {
        ...ResumeModal.formObject.languages,
        isShow: Array.isArray(languages) && languages.length > 0,
        inputGroups: languages.map((language) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "language",
              inputLabel: "Language",
              inputType: "text",
              placeholder: "Enter a language",
              show: true,
              required: true,
              inputValue: language || '',
            },
            {
              inputKey: "level",
              inputLabel: "Level",
              inputType: "dropdown",
              placeholder: "Select language level",
              show: true,
              required: true,
              inputValue: "Fluent",
              options: [
                "Beginner",  
                "Moderate", 
                "Good",   
                "Very good", 
                "Fluent"         
              ]
            }
          ]
        })),
      },
      projects: {
        ...ResumeModal.formObject.projects,
        isShow: Array.isArray(projects) && projects.length > 0,
        inputGroups: projects.map((project) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "projectTitle",
              inputLabel: "Project Title",
              inputType: "text",
              placeholder: "Enter the project title",
              show: true,
              required: true,
              inputValue: project.projectTitle || '',
            },
            {
              inputKey: "role",
              inputLabel: "Role",
              inputType: "text",
              placeholder: "Enter your role",
              show: true,
              required: true,
              inputValue: project.role || '',
            },
            {
              inputKey: "startDate",
              inputLabel: "Start Date",
              inputType: "daterange",
              placeholder: "Enter start month/year",
              show: true,
              required: true,
              inputValue: project.fromDate || '',
            },
            {
              inputKey: "endDate",
              inputLabel: "End Date",
              inputType: "daterange",
              placeholder: "Enter end month/year or select 'Present'",
              show: true,
              required: false,
              inputValue: project.toDate || '',
            },
            {
              inputKey: "description",
              inputLabel: "Description",
              inputType: "textarea",
              placeholder: "Describe the project, your role, and any accomplishments.",
              show: true,
              required: true,
              inputValue: project.description || '',
            }
          ]
        })),
      },
      achievements: {
        ...ResumeModal.formObject.achievements,
        isShow: Array.isArray(achievements) && achievements.length > 0,
        inputGroups: [
          {
            id: `${Math.random().toString(36).substr(2, 9)}`,
            isExpanded: true,
            inputs: [
              {
                inputKey: "achievements",
                inputLabel: "Achievements",
                inputType: "textarea",
                placeholder: `List your key achievements, e.g., 'Increased sales by 20% within the first year as Sales Manager.'`,
                show: true,
                required: true,
                inputValue: formatJobResponsibilities(achievements) || ''
              }
            ]
          }
        ]
      },
      awards: {
        ...ResumeModal.formObject.awards,
        isShow: Array.isArray(awards) && awards.length > 0,
        inputGroups: awards.map((award) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "awardName",
              inputLabel: "Award Name",
              inputType: "text",
              placeholder: "Enter the award name",
              show: true,
              required: true,
              inputValue: award.awardName || '',
            },
            {
              inputKey: "issuingOrganization",
              inputLabel: "Issuing Organization",
              inputType: "text",
              placeholder: "Enter the issuing organization",
              show: true,
              required: true,
              inputValue: award.issuingOrganization || '',
            },
            {
              inputKey: "issuedDate",
              inputLabel: "Issued Date",
              inputType: "daterange",
              placeholder: "Enter start month/year",
              show: true,
              required: true,
              inputValue: award.issuedDate || '',
            },
            {
              inputKey: "description",
              inputLabel: "Description",
              inputType: "textarea",
              placeholder: "Describe the significance of the award",
              show: true,
              required: false,
              inputValue: award.description || '',
            }
          ]
        })),
      },
      certifications: {
        ...ResumeModal.formObject.certifications,
        isShow: Array.isArray(certifications) && certifications.length > 0,
        inputGroups: certifications.map((certification) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "certificate",
              inputLabel: "Certificate",
              inputType: "text",
              placeholder: "Enter the certificate name",
              show: true,
              required: true,
              inputValue: certification.certificate || '',
            },
            {
              inputKey: "institution",
              inputLabel: "Institution",
              inputType: "text",
              placeholder: "Enter the institution name",
              show: true,
              required: true,
              inputValue: certification.institution || '',
            },
            {
              inputKey: "issueDate",
              inputLabel: "Issue Date",
              inputType: "daterange",
              placeholder: "MM/YYYY",
              show: true,
              required: false,
              inputValue: certification.issueDate || '',
            },
            {
              inputKey: "certificationUrl",
              inputLabel: "Certification URL/ID",
              inputType: "text",
              placeholder: "Enter the URL/ID to the certification (if available)",
              show: true,
              required: false,
              inputValue: certification.certificationUrl || '',
            }
          ]
        })),
      },
      courses: {
        ...ResumeModal.formObject.courses,
        isShow: Array.isArray(courses) && courses.length > 0,
        inputGroups: courses.map((course) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "courseName",
              inputLabel: "Course Name",
              inputType: "text",
              placeholder: "Enter the course name",
              show: true,
              required: true,
              inputValue: course.courseName || '',
            },
            {
              inputKey: "institution",
              inputLabel: "Institution",
              inputType: "text",
              placeholder: "Enter the institution or platform",
              show: true,
              required: true,
              inputValue: course.institution || '',
            },
            {
              inputKey: "endDate",
              inputLabel: "End Date",
              inputType: "daterange",
              placeholder: "Enter end month/year or select 'Present'",
              show: true,
              required: false,
              inputValue: course.endDate || '',
            },
            {
              inputKey: "description",
              inputLabel: "Description",
              inputType: "textarea",
              placeholder: "Briefly describe the course",
              show: true,
              required: false,
              inputValue: course.description || '',
            }
          ]
        })),
      },
      references: {
        ...ResumeModal.formObject.references,
        isShow: Array.isArray(references) && references.length > 0,
        inputGroups: references.map((reference) => ({
          id: `${Math.random().toString(36).substr(2, 9)}`,
          isExpanded: true,
          inputs: [
            {
              inputKey: "referenceName",
              inputLabel: "Reference Name",
              inputType: "text",
              placeholder: "Enter reference's name",
              show: true,
              required: true,
              inputValue: reference.referenceName || '',
            },
            {
              inputKey: "organization",
              inputLabel: "Organization",
              inputType: "text",
              placeholder: "Enter organization",
              show: true,
              required: false,
              inputValue: reference.organization || '',
            },
            {
              inputKey: "phoneNumber",
              inputLabel: "Phone number",
              inputType: "text",
              placeholder: "Enter phone number",
              show: true,
              required: true,
              inputValue: reference.phoneNumber || '',
            },
            {
              inputKey: "emailAddress",
              inputLabel: "Email address",
              inputType: "text",
              placeholder: "Enter reference's email",
              show: true,
              required: true,
              inputValue: reference.emailAddress || '',
            }
          ]
        })),
      },
      softSkills: {
        ...ResumeModal.formObject.softSkills,
        isShow: false,        
        isDisplay: true   
      }
    },
    metadata: {
      templateID: "temp12",
      templateName: `Resume-${personalInformation.jobTitle || 'Untitled'}`,
      description: "",
      creationDate: new Date().toISOString(),
      lastModifiedDate: new Date().toISOString(),
      resumeID: "", // Will be updated after creation
      selectedStyle: "Visionary",
      selectedFont: "roboto-regular",
      selectedLanguage: "en",
      fontSize: "M",
      textAlignment: "left",
      lineHeight: "1.30",
      sectionSpacing: "normal",
    },
    formOrder: ResumeModal.formOrder,
  };

  // Remove any undefined values from the data object before adding to Firestore
  const cleanedResumeData = removeUndefinedValues(resumeData);

  try {
    console.log(cleanedResumeData);
    const docRef = await addDoc(collection(db, "resumes"), cleanedResumeData);
    const resumeID = docRef.id;
    await setDoc(doc(db, "resumes", resumeID), {
      ...cleanedResumeData,
      sectionOrder: ResumeModal.sectionOrder,
      metadata: {
        ...cleanedResumeData.metadata,
        resumeID: resumeID,
      },
    }, { merge: true });

    if (resumeID) {
      navigate(`/editor/resume/${resumeID}`);
    }

    return resumeID;
  } catch (error) {
    console.error('Error creating new resume with AI:', error);
    throw error;
  }
};
