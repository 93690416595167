import React from 'react';
import { useDrop } from 'react-dnd';
import { Link } from 'react-router-dom';
import JobCardDraggable from './JobCardDraggable';

const JobColumn = ({ status, jobs, onDrop, onJobClick, onStatusChange }) => { // Added onStatusChange here
  const [{ isOver }, dropRef] = useDrop({
    accept: 'JOB',
    drop: (item) => onDrop(item.job, status),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={`job-column border-2 bg-gray-100 overflow-hidden rounded shadow w-1/6 ${
        isOver ? 'border-2 border-dashed border-gray-300' : 'border border-transparent'
      }`} // Update class to show border on drop
    >
      {/* Container for header and body */}
      <div className="flex flex-col h-full p-0">
        {/* Fixed header */}
        <div className="border-bottom border-gray-200 m-0 p-4 title-head">
          <h3 className="text-lg m-0 p-0 font-bold">
            {status.charAt(0).toUpperCase() + status.slice(1)} ({jobs.length}) {/* Add the count here */}
          </h3>
        </div>

        {/* Scrollable body */}
        <div className="flex-1 overflow-auto p-4">
          {jobs.length === 0 ? (
            status === 'saved' && (
              <div className="text-center text-sm text-gray-500">
                <p className='mb-2 text-gray-400'>No jobs saved yet. Search and add your favorite jobs!</p>
                <Link to="/jobs" className="text-blue-500 underline">
                  Go to Job Search
                </Link>
              </div>
            )
          ) : (
            jobs.map((job) => (
              <JobCardDraggable 
                key={job.docId}
                job={job}
                onClick={() => onJobClick(job)}  
                onStatusChange={onStatusChange}   
                onJobClick={onJobClick}   
              />
              // <JobCardDraggable
              //   className="bg-red-500"
              //   key={job.docId} // Changed to job.docId
              //   job={job}
              //   onClick={() => onJobClick(job)}
              //   onStatusChange={onStatusChange} // Pass onStatusChange to JobCardDraggable
              // />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default JobColumn;
