import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import { FaFileUpload } from 'react-icons/fa';

const ResumeAnalyzer = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [dragging, setDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State to manage error messages

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(''); // Clear any previous error message when a new file is selected
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setSelectedFile(event.dataTransfer.files[0]);
    setDragging(false);
    setErrorMessage(''); // Clear any previous error message when a new file is dropped
  };

  const handleAnalyzeResume = async () => {
    if (!selectedFile) {
      setErrorMessage('Please upload a resume.');
      return;
    }
  
    setLoading(true);
    setLoadingMessage('Preparing your resume for analysis...');
    setErrorMessage(''); // Clear any previous error message
  
    const formData = new FormData();
    formData.append('resume', selectedFile);
  
    const local = 'http://localhost:3002/analyze-resume';
    const production = 'https://rd-server-418cf5202d66.herokuapp.com/analyze-resume';
    try {
      const response = await fetch(production, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        const errorText = await response.text(); // Get the error message from the response
        throw new Error(errorText);
      }
  
      const data = await response.json();
      setAnalysisResult(data.analysis);
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || 'Failed to analyze resume. Please try again.'); // Set specific error message from backend
      setAnalysisResult(null); // Clear any previous analysis result
      setSelectedFile(null); // Reset the selected file
    } finally {
      setLoading(false);
    }
  };
  

  const handleReset = () => {
    setSelectedFile(null);
    setAnalysisResult(null);
    setLoading(false);
    setLoadingMessage('');
    setErrorMessage(''); // Clear error message on reset
  };

  useEffect(() => {
    if (loading) {
      const messages = [
        'Analyzing ATS compatibility...',
        'Checking for keywords...',
        'Reviewing grammar and spelling...',
        'Evaluating format and structure...',
        'Generating your resume score...',
      ];
      let index = 0;

      const interval = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  return (
    <div className="flex flex-col items-center max-w-5xl mx-auto p-4">
      <div className={`flex ${analysisResult ? 'justify-between' : 'justify-center'} w-full mt-12`}>
        <h2 className="text-2xl font-bold mb-4 text-center">ATS Resume Checker</h2>
        {analysisResult && (
          <button
            onClick={handleReset}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Check New Resume
          </button>
        )}
      </div>

      {errorMessage && ( // Conditionally render the error message
        <div className="text-center text-red-500 mb-4">
          {errorMessage}
        </div>
      )}

      {!selectedFile && (
        <div
          className={`w-full max-w-md bg-white mt-6 rounded-lg shadow-md p-12 border-dashed border-2 border-gray-300 text-center transition ${
            dragging ? 'border-blue-500' : 'border-gray-300'
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className='mb-5'>
           <p className="text-sm font-medium text-gray-700 ">
              Drag & Drop your resume here or click the button below               
            </p>
            <i className="text-xs text-gray-600">Acceptable file types: PDF, DOC, DOCX</i>
            </div>
          
            <FaFileUpload className="mx-auto text-6xl mb-4 fill-gray-500" />
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer mt-2 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Upload Resume
          </label>
        </div>
      )}

      {!analysisResult && selectedFile && (
        <div className="text-center mt-9 mb-4">
          <p className="text-black mb-5">File selected:<br/> <strong>{selectedFile.name}</strong></p>
          <button
            onClick={handleAnalyzeResume}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            disabled={loading}
          >
            {loading ? 'Analyzing...' : 'Analyze Resume'}
          </button>
        </div>
      )}

      {loading && (
        <div className="text-center mt-12 mx-auto">
          <div className="h-36 w-36 mt-6 mx-auto ">
            <LoadingSpinner displayType="inline" />
          </div>
          <p className="text-black mt-0">{loadingMessage}</p>
        </div>
      )}

      {!loading && analysisResult && (
        <div className="w-full bg-white p-8 rounded-lg shadow-md mt-6">
          <h2 className="text-xl font-bold mb-4 text-center">Resume Analysis Report</h2>
          <div className='reviewSummary'>             
             <div className="mb-12">
             <h3 className="text-lg mb-6 font-semibold">Resume Score: <span>{analysisResult.overallScore}/100</span></h3>  
              <p>{analysisResult.summary}</p>
           </div>

            <div className='reviewSection'>
                  <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">ATS Compatibility</h3>
                  <p className='scoreCard'><strong>Score:</strong> {analysisResult.atsCompatibility.score}/10</p>
                  </div>
                 <p><strong className="text-green-600">Strengths:</strong></p>
                  <ul className="text-green-600">
                    {analysisResult.atsCompatibility.strengths.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <p><strong className="text-orange-600">Improvements:</strong></p>
                  <ul className="text-orange-600">
                    {analysisResult.atsCompatibility.improvements.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
              </div>
            <div className='reviewSection'>
            <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">Content</h3>
            <p className='scoreCard'><strong>Score:</strong> {analysisResult.content.score}/10</p>
            </div>
            <p><strong className="text-green-600">Strengths:</strong></p>
            <ul className="text-green-600">
              {analysisResult.content.strengths.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p><strong className="text-orange-600">Improvements:</strong></p>
            <ul className="text-orange-600">
              {analysisResult.content.improvements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
</div> 
 <div className='reviewSection'>
            <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">Format</h3>
            <p className='scoreCard'><strong>Score:</strong> {analysisResult.format.score}/10</p>
            </div>
            <p><strong className="text-green-600">Strengths:</strong></p>
            <ul className="text-green-600">
              {analysisResult.format.strengths.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p><strong className="text-orange-600">Improvements:</strong></p>
            <ul className="text-orange-600">
              {analysisResult.format.improvements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
</div>   
<div className='reviewSection'>
            <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">Skills</h3>
            <p className='scoreCard'><strong>Score:</strong> {analysisResult.skills.score}/10</p>
            </div>
            <p><strong className="text-green-600">Strengths:</strong></p>
            <ul className="text-green-600">
              {analysisResult.skills.strengths.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p><strong className="text-orange-600">Improvements:</strong></p>
            <ul className="text-orange-600">
              {analysisResult.skills.improvements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
  </div>   
  <div className='reviewSection'>
            <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">Resume Sections</h3>
            <p className='scoreCard'><strong>Score:</strong> {analysisResult.resumeSections.score}/10</p>
            </div>
            <p><strong className="text-green-600">Strengths:</strong></p>
            <ul className="text-green-600">
              {analysisResult.resumeSections.strengths.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p><strong className="text-orange-600">Improvements:</strong></p>
            <ul className="text-orange-600">
              {analysisResult.resumeSections.improvements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
  </div>   
  <div className='reviewSection'>
            <div className='secTitle full-width'>
            <h3 className="text-lg font-semibold">Style</h3>
            <p className='scoreCard'><strong>Score:</strong> {analysisResult.style.score}/10</p>
            </div> 
            <p><strong className="text-green-600">Strengths:</strong></p>
            <ul className="text-green-600">
              {analysisResult.style.strengths.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p><strong className="text-orange-600">Improvements:</strong></p>
            <ul className="text-orange-600">
              {analysisResult.style.improvements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeAnalyzer;
