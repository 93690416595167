import React, { useState, useEffect } from 'react';

const EmailGenerator = ({ defaultEmailType = '' }) => {
  const [emailType, setEmailType] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    recipient: 'Hiring Manager', // Default value for recipient name
    jobTitle: '',
    companyName: '',
    subject: '',
    message: '',
    interviewDate: '',
    startDate: '',
    resignationDate: '',
    reason: '',
  });
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(''); 

  const emailTypes = [
    { value: 'jobInquiry', label: 'Job Inquiry Email' },
    { value: 'jobApplication', label: 'Job Application Email' },
    { value: 'followUp', label: 'Follow-Up Email After Application Submission' },
    { value: 'interviewConfirmation', label: 'Interview Confirmation Email' },
    { value: 'interviewThankYou', label: 'Thank You Email After Interview' },
    { value: 'followUpAfterInterview', label: 'Follow-Up Email After Interview' },
    { value: 'acceptance', label: 'Acceptance Email' },
    { value: 'rejection', label: 'Rejection Email' },
    { value: 'networkingThankYou', label: 'Networking or Thank You Email to Contacts' },
    { value: 'resignation', label: 'Resignation Email' },
    { value: 'generalFollowUp', label: 'General Follow-Up Email' },
  ];

  // Set emailType from defaultEmailType on mount or when it changes
  useEffect(() => {
    if (defaultEmailType) {
      console.log('Setting emailType to:', defaultEmailType); // Debugging output
      setEmailType(defaultEmailType); // Set the email type when the prop changes
    }
  }, [defaultEmailType]); // Trigger this useEffect when `defaultEmailType` changes

  const handleEmailTypeChange = (e) => {
    setEmailType(e.target.value);
  };
  
  // const handleEmailTypeChange = (e) => {
  //   setEmailType(e.target.value);
  //   setGeneratedEmail(''); // Clear the generated email when type changes
  //   setError(''); // Clear any existing errors
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(''); // Clear previous error messages

    const production = 'https://rd-server-418cf5202d66.herokuapp.com/generate-email'; // Updated endpoint

    try {
      const response = await fetch(production, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailType, formData }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate email. Please try again.');
      }

      const result = await response.json();
      setGeneratedEmail(result.generatedEmail);
    } catch (error) {
      console.error('Error generating email:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 px-12 emailGenerator">
      <h1 className="text-2xl font-bold mb-12 mt-12 text-center">Email Generator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        
        {/* First Column - Form */}
        <div className="p-6 pt-0">
          <label className="block mb-4 font-semibold text-gray-700">
            Select Email Type:
            <select
              value={emailType}
              onChange={handleEmailTypeChange}
              className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select an email type</option>
              {emailTypes.map((type) => (
                <option key={type.value} value={type.value}>{type.label}</option>
              ))}
            </select>
          </label>
          
          {/* Form Fields */}
          <form onSubmit={handleSubmit}>
            {/* Common Fields */}
            <label className="block mb-4">
              <span className="text-gray-700">Your Name:</span>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </label>

            <label className="block mb-4">
              <span className="text-gray-700">Recipient Name (optional):</span>
              <input
                type="text"
                name="recipient"
                value={formData.recipient}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </label>

            {(emailType === 'jobApplication' || emailType === 'acceptance' || emailType === 'followUp' || emailType === 'followUpAfterInterview' || emailType === 'interviewConfirmation' || emailType === 'interviewThankYou' || emailType === 'rejection' || emailType === 'resignation') && (
              <label className="block mb-4">
                <span className="text-gray-700">Job Title:</span>
                <input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  placeholder='e.g., Accounts Manager'
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>
            )}

            {(emailType === 'jobApplication' || emailType === 'acceptance' || emailType === 'resignation' || emailType === 'rejection') && (
              <label className="block mb-4">
                <span className="text-gray-700">Company Name:</span>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  placeholder='e.g., ABC Corp'
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>
            )}

            {emailType === 'jobInquiry' && (
              <label className="block mb-4">
                <span className="text-gray-700">Your Background:</span>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                ></textarea>
              </label>
            )}

            {(emailType === 'interviewConfirmation' || emailType === 'interviewThankYou' || emailType === 'followUpAfterInterview') && (
              <label className="block mb-4">
                <span className="text-gray-700">Interview Date:</span>
                <input
                  type="date"
                  name="interviewDate"
                  value={formData.interviewDate}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>
            )}

            {(emailType === 'acceptance') && (
              <label className="block mb-4">
                <span className="text-gray-700">Start Date:</span>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>
            )}

            {(emailType === 'resignation') && (
              <label className="block mb-4">
                <span className="text-gray-700">Resignation Date:</span>
                <input
                  type="date"
                  name="resignationDate"
                  value={formData.resignationDate}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>
            )}

            {(emailType === 'rejection' || emailType === 'resignation') && (
              <label className="block mb-4">
                <span className="text-gray-700">Reason (optional):</span>
                <textarea
                  name="reason"
                  value={formData.reason}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                ></textarea>
              </label>
            )}

            {(emailType === 'networkingThankYou') && (
              <label className="block mb-4">
                <span className="text-gray-700">Message:</span>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                ></textarea>
              </label>
            )}

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 hover:bg-blue-700 transition duration-200"
              disabled={isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate Email'}
            </button>
          </form>

          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>

        {/* Second Column - Generated Email */}
        <div className="bg-white border border-gray-200 p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4">Generated Email</h2>
          {generatedEmail ? (
            <div>
              <textarea
                value={generatedEmail}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                rows="10"
              ></textarea>
              <button
                onClick={() => navigator.clipboard.writeText(generatedEmail)}
                className="w-full bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-200"
              >
                Copy to Clipboard
              </button>
            </div>
          ) : (
            <p className="text-gray-500">No email generated yet. Fill out the form and click "Generate Email".</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailGenerator;
