import { useState } from 'react';
import { db } from "../config/firebaseConfig";
import { collection, addDoc } from 'firebase/firestore';

const AddSectionForm = () => {
  const [sectionName, setSectionName] = useState('');
  const [questions, setQuestions] = useState([{ text: '', options: ['', '', '', ''] }]);

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].text = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { text: '', options: ['', '', '', ''] }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'sections'), {
        name: sectionName,
        questions: questions.map((q, index) => ({
          id: index + 1,
          text: q.text,
          type: 'mcq',
          options: q.options
        }))
      });
      alert('Section added successfully!');
    } catch (error) {
      console.error('Error adding section: ', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Section Name</label>
        <input
          type="text"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          required
        />
      </div>

      {questions.map((q, qIndex) => (
        <div key={qIndex}>
          <label>Question {qIndex + 1}</label>
          <input
            type="text"
            value={q.text}
            onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
            required
          />

          {q.options.map((option, oIndex) => (
            <input
              key={oIndex}
              type="text"
              value={option}
              placeholder={`Option ${oIndex + 1}`}
              onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
            />
          ))}
        </div>
      ))}

      <button type="button" onClick={addQuestion}>Add Question</button>

      <button type="submit">Submit Section</button>
    </form>
  );
};

export default AddSectionForm;
