import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import LoadingSpinner from '../components/LoadingSpinner'; // Ensure correct path


// FAQ Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="border-b">
      <button
        className="flex justify-between items-center w-full py-3 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-md font-bold">{question}</span>
        <span>{isOpen ? '−' : '+'}</span>
      </button>
      {isOpen && (
        <div className="pb-3">
          <p className='text-md font-medium'>{answer}</p>
        </div>
      )}
    </div>
  );
};

const ContactForm = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const supportEmailID = process.env.REACT_APP_LOGO_IMAGE === 'cvdesigner-logo.png' ? 'support@cvdesigner.ai' : 'support@resumedesign.ai';
  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <LoadingSpinner />; // Or any loading indicator you prefer
  }

  const faqs = [
    {
      question: 'How do I download my resume for free?',
      answer: 'You can download your resume to text file for free. You first must be signed in. Then simply click on this link: [Your Link Here].'
    },
    {
      question: 'When will my subscription auto-renew?',
      answer: 'Your subscription will auto-renew at the end of your billing cycle. You will receive an email reminder before any charges are made.'
    },
    {
      question: 'What can I do with a paid subscription?',
      answer: 'With a paid subscription, you can create as many resumes and cover letters as you want, download in Text, Word or PDF, email/print, and search for jobs directly from your account.'
    },
    {
      question: 'Why can’t I log in to my account?',
      answer: `You may be putting the wrong password. Click Login, then the "Forgot Password?" link. Follow the instructions and you may set up a new password.
  
      It is also possible that you never created an account with us. Did you ever receive an Activation Email from us?`
    },
    {
      question: 'Can\'t find the answer to your question here?',
      answer: 'Contact us via Email or Live Chat. Our Contact information is on the right side.'
    }
  ];

  return (
    <>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mt-6 mb-12">Help & Support</h1>
        <div className="bg-white px-8 py-8 rounded-lg shadow-md grid md:grid-cols-3 gap-10">
          <div className="md:col-span-2">
            <h3 className="text-xl font-bold mb-6">Frequently Asked Questions</h3>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-6">Contact Us</h3>           
            <p className="mb-4 text-md font-medium">
                <strong>Email:</strong> <a href={`mailto:${supportEmailID}`}>{supportEmailID}</a>
            </p>
            <p className="mb-4 text-md font-medium"><strong>Phone:</strong> +91 8555850150</p>
            <p className="mb-4 text-md font-medium">We're available from Monday to Friday, 8 AM – 12 AM (Midnight) EDT</p>
            <p className="mb-4 text-md font-medium">All requests are processed within 5 business hours. Thank you for your patience.</p>
          </div>
        </div>
      </div>
    </>
  );
}; 

export default ContactForm;
