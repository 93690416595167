import React, { useState } from 'react';

const Support = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(''); // Clear any previous messages
    setErrorMessage('');

    try {
      const local = 'http://localhost:3002/contactf';
      const production = 'https://rd-server-418cf5202d66.herokuapp.com/contact';
      const response = await fetch(production, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setSuccessMessage('Thank you for reaching out! Our support team will get back to you shortly.!');
      setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form
    } catch (error) {
      console.error('There was an error sending the email:', error);
      setErrorMessage('Failed to send email. Please try again later.');
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mt-6 text-center mb-12 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold">Help & Support</h2>
        <p className="text-gray-600 text-center mt-4 text-md">
            If you have any questions or encounter any issues, please fill out the form below and our support team will get back to you as soon as possible.
          </p>
      </div>
    <div className="flex justify-center items-center">
      <div className="w-full max-w-3xl">
        <form 
          onSubmit={handleSubmit} 
          className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
        >      
         <div className="grid grid-cols-2 gap-4 ">     
          <div className="mb-4">
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="name"
            >
              Name
            </label>
            <input  
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="name" 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
          </div>
         
          <div className="mb-4">
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="email"
            >
              Email
            </label>
            <input 
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="email" 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
            />
          </div>
          </div>
          <div className="mb-4">
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="subject"
            >
              Subject
            </label>
            <input 
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="subject" 
              type="text" 
              name="subject" 
              value={formData.subject} 
              onChange={handleChange} 
              required 
            />
          </div>         
          <div className="mb-6">
            <label 
              className="block text-gray-700 text-sm font-bold mb-2" 
              htmlFor="message"
            >
              Issue
            </label>
            <textarea 
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none" 
              id="message" 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="flex items-center justify-between">
            <button 
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="submit"
            >
              Submit Ticket
            </button>
          </div>
        </form>
        {successMessage && (
          <div className="text-green-500 text-center mt-4 font-semibold">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="text-red-500 text-center mt-4 font-semibold">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Support;
