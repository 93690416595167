import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { deleteJob, updateJobStatus, updateJobNotes } from '../../services/jobService';
import { useAuth } from '../../context/AuthContext'; 
import { BsTrash, BsStars } from "react-icons/bs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchUserResumes, fetchUserCoverLetters } from '../../services/resumeService';
import ResumePreview from '../Resume/ResumePreview';
import CoverLetterPreview from '../CoverLetter/CoverLetterPreview';
import guidanceContent from './JobGuidence'; 
import { useLocation, useNavigate } from 'react-router-dom';
import EmailGenerator from '../../pages/emailGenerator';
import JobInterview from '../../pages/JobInterview';

const JobDetailsModal = ({ job, onClose, onJobDelete, onStatusChange, initialTab = 'Job Description' }) => {
  const { title, company, description, location, employmentType, datePosted, salaryRange, jobProviders } = job;
  const { user } = useAuth();

  const [selectedStatus, setSelectedStatus] = useState(job.status);
  const [activeTab, setActiveTab] = useState('Job Description');
  const [notes, setNotes] = useState(job.notes || '');
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false);
  const [resumes, setResumes] = useState([]);
  const [coverLetters, setCoverLetters] = useState([]);
  const [selectedResume, setSelectedResume] = useState(job.resumeID || '');
  const [selectedCoverLetter, setSelectedCoverLetter] = useState(job.coverletterID || '');
  const [resumeData, setResumeData] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState(null);
  const [selectedEmailType, setSelectedEmailType] = useState('');  // NEW: Track the default email type
  const [emailType, setEmailType] = useState('');
  
  const [dropdownOpen, setDropdownOpen] = useState(false); // New state for dropdown

  const navigate = useNavigate();
  const locationQuery = useLocation();

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);  // Toggles the dropdown visibility
  };

  // Function to handle the link click in the dropdown
  const handleLinkClick = () => {
    setDropdownOpen(false);  // Close the dropdown after link click
  };

  // Function to handle apply click when there is only one job provider
  const handleApplyClick = () => {
    // Add your apply logic here
    console.log('Apply Now clicked');
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailTypeFromUrl = params.get('emailType');
    if (emailTypeFromUrl) {
      setEmailType(emailTypeFromUrl);
    }
  }, [window.location.search]);

  useEffect(() => {
    const params = new URLSearchParams(locationQuery.search);
    const emailTypeFromUrl = params.get('emailType') || '';
    setActiveTab(initialTab);
    setSelectedEmailType(emailTypeFromUrl);
  }, [initialTab, locationQuery.search]);

  useEffect(() => {
    setNotes(job.notes || '');

    const fetchData = async () => {
      try {
        const resumeData = await fetchUserResumes(user.uid);
        setResumes(resumeData.resumes || []);
        const coverLetterData = await fetchUserCoverLetters(user.uid);
        setCoverLetters(coverLetterData.coverLetters || []);

        if (job.resumeID) {
          const selectedResumeData = resumeData.resumes.find(resume => resume.id === job.resumeID);
          setResumeData(selectedResumeData);
        }
        if (job.coverletterID) {
          const selectedCoverLetterData = coverLetterData.coverLetters.find(letter => letter.id === job.coverletterID);
          setCoverLetterData(selectedCoverLetterData);
        }
      } catch (error) {
        console.error('Error fetching resumes or cover letters:', error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [job, user]);

  const handleDelete = async () => {
    if (!job || !job.id) return;
    const userId = user.uid;
    try {
      await deleteJob(userId, job.docId);
      onJobDelete(job.id);
      onClose();
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    try {
      await updateJobStatus(user.uid, job.docId, newStatus);
      job.status = newStatus;
      onStatusChange(job.id, newStatus);
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedEmailType('');  
    const url = new URL(window.location);
    url.searchParams.set('tab', tab);
    window.history.pushState({}, '', url);
  };

  const handleNotesChange = (content) => {
    setNotes(content);
  };

  const saveNotesToDatabase = async () => {
    setIsUpdatingNotes(true);
    try {
      await updateJobNotes(user.uid, job.docId, notes);
      job.notes = notes;
    } catch (error) {
      console.error('Error updating notes:', error);
    } finally {
      setIsUpdatingNotes(false);
    }
  };

  const handleResumeChange = async (e) => {
    const selectedResumeId = e.target.value;
    setSelectedResume(selectedResumeId);
    const selectedResumeData = resumes.find(resume => resume.id === selectedResumeId);
    setResumeData(selectedResumeData);

    try {
      await updateJobStatus(user.uid, job.docId, selectedStatus, notes, selectedResumeId, selectedCoverLetter);
      job.resumeID = selectedResumeId;
    } catch (error) {
      console.error('Error updating job with resume:', error);
    }
  };

  const handleCoverLetterChange = async (e) => {
    const selectedCoverLetterId = e.target.value;
    setSelectedCoverLetter(selectedCoverLetterId);
    const selectedCoverLetterData = coverLetters.find(letter => letter.id === selectedCoverLetterId);
    setCoverLetterData(selectedCoverLetterData);

    try {
      await updateJobStatus(user.uid, job.docId, selectedStatus, notes, selectedResume, selectedCoverLetterId);
      job.coverletterID = selectedCoverLetterId;
    } catch (error) {
      console.error('Error updating job with cover letter:', error);
    }
  };

  const handleEditOrDownloadResume = () => {
    if (selectedResume) {
      const url = `/editor/resume/${selectedResume}`;
      window.open(url, '_blank');
    }
  };

  const handleEditOrDownloadCoverLetter = () => {
    if (selectedCoverLetter) {
      const url = `/editor/cover-letter/${selectedCoverLetter}`;
      window.open(url, '_blank');
    }
  };

  const handleOptimizeResume = () => {
    const jobDescriptionEncoded = encodeURIComponent(description);
    const jobTitleEncoded = encodeURIComponent(title);
    const url = `/optimize-resume?jobDescription=${jobDescriptionEncoded}&jobTitle=${jobTitleEncoded}`;
    window.open(url, '_blank'); 
  };

  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end z-50">
      <div className="jobmodal sideDraw transform transition-transform duration-300 ease-in-out relative bg-white shadow-lg w-3/4 max-w-4xl">
        <div className="sticky top-0 bg-white p-6 pb-6 border-b flex justify-between items-center z-10">
          <div className='flex-grow'>
            <h2 className="text-2xl font-bold mb-1">{title}</h2>
            <div className='flex justify-between'>
              <p className="text-gray-700 text-md ">{company} - {location}</p>
              <div className='jobStatuss'>
              {/* <button
  onClick={handleDelete}
  className="flex items-center text-sm justify-center transition duration-200 mt-0"
>
  <BsTrash className="text-red-500 hover:text-red-600 transition duration-300" /> <span className='ml-2 text-gray-400'>Delete</span>
</button> */}

              {/* <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="border rounded p-1 mt-0 w-full"
                >
                  <option value="saved">Saved</option>
                  <option value="applied">Applied</option>
                  <option value="interviewing">Interviewing</option>
                  <option value="offer">Offer</option>
                  <option value="archive">Archive</option>
                </select> */}
              </div>
            </div>
          </div>
          <button onClick={onClose} className="modalclose text-gray-500 hover:text-gray-800">
            <AiOutlineClose size={24} />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b flex justify-around">
          {['Job Description', 'Resume', 'Cover Letter', 'Interviews', 'Emails', 'Notes', 'Guidance'].map((tab) => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="p-6 max-h-[80%] overflow-y-auto">
          {activeTab === 'Job Description' && (
            <div className='text-sm space-y-4'>   
               {(selectedStatus === 'saved' || selectedStatus === 'applied') && jobProviders && jobProviders.length > 0 && (
            <div className="p-6 flex flex-wrap gap-3">
              {jobProviders.length > 1 ? (
  <div className="relative inline-block text-left">
    <button
      className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
      onClick={toggleDropdown}
    >
      Apply Now
    </button>
    {dropdownOpen && (
      <div className="absolute mt-2 rounded shadow py-2 bg-white z-10 border border-gray-200 w-[250px] rounded-tr-[15px] rounded-tl-[15px] rounded-br-[15px] rounded-bl-[15px]">
              {jobProviders.map((provider, index) => (
                          <a
                            key={provider.url}
                            href={provider.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
                            onClick={handleLinkClick} // Close the dropdown on link click
                          >
                            Apply on {provider.jobProvider}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <a
                    href={jobProviders[0].url}
                    target="_blank"
                    rel="noopener noreferrer"

                    className='bg-blue-500 text-white text-sm py-2 px-4 rounded-full hover:bg-blue-700'
                    onClick={handleApplyClick}
                  >
                    Apply Now
                  </a>
                )}
            </div>
          )}         
              <div className="pl-4 text-gray-700">
                {description.split('•').map((point, index) => (
                  <p key={index} className="mb-2 text-sm flex items-start">
                    {point.trim() && (
                      <>
                        <span className="inline-block align-top text-blue-500 mr-2">•</span> {point.trim()}
                      </>
                    )}
                  </p>
                ))}
              </div>
              <div className="mt-4 space-y-2 text-gray-600">
                <p><strong>Location:</strong> {location}</p>
                <p><strong>Employment Type:</strong> {employmentType}</p>
                <p><strong>Date Posted:</strong> {datePosted}</p>
                <p><strong>Salary Range:</strong> {salaryRange || 'N/A'}</p>
              </div>
              <button onClick={handleDelete} className="text-sm bg-red-500 mb-6 text-white px-4 py-2 rounded shadow hover:bg-red-600 transition duration-300 mt-4">
                Delete Job
              </button>
            </div>
          )}

          {activeTab === 'Resume' && (
            <div>
               {(job.status == "saved" || job.status == "applied") &&
              <div className='flex justify-between items-center mb-4'>             
                <div className="block max-w-[260px]">
                  <select
                    value={selectedResume}
                    onChange={handleResumeChange}
                    className="border rounded p-2.5 w-full"
                  >
                    <option value="">Select a resume</option>
                    {resumes.map((resume) => (
                      <option key={resume.id} value={resume.id}>
                        {resume.metadata.templateName || 'Unnamed Resume'}
                      </option>
                    ))}
                  </select>
                </div>              
                <div className='flex space-x-4'>
                  <button 
                    onClick={handleEditOrDownloadResume} 
                    className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition-colors duration-300"
                  >
                    Edit / Download
                  </button>
                  <button 
                    onClick={handleOptimizeResume} 
                    className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 transition-colors duration-300"
                  >
                    Optimize Resume
                  </button>
                </div>
              </div>}
              {resumeData && resumeData.formObject && resumeData.formObject.personalDetails ? (                
                <div className='jobTrackResume'><ResumePreview sections={resumeData.formObject} resumeData={resumeData} showPreviewOnly={true} /></div>         
              ) : (
                <p className='p-6 text-center text-gray-500 text-sm'>
                      {(job.status == "saved" || job.status == "applied") ? (
                      `No resume selected.`
                     ):(
                      `No resume selected. Selection option available on 'Applied' status.`
                     )}
                  </p>
              )}
            </div>
          )}

          {activeTab === 'Cover Letter' && (
            <div className='jobTrackCoverLetter'>
              
               {(job.status == "saved" || job.status == "applied") &&
               <div className='flex justify-between items-center mb-4'>             
                <div className="block max-w-[260px]"> 
                <select
                  value={selectedCoverLetter}
                  onChange={handleCoverLetterChange}
                  className="border rounded p-2.5 w-full"
                >
                  <option value="">Select a cover letter</option>
                  {coverLetters.map((letter) => (
                    <option key={letter.id} value={letter.id}>
                      {letter.metadata.templateName || 'Unnamed Cover Letter'}
                    </option>
                  ))}
                </select>
              </div>
              <div className='flex space-x-4'>
                  <button 
                    onClick={handleEditOrDownloadCoverLetter} 
                    className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition-colors duration-300"
                  >
                    Edit / Download
                  </button>
                  </div>
              </div>}
              {coverLetterData && coverLetterData.formObject ? (
                <CoverLetterPreview 
                  coverLetterData={coverLetterData.formObject}
                  showPreviewOnly={true}
                />
              ) : (
                <p className='p-6 text-center text-gray-500 text-sm'>
                     {(job.status == "saved" || job.status == "applied") ? (
                      `No cover letter selected.`
                     ):(
                      `No cover letter selected. Selection option available on 'Applied' status.`
                     )}
                  </p>
              )}
            </div>
          )}

{activeTab === 'Interviews' && (
              
                  <div className="flex justify-between p-5 px-2">          
                    {/* <button
                      onClick={handleInterviewClick}
                      className="flex bg-green-500 text-white px-4 py-2 text-sm rounded shadow hover:bg-green-600 transition-colors duration-300 w-[175px]"
                    >
                      <BsStars className="mr-2 text-xl" />Interview Prep
                    </button> */}
                    <JobInterview/>
                  </div>
          
          
)}
{activeTab === 'Emails' && (
              
<div className="flex justify-between p-6 px-2">
  {/* <button
    onClick={handleEmailClick}
    className="flex bg-gray-500 text-white mr-6 px-4 py-2 text-sm rounded shadow hover:bg-gray-600 transition-colors duration-300 w-[175px]"
  >
    <BsStars className="mr-2 text-xl" />Email Generator
  </button> */}

<EmailGenerator defaultEmailType={emailType} /> 

</div>


)}
          {activeTab === 'Notes' && (
            <div className='h-full'>
              <ReactQuill
                value={notes}
                onChange={handleNotesChange}
                onBlur={saveNotesToDatabase}
                className="bg-white jtEditor"
              />
              {isUpdatingNotes && <p className="text-sm text-blue-500 mt-2">Updating notes...</p>}
            </div>
          )}

          {activeTab === 'Guidance' && (
            <div className='text-sm'>
              <strong className='float-left w-full mb-5'>Check List</strong>
              {guidanceContent[selectedStatus.toLowerCase()] ? (
                <ul className="list-disc pl-5">
                  {guidanceContent[selectedStatus.toLowerCase()].map((item, index) => (
                    <li key={index} className="mb-2">{item}</li>
                  ))}
                </ul>
              ) : (
                <p>No guidance available for the current status.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal;
