import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { 
  createNewResume, 
  fetchUserResumes, 
  fetchUserCoverLetters, 
  deleteResume, 
  deleteCoverLetter, 
  duplicateCoverLetter, 
  updateCoverLetterName, 
  updateResumeName, 
  duplicateResume2,
  fetchResumeById,
  duplicateCoverLetter2
} from '../services/resumeService';
import { db } from "../config/firebaseConfig";
import { createNewResumeWithAI } from '../services/aiResumeCreateService';
import { createNewCoverLetterWithAI } from '../services/aiCoverLetterCreateService';
import ResumePreview from '../components/Resume/ResumePreview';
import Popover from '../components/Resume/Popover'; 
import Modal from '../components/Modal';
import ResumeTemplates from '../components/Resume/resumeTemplates';
import CoverLetterTemplates from '../components/CoverLetter/CoverLetterTemplates';
import ConfirmationModal from '../components/ConfirmationModal';
import LoginModal from '../components/LoginModal';
import { CgClose } from "react-icons/cg";
import { GiStarsStack } from "react-icons/gi";
import { AiOutlinePlus } from 'react-icons/ai';
import { BsStars } from "react-icons/bs";
import { 
  addDoc, 
  collection, 
  doc, 
  updateDoc, 
  getDoc, 
  getDocs, 
  setDoc, 
  deleteDoc, 
  query, 
  where, 
  limit, 
  startAfter, 
  serverTimestamp 
} from "firebase/firestore";
import { storage } from "../config/firebaseConfig"; 
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import CoverLetterPreview from '../components/CoverLetter/CoverLetterPreview';

const Dashboard = () => {
  const { user, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('resume');
  const [resumes, setResumes] = useState([]);
  const [coverLetters, setCoverLetters] = useState([]);
  const [operationLoading, setOperationLoading] = useState(false);
  const [visiblePopoverId, setVisiblePopoverId] = useState(null);
  const resumePreviewRef = useRef();
  const [displayTemplates, setDisplayTemplates] = useState(false);
  const [displayCoverLetterTemplates, setDisplayCoverLetterTemplates] = useState(false);
  const [isFetchingResumes, setIsFetchingResumes] = useState(false);
  const [isFetchingCoverLetters, setIsFetchingCoverLetters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAILoading, setIsAILoading] = useState(false);
  const [isResumeModalVisible, setIsResumeModalVisible] = useState(false); 
  const [isLetterModalVisible, setIsLetterModalVisible] = useState(false);
  const [resumeToDelete, setResumeToDelete] = useState(null);
  const [letterToDelete, setLetterToDelete] = useState(null);
  const [loadingItemId, setLoadingItemId] = useState(null);
  const [isAIFormVisible, setIsAIFormVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); 
  const [aiErrorMessage, setAiErrorMessage] = useState('');

  const [lastResumeVisible, setLastResumeVisible] = useState(null);
  const [lastCoverLetterVisible, setLastCoverLetterVisible] = useState(null);
  const [isFetchingMoreResumes, setIsFetchingMoreResumes] = useState(false);
  const [isFetchingMoreCoverLetters, setIsFetchingMoreCoverLetters] = useState(false);
  const [isLoadingMoreResumes, setIsLoadingMoreResumes] = useState(false);
  const [isLoadingMoreCoverLetters, setIsLoadingMoreCoverLetters] = useState(false);
  const LOAD_MORE_THRESHOLD = 11;
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    yearsOfExperience: 0,
  });

  const [isAIFormVisibleCoverLetter, setIsAIFormVisibleCoverLetter] = useState(false);
  const [formDataCoverLetter, setFormDataCoverLetter] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    yearsOfExperience: 0,
  });

  const openPopover = (id) => {
    setVisiblePopoverId(id);
  };

  useEffect(() => {
    if (!authLoading) {
      if (user) {
        fetchInitialData();
      } else {        
        setDisplayTemplates(true);
        setIsLoading(false);
      }
    }
  }, [user, authLoading]);

/**************/

/*************/
  

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
        const resumeData = await fetchUserResumes(user.uid, null);
        const coverLetterData = await fetchUserCoverLetters(user.uid, null);
        setResumes(Array.isArray(resumeData.resumes) ? resumeData.resumes : []); // Ensure resumes is always set as an array
        setCoverLetters(Array.isArray(coverLetterData.coverLetters) ? coverLetterData.coverLetters : []); // Ensure coverLetters is always set as an array
        setLastResumeVisible(resumeData.lastVisible);
        setLastCoverLetterVisible(coverLetterData.lastVisible);
    } catch (error) {
        console.error('Failed to fetch initial data:', error);
        setResumes([]);
        setCoverLetters([]);
    } finally {
        setIsLoading(false);
    }
};

const fetchMoreResumes = async () => {
    if (isLoadingMoreResumes) return;
    setIsLoadingMoreResumes(true);
    try {
        const resumeData = await fetchUserResumes(user.uid, lastResumeVisible);
        setResumes(prevResumes => [...prevResumes, ...(Array.isArray(resumeData.resumes) ? resumeData.resumes : [])]); // Ensure resumes is always set as an array
        setLastResumeVisible(resumeData.lastVisible);
    } catch (error) {
        console.error('Failed to fetch more resumes:', error);
    } finally {
        setIsLoadingMoreResumes(false);
    }
};

const fetchMoreCoverLetters = async () => {
    if (isLoadingMoreCoverLetters) return;
    setIsLoadingMoreCoverLetters(true);
    try {
        const coverLetterData = await fetchUserCoverLetters(user.uid, lastCoverLetterVisible);
        setCoverLetters(prevCoverLetters => [...prevCoverLetters, ...(Array.isArray(coverLetterData.coverLetters) ? coverLetterData.coverLetters : [])]); // Ensure coverLetters is always set as an array
        setLastCoverLetterVisible(coverLetterData.lastVisible);
    } catch (error) {
        console.error('Failed to fetch more cover letters:', error);
    } finally {
        setIsLoadingMoreCoverLetters(false);
    }
};

  
  

  const EngagingTexts = [
    "Hang tight! We're building your resume.",
    "Just a moment, your resume is on the way!",
    "Almost there, polishing up the final details!",
    "Grab a coffee while we wrap this up!",
    "Final touches being added to your masterpiece!"
  ];
  
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex(prevIndex => (prevIndex + 1) % EngagingTexts.length);
    }, 3000); 

    return () => clearInterval(intervalId);
  }, []);

  const tabs = [
    { id: 'resume', title: 'Resumes' },
    { id: 'coverLetter', title: 'Cover Letters' },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleCreateNewResume = async () => {
    if (user) {
      setDisplayTemplates(true);
    } else {
      setIsLoginModalOpen(true); 
    }
  };
  
  const handleCreateNewCoverLetter = async () => {
    if (user) {
      setDisplayCoverLetterTemplates(true);
    } else {
      setIsLoginModalOpen(true); 
    }
  };

  const handleDeleteResume = async () => {
    if (!resumeToDelete) return;
    setLoadingItemId(resumeToDelete);
    try {
      const resumeToDeleteData = resumes.find(r => r.id === resumeToDelete);
      if (!resumeToDeleteData) {
        throw new Error("Resume not found.");
      }
      const photoURL = resumeToDeleteData.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo").inputValue;
      if (photoURL) {
        await deletePhoto(photoURL);
      }
      await deleteResume(resumeToDelete);
      setResumes(resumes.filter(resume => resume.id !== resumeToDelete));
      setResumeToDelete(null); 
      setIsResumeModalVisible(false); 
    } catch (error) {
      console.error("Failed to delete resume:", error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const deletePhoto = async (photoURL) => {
    if (!photoURL) {
      console.error("No photo URL provided for deletion.");
      return;
    }
    try {
      const imageRef = ref(storage, photoURL);
      await deleteObject(imageRef);
      console.log("Photo deleted successfully from Firebase Storage");
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const handleDuplicateResume = async (resumeId) => {
    setLoadingItemId(resumeId);
    try {
      const resumeToDuplicate = resumes.find(r => r.id === resumeId);
      if (!resumeToDuplicate) {
        throw new Error("Resume not found.");
      }
  
      const photoURL = resumeToDuplicate.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo")?.inputValue;
      let newPhotoURL = null;
  
      if (photoURL) {
        const response = await fetch(photoURL, { mode: 'cors' });
        if (!response.ok) {
          throw new Error('Failed to fetch photo');
        }
        const blob = await response.blob();
        const newFileName = `${new Date().getTime()}.jpg`;
        const newFileRef = ref(storage, `images/${newFileName}`);
        const snapshot = await uploadBytes(newFileRef, blob);
        newPhotoURL = await getDownloadURL(snapshot.ref);
      }
  
      let duplicatedResumeData = { ...resumeToDuplicate };
      delete duplicatedResumeData.id;
      if (newPhotoURL) {
        duplicatedResumeData.formObject.personalDetails.inputGroups[0].inputs.find(input => input.inputKey === "photo").inputValue = newPhotoURL;
      }
  
      const newResumeID = await duplicateResume(duplicatedResumeData, user.uid);
      const updatedResumes = await fetchUserResumes(user.uid, null);
      setResumes(updatedResumes.resumes);
      console.log("Resume duplicated successfully with new photo URL:", newResumeID);
    } catch (error) {
      console.error("Failed to duplicate resume:", error);
    } finally {
      setLoadingItemId(null);
    }
  };
  
  const handleCreateNewResumeWithAI = () => {
    if (user && (user.email === "cvdesigner.ai@gmail.com" || user.email === "resumedesign.ai@gmail.com")) {
      const firstNames = [
        'Diana', 'Fiona', 'Jennifer', 'Sean', 'Ryan', 'Victor',
        'Sophia', 'Olivia', 'Emma', 'Ava', 'Isabella', 'Mia', 'Charlotte', 'Amelia', 
        'Harper', 'Evelyn', 'Abigail', 'Ella', 'Scarlett', 'Grace', 'Liam', 'Noah', 
        'William', 'James', 'Benjamin', 'Lucas', 'Henry', 'Alexander', 'Daniel'
      ];
      
      const lastNames = [
        'Powell', 'Roberts', 'Murray', 'Stewart', 'Turner',
        'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 
        'Miller', 'Wilson', 'Moore', 'Taylor', 'Anderson', 
        'Thomas', 'Jackson', 'White', 'Harris', 'Martin'
      ];
    
    const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];
    const randomExperience = Math.floor(Math.random() * 11) + 6; // Random number between 5 and 15
  
    setFormData({
      firstName: randomFirstName,
      lastName: randomLastName,
      jobTitle: '',
      email: 'support@cvdesigner.ai',
      phone: '(555) 345-6789',
      yearsOfExperience: randomExperience,
    });
  }
    setAiErrorMessage('');
    if (user) {
      setIsAIFormVisible(true);
    } else {
      setIsLoginModalOpen(true); 
    }
  };
  
    
  const duplicateResume = async (duplicatedResumeData, userId) => {
    try {
      const preparedDuplicatedResumeData = {
        ...duplicatedResumeData,
        metadata: {
          ...duplicatedResumeData.metadata,
          userID: userId,
          createdAt: new Date(),
        }
      };
      const docRef = await addDoc(collection(db, "resumes"), preparedDuplicatedResumeData);
      await updateDoc(doc(db, "resumes", docRef.id), {
        "metadata.resumeID": docRef.id
      });
      console.log("Document duplicated with new ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error duplicating document: ", e);
      throw e;
    }
  };

  const duplicatePhoto = async (photoURL, userId) => {
    try {
      if (!photoURL) {
        console.error("No photo URL provided for duplication.");
        return null;
      }
      const response = await fetch(photoURL);
      const blob = await response.blob();
      const fileName = `${new Date().getTime()}.jpg`;
      const file = new File([blob], fileName, { type: 'image/jpeg' });
      const storageRef = ref(storage, `images/${fileName}`);
      const snapshot = await uploadBytes(storageRef, file);
      const newPhotoURL = await getDownloadURL(snapshot.ref);
      console.log("Duplicated image URL: ", newPhotoURL);
      return newPhotoURL;
    } catch (error) {
      console.error("Error duplicating photo:", error);
      throw error;
    }
  };

  const handleUpdateResumeName = async (resumeId, newTemplateName) => {
    setOperationLoading(true);
    try {
      await updateResumeName(resumeId, newTemplateName, user.uid);
      fetchUserResumes(user.uid).then(setResumes);
    } catch (error) {
      console.error("Failed to update resume name:", error);
    } finally {
      setOperationLoading(false);
    }
  };

  const handleUpdateLetterName = async (letterId, newTemplateName) => {
    setOperationLoading(true);
    try {
      await updateCoverLetterName(letterId, newTemplateName, user.uid);
      fetchUserCoverLetters(user.uid).then(setCoverLetters);
    } catch (error) {
      console.error("Failed to update cover letter name:", error);
    } finally {
      setOperationLoading(false);
    }
  };

  const handleDeleteLetter = async () => {
    if (!letterToDelete) return;
    setLoadingItemId(letterToDelete);
    try {
      const letter = coverLetters.find(r => r.id === letterToDelete);
      if (!letter) {
        throw new Error("Cover Letter not found.");
      }
      await deleteCoverLetter(letterToDelete);
      setCoverLetters(coverLetters.filter(letter => letter.id !== letterToDelete));  
      setLetterToDelete(null); 
      setIsLetterModalVisible(false); 
    } catch (error) {
      console.error("Failed to delete Cover Letter:", error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const handleDuplicateLetter = async (letterId) => {    
    setLoadingItemId(letterId);
    setVisiblePopoverId(null); 
    try {
        const letterToDuplicate = coverLetters.find(r => r.id === letterId);
        if (!letterToDuplicate) {
            throw new Error("Cover letter not found.");
        }
        let duplicatedCoverLetterData = JSON.parse(JSON.stringify(letterToDuplicate));
        delete duplicatedCoverLetterData.id;
        const newCoverLetterID = await duplicateCoverLetter(duplicatedCoverLetterData);
        const updatedCoverLetters = await fetchUserCoverLetters(user.uid, null);
        setCoverLetters(updatedCoverLetters.coverLetters);
        console.log("Cover letter duplicated successfully with ID: ", newCoverLetterID);
    } catch (error) {
        console.error("Failed to duplicate cover letter:", error);
    } finally {
        setLoadingItemId(null);
    }
};

  const handleConfirmDelete = (resumeId) => {
    setResumeToDelete(resumeId);
    setVisiblePopoverId(null); 
    setIsResumeModalVisible(true);
  };
  
  const handleConfirmDeleteLetter = (letterId) => {
    setLetterToDelete(letterId);
    setVisiblePopoverId(null); 
    setIsLetterModalVisible(true);
  };
  
  const handleCancelDelete = () => {
    setResumeToDelete(null);
    setLetterToDelete(null);
    setIsResumeModalVisible(false);
    setIsLetterModalVisible(false);
  };

  const ResumeItem = ({ resume, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(resume.metadata.templateName);

    useEffect(() => {
      setNewName(resume.metadata.templateName);
    }, [resume.metadata.templateName]);

    const handleBlur = () => {
      if (newName.trim() !== resume.metadata.templateName && newName.trim() !== "") {
        onUpdate(resume.id, newName.trim());
      }
      setIsEditing(false);
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.target.blur();
      }
    };
    
    return (
      <div className="resume-name">
        {isEditing ? (
          <input
            type="text"
            value={newName}
            onBlur={handleBlur}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ maxWidth: '180px' }}
            className="inline-resume-title"
          />
        ) : (
          <div
            onMouseEnter={() => setIsEditing(true)}
            className="inline-block"
          >
            {resume.metadata.templateName || 'Unnamed Resume'}
          </div>
        )}
      </div>
    );
  };

  const LetterItem = ({ letter, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(letter.metadata.templateName);

    useEffect(() => {
      setNewName(letter.metadata.templateName);
    }, [letter.metadata.templateName]);

    const handleBlur = () => {
      if (newName.trim() !== letter.metadata.templateName && newName.trim() !== "") {
        onUpdate(letter.id, newName.trim());
      }
      setIsEditing(false);
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.target.blur();
      }
    };

    return (
      <div className="resume-name">
        {isEditing ? (
          <input
            type="text"
            value={newName}
            onBlur={handleBlur}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ maxWidth: '180px' }}
            className="inline-resume-title"
          />
        ) : (
          <div
            onMouseEnter={() => setIsEditing(true)}
            className="inline-block"
          >
            {letter.metadata.templateName || 'Unnamed Resume'}
          </div>
        )}
      </div>
    );
  };

  const convertToDate = (date) => {
    if (date?.seconds) {
      return new Date(date.seconds * 1000);
    } else if (typeof date === 'string' || typeof date === 'number') {
      return new Date(date);
    }
    return new Date();
  };

  if (authLoading || operationLoading) {
    return <LoadingSpinner />;
  }

  const handleResumeClick = (id) => {
    navigate(`/editor/resume/${id}`);
  };

  const handleLetterClick = (id) => {
    navigate(`/editor/cover-letter/${id}`);
  };

  const handleBacktoDashboard = () => {
    setDisplayTemplates(false);
    setDisplayCoverLetterTemplates(false);
  };

  const handleCreateResumeWithAIButtonClick = () => {
    setAiErrorMessage(''); 
    setIsAIFormVisible(true);
  };
  
 const handleAIFormSubmit = async (e) => {
  e.preventDefault(); 
  setIsAILoading(true);
  setAiErrorMessage(''); 
  try {
    const formDataWithUserID = {
      ...formData,
      userID: user.uid,
      yearsOfExperience: formData.yearsOfExperience,
    };
    await createNewResumeWithAI(formDataWithUserID, navigate);
    fetchUserResumes(user.uid).then(setResumes);
    setIsAIFormVisible(false); 
  } catch (error) {
    console.error("Failed to create new resume with AI:", error);
    setAiErrorMessage('Failed to create new resume with AI. Please try later.');
  } finally {
    setIsAILoading(false);
  }
};

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreateNewCoverLetterWithAI = () => {
    if (user) {
      setFormDataCoverLetter({
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: user.email,
        phone: '',
      });
      setAiErrorMessage('');
      setIsAIFormVisibleCoverLetter(true);
    } else {
      setIsLoginModalOpen(true);
    }
  };
  
  
  const handleAIFormSubmitCoverLetter = async (e) => {
    e.preventDefault();
    setIsAILoading(true);
    setAiErrorMessage('');
    try {
      const formDataWithUserID = {
        ...formDataCoverLetter,
        userID: user.uid
      };
      console.log(formDataWithUserID)
      await createNewCoverLetterWithAI(formDataWithUserID, navigate);
      fetchUserCoverLetters(user.uid).then(setCoverLetters);
      setIsAIFormVisibleCoverLetter(false);
    } catch (error) {
      console.error("Failed to create new cover letter with AI:", error);
      setAiErrorMessage('Failed to create new cover letter with AI. Please try later.');
    } finally {
      setIsAILoading(false);
    }
  };
   
  const handleFormChangeCoverLetter = (e) => {
    const { name, value } = e.target;
    setFormDataCoverLetter((prevData) => ({ ...prevData, [name]: value }));
  };

  if (isLoading) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (   
    <div className='main-dashboard'>
      {(displayTemplates || displayCoverLetterTemplates) && (
        <button className="absolute text-3xl px-4 m-4 right-0 hover:text-blue-600" onClick={handleBacktoDashboard}>
          <CgClose />
        </button>
      )}
      {displayTemplates && <ResumeTemplates userId={user?.uid} showLoginModal={() => setIsLoginModalOpen(true)} />}
      {displayCoverLetterTemplates && <CoverLetterTemplates userId={user?.uid} />}
      {!displayTemplates && !displayCoverLetterTemplates && (
        <div className="container mx-auto px-4 py-6 max-w-6xl">
          <div className="flex justify-between mb-6 pb-6">
            <div className="flex space-x-4 border-b gap-24 templates-tab">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`pb-2 font-bold text-smx2 ${activeTab === tab.id ? 'border-b-2 border-blue-500' : ''}`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            {activeTab === 'resume' && (
              <button onClick={() => handleCreateNewResumeWithAI()} className="outline-button ai-btn top-create-btn">
               <BsStars /> Create Resume (AI)
              </button>
            )}
            {activeTab === 'coverLetter' && (
              <button onClick={() => handleCreateNewCoverLetterWithAI()} className="outline-button ai-btn top-create-btn">
              <BsStars /> Create Cover Letter (AI)
            </button>
            )}
          </div>
          {isFetchingResumes && <LoadingSpinner displayType="block" />}
          <div className="flex flex-wrap gap-12 all-templates">
            {activeTab === 'resume' && (
              <div className="flex flex-col justify-between items-center new-doc"> 
              <div className="flex flex-col justify-between items-center py-8 text-2xl"><AiOutlinePlus /></div>                
                <button onClick={() => handleCreateNewResume()} className="outline-button mb-5 ">
                 Create New Resume
                </button>       
                <Link to="/uploadresume" className="outline-button mb-5">
                 Upload Current Resume
                </Link>
                <button onClick={() => handleCreateNewResumeWithAI()} className="outline-button ai-btn">
                <BsStars /> Create Resume (AI)
                </button> 
              </div>
            )}
            {activeTab === 'resume' &&
              resumes && Array.isArray(resumes) && resumes.map((resume) => (
                <div key={resume.id} className="flex flex-col justify-between items-center">
                  <div className="bg-white p-1 rounded shadow dash-resume relative">
                    <div onClick={() => handleResumeClick(resume.id)} className="thumbnail cursor-pointer">
                      <ResumePreview ref={resumePreviewRef} sections={resume.formObject} resumeData={resume} showPreviewOnly={true} />
                      {loadingItemId === resume.id && <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50"><LoadingSpinner /></div>}
                    </div>
                    <div className="dash-resume-footer">
                      <div className="flex justify-between items-center w-full">
                        <div>
                          <h3 className="text-sm font-semibold">
                            <ResumeItem resume={resume} onUpdate={handleUpdateResumeName} />
                          </h3>
                          <span className="text-xs text-gray-500">{convertToDate(resume.metadata.lastModifiedDate).toLocaleString()}</span>
                        </div>
                        <button className="resume-links text-gray-500" onClick={() => openPopover(resume.id)}>
                          ...
                        </button>
                        <Popover
                          isVisible={visiblePopoverId === resume.id}
                          content={[
                            { label: 'Edit', action: (id) => { setVisiblePopoverId(null); navigate(`/editor/resume/${id}`) } },
                            { label: 'Delete', action: () => handleConfirmDelete(resume.id) },
                            { label: 'Duplicate', action: () => handleDuplicateResume(resume.id) },
                          ]}
                          onClickOutside={() => setVisiblePopoverId(null)}
                          resumeId={resume.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {activeTab === 'coverLetter' && (
              <>
                {isFetchingCoverLetters ? (
                  <LoadingSpinner displayType="block" />
                ) : (
                  <div className="flex flex-wrap gap-12 all-templates">
                    <div className="flex flex-col justify-between items-center new-doc">             
                      <div className="flex flex-col justify-between items-center py-8 text-2xl"><AiOutlinePlus /></div>                
                      <button onClick={() => handleCreateNewCoverLetter()} className="outline-button mb-5 ">
                      Create Cover Letter
                      </button>
                      <button onClick={() => handleCreateNewCoverLetterWithAI()} className="outline-button ai-btn top-create-btn">
                        <BsStars /> Create Cover Letter (AI)
                      </button>
                    </div>                 
                   {coverLetters && Array.isArray(coverLetters) && coverLetters.map((letter) =>(
                      <div key={letter.id} className="flex flex-col justify-between items-center">
                        <div className="bg-white p-1 rounded shadow dash-resume relative">
                          <div onClick={() => handleLetterClick(letter.id)} className="thumbnail cursor-pointer">
                            <CoverLetterPreview
                              currentCoverletterId={letter.id}
                              coverLetterData={letter.formObject}
                              coverLetterFullData={letter}
                              showPreviewOnly={true}
                            />
                            {loadingItemId === letter.id && <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50"><LoadingSpinner /></div>}
                          </div>
                          <div className="dash-resume-footer">
                            <div className="flex justify-between items-center w-full">
                              <div>
                                <h3 className="text-sm font-semibold">
                                  <LetterItem letter={letter} onUpdate={handleUpdateLetterName} />
                                </h3>
                                <span className="text-xs text-gray-500">{convertToDate(letter.metadata.lastModifiedDate).toLocaleString()}</span>
                              </div>
                              <button className="resume-links text-gray-500" onClick={() => openPopover(letter.id)}>
                                ...
                              </button>
                              <Popover
                                isVisible={visiblePopoverId === letter.id}
                                content={[
                                  { label: 'Edit', action: (id) => { setVisiblePopoverId(null); navigate(`/editor/cover-letter/${id}`) } },
                                  { label: 'Delete', action: () => handleConfirmDeleteLetter(letter.id) },
                                  { label: 'Duplicate', action: () => handleDuplicateLetter(letter.id) },
                                ]}
                                onClickOutside={() => setVisiblePopoverId(null)}
                                resumeId={letter.id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          {activeTab === 'resume' && resumes.length >= LOAD_MORE_THRESHOLD && lastResumeVisible && (
            <div className='flex pt-6 justify-center'>
                {isLoadingMoreResumes ? (
                    <LoadingSpinner displayType='inline' />
                ) : (
                    <button onClick={fetchMoreResumes} className="load-more-button">
                        Load More Resumes
                    </button>
                )}
            </div>
        )}

        {activeTab === 'coverLetter' && coverLetters.length >= LOAD_MORE_THRESHOLD && lastCoverLetterVisible && (
            <div className='flex pt-6 justify-center'>
                {isLoadingMoreCoverLetters ? (
                    <LoadingSpinner displayType='inline' />
                ) : (
                    <button onClick={fetchMoreCoverLetters} className="load-more-button">
                        Load More Cover Letters
                    </button>
                )}
            </div>
        )}

        </div>
        
      )}
      <ConfirmationModal
        isVisible={isResumeModalVisible}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteResume}
        title="Delete Resume"
        message="Are you sure you want to delete this resume? Once deleted, this resume cannot be restored."
        confirmText="Delete"
        cancelText="Cancel"
      />
      <ConfirmationModal
        isVisible={isLetterModalVisible}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteLetter}
        title="Delete Cover Letter"
        message="Are you sure you want to delete this cover letter? Once deleted, this cover letter cannot be restored."
        confirmText="Delete"
        cancelText="Cancel"
      />
<Modal isOpen={isAIFormVisible} closeModal={() => setIsAIFormVisible(false)} title={isAILoading ? '' : 'Create Resume with AI'}>
  <button className="absolute top-2 right-2 text-xl p-3 text-gray-400 hover:text-gray-600" onClick={() => setIsAIFormVisible(false)}>
    <CgClose />
  </button>
  {isAILoading ? (
    <div className="text-center h-[150px]">
      <p className="mt-2 opacity-100 font-bold text-smx2 transition-opacity duration-1000 ease-in-out">
        {EngagingTexts[currentTextIndex]}
      </p>
      <div className='h-[120px] flex items-center justify-center'><LoadingSpinner displayType="normal" className="loadingSpin" /></div>
    </div>
  ) : (
    <form onSubmit={handleAIFormSubmit} className="space-y-4">
      {aiErrorMessage && <p className="text-red-500">{aiErrorMessage}</p>}
      <div className="grid mt-8 grid-cols-2 gap-4">
        <label className="block">
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <label className="block">
        Job Title:
        <input
          type="text"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleFormChange}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>
      <label className="block">
        Email:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>
      <div className="grid grid-cols-2 gap-4">
        <label className="block">
          Phone:
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Experience:
          <input
            type="number"
            name="yearsOfExperience"
            value={formData.yearsOfExperience}
            onChange={handleFormChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <button 
    type="submit" 
    className="w-full flex text-center justify-center bg-blue-500 text-white p-2 rounded-md ai-writer-btn create-resume-btn"
    >
      <GiStarsStack className="mr-2"/> 
      Create Resume
  </button>
    </form>
  )}
</Modal>

<Modal isOpen={isAIFormVisibleCoverLetter} closeModal={() => setIsAIFormVisibleCoverLetter(false)} title={isAILoading ? '' : 'Create Cover Letter with AI'}>
  <button className="absolute top-2 right-2 text-xl p-3 text-gray-400 hover:text-gray-600" onClick={() => setIsAIFormVisibleCoverLetter(false)}>
    <CgClose />
  </button>
  {isAILoading ? (
    <div className="text-center h-[150px]">
      <p className="mt-2 opacity-100 font-bold text-smx2 transition-opacity duration-1000 ease-in-out">
        {EngagingTexts[currentTextIndex]}
      </p>
      <div className='h-[120px] flex items-center justify-center'><LoadingSpinner displayType="normal" className="loadingSpin" /></div>
    </div>
  ) : (
    <form onSubmit={handleAIFormSubmitCoverLetter} className="space-y-4">
      {aiErrorMessage && <p className="text-red-500">{aiErrorMessage}</p>}
      <div className="grid mt-8 grid-cols-2 gap-4">
        <label className="block">
          First Name:
          <input
            type="text"
            name="firstName"
            value={formDataCoverLetter.firstName}
            onChange={handleFormChangeCoverLetter}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formDataCoverLetter.lastName}
            onChange={handleFormChangeCoverLetter}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>
      <label className="block">
        Job Title:
        <input
          type="text"
          name="jobTitle"
          value={formDataCoverLetter.jobTitle}
          onChange={handleFormChangeCoverLetter}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>
      <label className="block">
        Email:
        <input
          type="email"
          name="email"
          value={formDataCoverLetter.email}
          onChange={handleFormChangeCoverLetter}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
        />
      </label>  
      <div className="grid grid-cols-2 gap-4">
        <label className="block">
          Phone:
          <input
            type="text"
            name="phone"
            value={formDataCoverLetter.phone}
            onChange={handleFormChangeCoverLetter}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
        <label className="block">
          Experience:
          <input
            type="number"
            name="yearsOfExperience"
            value={formDataCoverLetter.yearsOfExperience}
            onChange={handleFormChangeCoverLetter}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
          />
        </label>
      </div>

      <button 
        type="submit" 
        className="w-full flex text-center justify-center bg-blue-500 text-white p-2 rounded-md ai-writer-btn create-resume-btn"
      >
        <GiStarsStack className="mr-2"/> 
        Create Cover Letter
      </button>
    </form>
  )}
</Modal>

      {isLoginModalOpen && <LoginModal isOpen={isLoginModalOpen} closeModal={() => setIsLoginModalOpen(false)} />}

    </div>    
  );
};

export default Dashboard;
