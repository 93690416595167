// src/pages/MyAccount.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext'; 
import { updateProfile, getAuth } from 'firebase/auth'; 
import LoadingSpinner from '../components/LoadingSpinner'; 

const MyAccount = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!loading && !user) {
      // If not loading and user is not authenticated, redirect to login
      navigate('/login');
    } else if (user) {
      // If user is authenticated, populate the form with the user's info
      const names = user.displayName ? user.displayName.split(' ') : [''];
      setFirstName(names[0]);
      setLastName(names.length > 1 ? names[1] : '');
      setEmail(user.email || '');
    }
  }, [user, loading, navigate]);

  const handleUpdateProfile = async () => {
    const auth = getAuth();
    try {
      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`,
      });
      alert('Profile updated successfully!');
    } catch (error) {
      alert('Error updating profile: ' + error.message);
    }
  };

  const handleDeleteAccount = async () => {
    // Placeholder for account deletion logic
    alert('Delete account functionality not implemented.');
  };

  // Show a loading message while waiting for the authentication check
  if (loading) return <LoadingSpinner />;

  return (
    <>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto mt-6 mb-12 text-center">
        <h2 className="text-2xl font-bold">Account Settings</h2>
        <p className="text-gray-600 mt-4 text-md">
        Update your profile, manage your plan, or delete your account. Keep your details current for the best experience.
          </p>
        </div>
        {/* Account Section */}
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Profile</h2>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            disabled
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          />
          <button
            onClick={handleUpdateProfile}
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
          >
            Update
          </button>
        </div>

        {/* Your Plan Section */}
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Your Plan</h2>
          <p>You are on the free plan. You can save your data and search for jobs. Upgrade for PDF downloads & premium features.</p>
          <button className="text-md bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded mt-4" onClick={() => navigate('/plans')}>
            Upgrade
          </button>
        </div>

        {/* Delete Account Section */}
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Delete Account</h2>
          <p className='text-md '>Once you delete your account, it cannot be undone. This is permanent.</p>
          <button
            onClick={handleDeleteAccount}
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mt-4"
          >
            Delete Account
          </button>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
