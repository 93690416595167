import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const OptimizeLinkedInProfile = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [profileData, setProfileData] = useState(null); // State to store fetched profile data
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null); // Clear error when a new file is selected
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setError(null); // Clear error when a new file is dropped
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a LinkedIn profile PDF to upload.');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('linkedinProfile', file); // Append the LinkedIn profile file

    const local = "http://localhost:3002/optimize-linkedin-profile";
    const production = 'https://rd-server-418cf5202d66.herokuapp.com/optimize-linkedin-profile'; // Updated endpoint

    try {
      const response = await fetch(production, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload LinkedIn profile.');
      }

      const data = await response.json();

      if (data && data.analysis) {
        setProfileData(data.analysis); // Store fetched profile data in state
        console.log(`LinkedIn profile optimized successfully. Analysis:`, data.analysis);
      } else {
        throw new Error('Optimized LinkedIn profile data is not available.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Effect to handle the rotating messages while loading
  useEffect(() => {
    if (loading) {
      const messages = [
        "LinkedIn profile uploaded. Starting analysis, please wait...",
        "Reviewing profile for keyword relevance...",
        "Identifying areas for improvement...",
        "Generating suggestions to enhance profile impact...",
        "Compiling final report with detailed feedback...",
        "Almost ready, preparing the report..."
      ];
      let index = 0;

      const interval = setInterval(() => {
        setLoadingMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 2000); // Change message every 2 seconds

      return () => clearInterval(interval);
    } else {
      setLoadingMessage(''); // Clear message when not loading
    }
  }, [loading]);

  return (
    <div className="flex flex-col items-center min-h-screen p-4 mb-9">
      <h2 className="text-2xl font-bold mt-12 mb-4">Optimize LinkedIn Profile with AI</h2>
      <p className="mb-2">Enhance your LinkedIn profile to rise to the top of their list.</p>      

      {/* Upload Section (unchanged) */}
      <div
        className={`w-full max-w-md bg-white mt-6 rounded-lg shadow-md p-12 border-dashed border-2 ${
          isDragging ? 'border-blue-400' : 'border-gray-300'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="mb-4 text-center">
          <div className='mb-5'>
            <p className="text-sm font-medium text-gray-700">
              Drag & Drop your LinkedIn profile PDF here or click the button below
            </p>
          </div>
          <input
            id="fileInput"
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
          />
          <div>
            <button
              onClick={handleButtonClick}
              className="bg-gray-200 text-gray-700 py-2 px-4 my-3 rounded-lg hover:bg-gray-300"
            >
              Choose File
            </button> 
          </div>
          <i className="text-xs text-gray-600">Acceptable file type: PDF</i>
        </div>

        {file && (
          <p className="mt-2 text-sm text-gray-600 text-center">
            Selected File: {file.name}
          </p>
        )}
      </div>

      <button
        onClick={handleUpload}
        disabled={loading}
        className={`mx-auto py-2 px-4 rounded-lg mt-8 ${
          loading ? 'bg-blue-400 text-white cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        {loading ? 'Optimizing LinkedIn profile in progress...' : 'Optimize LinkedIn Profile with AI'}
      </button>

      {loading && (
        <div className="flex flex-col items-center mt-4">
          <LoadingSpinner displayType="inline" />
          <p className="mt-2 text-gray-700">{loadingMessage}</p>
        </div>
      )}

      {/* Error message display */}
      {error && <p className="mt-4 text-red-500">{error}</p>}

      {/* Display the profile data once it is fetched */}
      {profileData && (
        <div className="mt-10 w-full max-w-5xl grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column: Overview and Score */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">Profile Overview</h3>
            <div className="flex items-center justify-center mb-6">
              <div className="w-40 h-40">
                <CircularProgressbar
                  value={profileData.overallScore || 0}
                  text={`${profileData.overallScore || 0}%`}
                  styles={buildStyles({
                    textSize: '16px',
                    pathColor: profileData.overallScore >= 70 ? 'green' : 'red',
                    textColor: profileData.overallScore >= 70 ? 'green' : 'red',
                    trailColor: '#d6d6d6',
                  })}
                />
              </div>
            </div>
            <p className="text-center text-lg font-semibold text-gray-700">
              Your overall LinkedIn profile score is {profileData.overallScore || 0}. Aim for a score of 90 or above for maximum impact.
            </p>
          </div>

          {/* Right Column: Detailed Analysis */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4">Detailed Analysis</h3>
            <div className="space-y-4">
              {Object.entries(profileData).map(([sectionName, sectionData]) => (
                sectionName !== 'overallScore' && sectionName !== 'finalThoughts' && (
                  <div key={sectionName} className="border-b pb-4">
                    <h4 className="text-lg font-semibold text-blue-600">{sectionName.charAt(0).toUpperCase() + sectionName.slice(1)}</h4>
                    <p className="text-sm text-gray-600">Score: {sectionData?.score || 0}/10</p>
                    <p className="text-sm text-gray-600"><strong>Strengths:</strong> {sectionData?.strengths?.join(', ') || 'None'}</p>
                    {sectionData?.improvements?.length > 0 && (
                      <p className="text-sm text-gray-600"><strong>Improvements:</strong> {sectionData.improvements.join(', ')}</p>
                    )}
                    {sectionData?.solutions?.length > 0 && (
                      <p className="text-sm text-gray-600"><strong>Solutions:</strong> {sectionData.solutions.join(', ')}</p>
                    )}
                  </div>
                )
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptimizeLinkedInProfile;
