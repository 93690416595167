import { db } from "../config/firebaseConfig";
import { doc, updateDoc, addDoc, getDoc, getDocs, setDoc, orderBy, collection, deleteDoc, query, where, limit, startAfter, serverTimestamp } from "firebase/firestore";
import ResumeModal from '../modals/ResumeModal';
import CoverLetterModal from '../modals/CoverLetterModal';

const validateResumeObject = (resumeObject) => {
  const requiredSections = Object.keys(ResumeModal.formObject);
  const missingSections = requiredSections.filter(section => !(section in resumeObject.formObject)); 
  if (missingSections.length > 0) {
    throw new Error(`Missing sections in resume object: ${missingSections.join(', ')}`);
  }
  // const requiredMetadataKeys = Object.keys(ResumeModal.metadata);
  // const missingMetadataKeys = requiredMetadataKeys.filter(key => !(key in resumeObject.metadata));
  
  // if (missingMetadataKeys.length > 0) {
  //   throw new Error(`Missing metadata in resume object: ${missingMetadataKeys.join(', ')}`);
  // }
};

/**
 * Save a resume to Firestore
 * @param {Object} resumeObject The resume object to save
 * @returns {Promise<void>}
 */
export const saveResume = async (resumeObject) => {
  try {
    validateResumeObject(resumeObject);
    await setDoc(doc(db, 'resumes', resumeObject.metadata.resumeID), resumeObject);
  } catch (error) {
    console.error('Error saving resume:', error);
    throw error;
  }
};
// export const saveResume = async (resumeObject) => {
//   try {
//     await setDoc(doc(db, 'resumes', resumeObject.metadata.resumeID), resumeObject);
//   } catch (error) {
//     console.error('Error saving resume:', error);
//     throw error;
//   }
// };

export const saveMetadata = async (resumeID, updatedField) => {
  try {
    const resumeRef = doc(db, 'resumes', resumeID);
    await setDoc(resumeRef, {
      metadata: {
        ...updatedField,
      },
    }, { merge: true });
    console.log('Metadata updated successfully');
  } catch (error) {
    console.error('Error updating metadata:', error);
    throw error;
  }
};

export const saveCoverLetterMetadata = async (coverLetterID, updatedField) => {
  try {
    const coverletterRef = doc(db, 'cover-letters', coverLetterID);
    await setDoc(coverletterRef, {
      metadata: {
        ...updatedField,
      },
    }, { merge: true });
    console.log('Metadata updated successfully');
  } catch (error) {
    console.error('Error updating metadata:', error);
    throw error; 
  }
};

export const saveCoverLetter = async (coverLetterObject) => {
  if (!coverLetterObject.metadata || !coverLetterObject.metadata.coverLetterID) {
    throw new Error('Missing cover letter ID.');
  }

  const coverLetterID = coverLetterObject.metadata.coverLetterID;
  try {
    await setDoc(doc(db, 'cover-letters', coverLetterID), coverLetterObject);
    console.log('Cover letter saved successfully');
  } catch (error) {
    console.error('Error saving cover letter:', error);
    throw error;
  }
};

export const createNewResume = async (templateName, userID) => {
  const initialResumeData = {
    formObject: {
      ...ResumeModal.formObject, 
    },
    sectionOrder: ResumeModal.sectionOrder, 
    metadata: {
      ...ResumeModal.metadata,
      selectedStyle: templateName,
      userID: userID, 
      createdAt: serverTimestamp(), 
    },
    formOrder: [
      ...ResumeModal.formOrder, 
    ]
  };
  try {
    const docRef = await addDoc(collection(db, "resumes"), initialResumeData);
    const resumeID = docRef.id;
    await setDoc(doc(db, "resumes", resumeID), {
      ...initialResumeData,
      metadata: {
        ...initialResumeData.metadata,
        resumeID: resumeID, // Update the resumeID in metadata
      }
    }, { merge: true });

    return resumeID; // Return the newly created resume's ID
  } catch (error) {
    console.error('Error creating new resume:', error);
    throw error;
  }
};

export const createNewCoverLetter = async (templateName, userID) => {
  const initialCoverLetterData = {
    formObject: {
      ...CoverLetterModal.formObject, 
    },
    metadata: {
      ...CoverLetterModal.metadata,
      selectedStyle: templateName,
      userID: userID, 
      createdAt: serverTimestamp(), 
    }
  };

  try {
    const docRef = await addDoc(collection(db, "cover-letters"), initialCoverLetterData);
    const clID = docRef.id;
    await setDoc(doc(db, "cover-letters", clID), {
      ...initialCoverLetterData,
      metadata: {
        ...initialCoverLetterData.metadata,
        coverLetterID: clID, // Update the resumeID in metadata
      }
    }, { merge: true });

    return clID; // Return the newly created resume's ID
  } catch (error) {
    console.error('Error creating new cover letter:', error);
    throw error;
  }
};

export const duplicateResume = async (resumeId, newResumeData) => {
  try {
    validateResumeObject(newResumeData);
    const resumeRef = doc(db, 'resumes', resumeId);
    await setDoc(resumeRef, newResumeData, { merge: true });
  } catch (error) {
    console.error("Error saving duplicated resume to database:", error);
    throw error;
  }
};
// export const duplicateResume2 = async (resumeId, newResumeData) => {
//   try {
//     validateResumeObject(newResumeData);
//     const docRef = await addDoc(collection(db, 'resumes'), newResumeData);
//     return docRef.id;
//   } catch (error) {
//     console.error("Error saving duplicated resume to database:", error);
//     throw error;
//   }
// };
export const duplicateResume2 = async (resumeId, newResumeData) => {
  try {
    validateResumeObject(newResumeData);    
    // Add new document to the collection
    const docRef = await addDoc(collection(db, 'resumes'), newResumeData);
    // Get the new resume ID
    const newResumeId = docRef.id;
    // Update the resumeID in the metadata
    newResumeData.metadata.resumeID = newResumeId;
    // Update the document with the new resumeID in the metadata
    await setDoc(doc(db, 'resumes', newResumeId), newResumeData, { merge: true });
    return newResumeId;
  } catch (error) {
    console.error("Error saving duplicated resume to database:", error);
    throw error;
  }
};

export const updateResume2 = async (resumeId, updatedResumeData) => {
  try {
    validateResumeObject(updatedResumeData);
    const resumeRef = doc(db, 'resumes', resumeId);
    await setDoc(resumeRef, updatedResumeData, { merge: true });
  } catch (error) {
    console.error("Error updating resume:", error);
    throw error;
  }
};
// export const updateResume2 = async (resumeId, updatedResumeData) => {
//   try {
//     const resumeRef = doc(db, 'resumes', resumeId);
//     await setDoc(resumeRef, updatedResumeData, { merge: true });
//   } catch (error) {
//     console.error("Error updating resume:", error);
//     throw error;
//   }
// };
export const updateResumeName = async (resumeId, newTemplateName, userId) => {
  const resumeRef = doc(db, "resumes", resumeId);
  try {
    const resumeSnapshot = await getDoc(resumeRef);
    if (resumeSnapshot.exists() && resumeSnapshot.data().metadata.userID === userId) {
      await updateDoc(resumeRef, {
        "metadata.templateName": newTemplateName,
        "metadata.lastModifiedDate": serverTimestamp()
      });
    } else {
      console.error("Resume not found or user mismatch");
    }
  } catch (error) {
    console.error('Error updating resume name:', error);
    throw error;
  }
};

export const fetchResumeById = async (resumeID) => {
  const docRef = doc(db, "resumes", resumeID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.error("No such document!");
    throw new Error("No such document!");
  }
};


export const fetchCoverletterById = async (coverletterID) => {
  const docRef = doc(db, "cover-letters", coverletterID);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("No such document!");
  }
};

// export const fetchUserResumes = async (userId) => {
//   const q = query(collection(db, "resumes"), where("metadata.userID", "==", userId));
//   const querySnapshot = await getDocs(q);

//   return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
// }; 

// export const fetchUserCoverLetters = async (userId) => {
//   const q = query(collection(db, "cover-letters"), where("metadata.userID", "==", userId));
//   const querySnapshot = await getDocs(q);

//   return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
// };
export const fetchResumesForAnalysis = async (userId) => {
  const queryRef = query(
      collection(db, 'resumes'),
      where('metadata.userID', '==', userId),
      orderBy('metadata.lastModifiedDate', 'desc')
  );

  const snapshot = await getDocs(queryRef);
  const resumes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return resumes;
};

export const fetchUserResumes = async (userId, lastVisible) => {
  let q;
  if (lastVisible) {
    q = query(
      collection(db, "resumes"),
      where("metadata.userID", "==", userId),
      orderBy("metadata.lastModifiedDate", "desc"),
      startAfter(lastVisible),
      limit(11)
    );
  } else {
    q = query(
      collection(db, "resumes"),
      where("metadata.userID", "==", userId),
      orderBy("metadata.lastModifiedDate", "desc"),
      limit(11)
    );
  }

  const querySnapshot = await getDocs(q);
  const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return {
    resumes: querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    lastVisible: lastDoc
  };
};

export const fetchUserCoverLetters = async (userId, lastVisible) => {
  let q;
  if (lastVisible) {
    q = query(
      collection(db, "cover-letters"),
      where("metadata.userID", "==", userId),
      orderBy("metadata.lastModifiedDate", "desc"),
      startAfter(lastVisible),
      limit(11)
    );
  } else {
    q = query(
      collection(db, "cover-letters"),
      where("metadata.userID", "==", userId),
      orderBy("metadata.lastModifiedDate", "desc"),
      limit(11)
    );
  }

  const querySnapshot = await getDocs(q);
  const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return {
    coverLetters: querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    lastVisible: lastDoc
  };
};

export const deleteResume = async (resumeId) => {
  await deleteDoc(doc(db, "resumes", resumeId));
};

export const deleteCoverLetter = async (letterId) => {
  try {
    await deleteDoc(doc(db, "cover-letters", letterId));
    console.log("Cover letter deleted successfully");
  } catch (error) {
    console.error("Error deleting cover letter:", error);
    throw error;
  }
};

export const duplicateCoverLetter = async (newCoverLetterData) => {
  try {
    const docRef = await addDoc(collection(db, 'cover-letters'), newCoverLetterData);
    return docRef.id;
  } catch (error) {
    console.error("Error saving duplicated cover letter to database:", error);
    throw error;
  }
};
export const duplicateCoverLetter2 = async (newCoverLetterData) => {
  try {
    const docRef = await addDoc(collection(db, 'cover-letters'), newCoverLetterData);
    return docRef.id;
  } catch (error) {
    console.error("Error saving duplicated cover letter to database:", error);
    throw error;
  }
};

export const updateCoverLetterName = async (letterId, newTemplateName, userId) => {
  const letterRef = doc(db, "cover-letters", letterId);
  try {
    const letterSnapshot = await getDoc(letterRef);
    if (letterSnapshot.exists() && letterSnapshot.data().metadata.userID === userId) {
      await updateDoc(letterRef, {
        "metadata.templateName": newTemplateName,
        "metadata.lastModifiedDate": serverTimestamp()
      });
    } else {
      console.error("Resume not found or user mismatch");
    }
  } catch (error) {
    console.error('Error updating resume name:', error);
    throw error;
  }
};

export const saveJob = async (userId, job) => {
  try {
    // Check if the job object has data
    if (!job || Object.keys(job).length === 0) {
      console.warn('Job object is empty or undefined, skipping save.');
      return null; // Return null to indicate that nothing was saved
    }

    // Reference to the user's jobs collection
    const jobsCollectionRef = collection(db, "savedJobs", userId, "jobs");

    // Step 1: Add a new job document to Firestore
    const jobDocRef = await addDoc(jobsCollectionRef, {
      ...job,
      status: 'saved',
      updatedAt: serverTimestamp()
    });

    console.log('Job added with ID:', jobDocRef.id);

    // Step 2: Update the job document with the generated document ID
    await updateDoc(jobDocRef, {
      docId: jobDocRef.id  // Set the document ID as a field in the document
    });

    console.log('Job updated with document ID:', jobDocRef.id);

    // Step 3: Return the updated job object with new fields
    const updatedJob = {
      ...job,
      docId: jobDocRef.id, // Include the new document ID
      status: 'saved',
      updatedAt: new Date() // Convert Firestore timestamp to JS Date
    };

    return updatedJob; // Return the updated job object

  } catch (error) {
    console.error('Error saving job:', error);
    throw error;
  }
};



export const fetchSavedJobs = async (userId) => {
  try {
    console.log(userId)
    // Fetch all saved jobs from Firestore or your backend
    const jobsCollectionRef = collection(db, "savedJobs", userId, "jobs");
    const querySnapshot = await getDocs(jobsCollectionRef);
    const savedJobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return savedJobs;
  } catch (error) {
    console.error('Error fetching saved jobs:', error);
    throw error;
  }
};



export const deleteSavedJob = async (userId, jobId) => {
  console.log(userId)
  console.log(jobId)
  try {
    await deleteDoc(doc(db, "savedJobs", userId, "jobs", jobId));
  } catch (error) {
    console.error('Error deleting job:', error);
    throw error;
  }
};
